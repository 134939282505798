html, *, *:before, *:after {
    text-shadow: none !important;
    box-sizing:border-box;
}

html {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
.ui-page-theme-a a {
    color: inherit;
}
.fancybox-slide > * {
    overflow:initial;
}
body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button, input {
    overflow: visible;
}

button, input, optgroup, select, textarea {
    /*margin: 0;*/
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.button {
    width: auto !important;
    display: inline-block !important;
    font-family: "gibson_semibold", helvetica, Arial, sans-serif;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 40px;
    padding: 10px 20px;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    -webkit-appearance: none;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    cursor: pointer;
}

.button > i, button.button > i {
    vertical-align: middle;
}

.button:hover {
    text-decoration: none;
}


.button-group-small > .button, .button-small, .button-group-small > button.button, button.button-small {
    padding: 6px 12px;
    min-height: 36px;
    font-size: 0.875rem;
}

.button-group-large > .button, .button-large, .button-group-large > button.button, button.button-large {
    padding: 12px 24px;
    min-height: 48px;
    font-size: 1.3rem;
    line-height: normal;
}

textarea {
    overflow: auto;
    resize: vertical;
}

select {
    word-wrap: normal;
}

a:hover, select:hover {
    cursor: pointer;
}

a {
    color: inherit;
}

/** Layout and Flex **/
.b-container,
.b-container-fluid,
.b-container-sm,
.b-container-md,
.b-container-lg,
.b-container-xl,
.b-container-xxl {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .b-container, .b-container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .b-container, .b-container-sm, .b-container-md {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .b-container, .b-container-sm, .b-container-md, .b-container-lg {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .b-container, .b-container-sm, .b-container-md, .b-container-lg, .b-container-xl {
        max-width: 1140px;
    }
}

@media (min-width: 1500px) {
    .b-container, .b-container-sm, .b-container-md, .b-container-lg, .b-container-xl, .b-container-xxl {
        max-width: 1440px;
    }
}
.b-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-left: -16px;
    box-sizing: border-box;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}
.no-gutters > .b-col,
.no-gutters > [class*="b-col-"] {
    padding-right: 0;
    padding-left: 0;
}
.add-gutters, .add-gutters-x {
    padding-left: 16px !important;
    padding-right: 16px !important;
}
.add-gutters, .add-gutters-y {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}
@media (min-width: 576px) {
    .add-gutters-sm, .add-gutters-sm-x {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .add-gutters-sm, .add-gutters-sm-y {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
    }
}
@media (min-width: 768px) {
    .add-gutters-md, .add-gutters-md-x {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .add-gutters-md, .add-gutters-md-y {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
    }
}
@media (min-width: 992px) {
    .add-gutters-lg, .add-gutters-lg-x {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .add-gutters-lg, .add-gutters-lg-y {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
    }
}
@media (min-width: 1200px) {
    .add-gutters-xl, .add-gutters-xl-x {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .add-gutters-xl, .add-gutters-xl-y {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
    }
}
@media (min-width: 1400px) {
    .add-gutters-xxl, .add-gutters-xxl-x {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .add-gutters-xxl, .add-gutters-xxl-y {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
    }
}

.b-col-1, .b-col-2, .b-col-3, .b-col-4, .b-col-5, .b-col-6, .b-col-7, .b-col-8, .b-col-9, .b-col-10, .b-col-11, .b-col-12, .b-col, .b-col-auto, .b-col-sm-1, .b-col-sm-2, .b-col-sm-3, .b-col-sm-4, .b-col-sm-5, .b-col-sm-6, .b-col-sm-7, .b-col-sm-8, .b-col-sm-9, .b-col-sm-10, .b-col-sm-11, .b-col-sm-12, .b-col-sm, .b-col-sm-auto, .b-col-md-1, .b-col-md-2, .b-col-md-25, .b-col-md-3, .b-col-md-4, .b-col-md-5, .b-col-md-6, .b-col-md-7, .b-col-md-8, .b-col-md-9, .b-col-md-10, .b-col-md-11, .b-col-md-12, .b-col-md, .b-col-md-auto, .b-col-lg-1, .b-col-lg-2, .b-col-lg-3, .b-col-lg-4, .b-col-lg-5, .b-col-lg-6, .b-col-lg-7, .b-col-lg-8, .b-col-lg-9, .b-col-lg-10, .b-col-lg-11, .b-col-lg-12, .b-col-lg, .b-col-lg-auto, .b-col-aus, .b-col-sm-aus, .b-col-md-aus, .b-col-lg.aus, .b-col-xl-aus, .b-col-xxl-aus, .b-col-prodlist, .b-col-sm-prodlist, .b-col-md-prodlist, .b-col-lg-prodlist, .b-col-xl-prodlist, .b-col-xxl-prodlist {
    position: relative;
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    box-sizing: border-box;
}

.b-col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    box-sizing: border-box;
}
.b-row-cols-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.b-row-cols-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.b-row-cols-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.b-row-cols-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.b-row-cols-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

.b-row-cols-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.b-col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.b-col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.b-col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.b-col-aus {
    -ms-flex: 0 0 22%;
    flex: 0 0 22%;
    max-width: 22%;
}
.b-col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.b-col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.b-col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.b-col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.b-col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.b-col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.b-col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}
.b-col-prodlist {
    -ms-flex: 0 0 78%;
    flex: 0 0 78%;
    max-width: 78%;
}

.b-col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.b-col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.b-col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    -ms-flex-order: -1;
    order: -1;
}

.order-last {
    -ms-flex-order: 13;
    order: 13;
}

.order-0 {
    -ms-flex-order: 0;
    order: 0;
}

.order-1 {
    -ms-flex-order: 1;
    order: 1;
}

.order-2 {
    -ms-flex-order: 2;
    order: 2;
}

.order-3 {
    -ms-flex-order: 3;
    order: 3;
}

.order-4 {
    -ms-flex-order: 4;
    order: 4;
}

.order-5 {
    -ms-flex-order: 5;
    order: 5;
}

.order-6 {
    -ms-flex-order: 6;
    order: 6;
}

.order-7 {
    -ms-flex-order: 7;
    order: 7;
}

.order-8 {
    -ms-flex-order: 8;
    order: 8;
}

.order-9 {
    -ms-flex-order: 9;
    order: 9;
}

.order-10 {
    -ms-flex-order: 10;
    order: 10;
}

.order-11 {
    -ms-flex-order: 11;
    order: 11;
}

.order-12 {
    -ms-flex-order: 12;
    order: 12;
}
.offset-1 {
    margin-left: 8.333333%;
}

.offset-2 {
    margin-left: 16.666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.333333%;
}

.offset-5 {
    margin-left: 41.666667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.333333%;
}

.offset-8 {
    margin-left: 66.666667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.333333%;
}

.offset-11 {
    margin-left: 91.666667%;
}

@media (min-width: 576px) {
    .b-col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .b-row-cols-sm-1 > * {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .b-row-cols-sm-2 > * {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .b-row-cols-sm-3 > * {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .b-row-cols-sm-4 > * {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .b-row-cols-sm-5 > * {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .b-row-cols-sm-6 > * {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .b-col-sm-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .b-col-sm-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .b-col-sm-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .b-col-sm-aus {
        -ms-flex: 0 0 22%;
        flex: 0 0 22%;
        max-width: 22%;
    }
    .b-col-sm-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .b-col-sm-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .b-col-sm-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .b-col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .b-col-sm-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .b-col-sm-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .b-col-sm-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .b-col-sm-prodlist {
        -ms-flex: 0 0 78%;
        flex: 0 0 78%;
        max-width: 78%;
    }
    .b-col-sm-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .b-col-sm-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .b-col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        -ms-flex-order: -1;
        order: -1;
    }

    .order-sm-last {
        -ms-flex-order: 13;
        order: 13;
    }

    .order-sm-0 {
        -ms-flex-order: 0;
        order: 0;
    }

    .order-sm-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .order-sm-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .order-sm-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .order-sm-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .order-sm-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .order-sm-6 {
        -ms-flex-order: 6;
        order: 6;
    }

    .order-sm-7 {
        -ms-flex-order: 7;
        order: 7;
    }

    .order-sm-8 {
        -ms-flex-order: 8;
        order: 8;
    }

    .order-sm-9 {
        -ms-flex-order: 9;
        order: 9;
    }

    .order-sm-10 {
        -ms-flex-order: 10;
        order: 10;
    }

    .order-sm-11 {
        -ms-flex-order: 11;
        order: 11;
    }

    .order-sm-12 {
        -ms-flex-order: 12;
        order: 12;
    }
    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.333333%;
    }

    .offset-sm-2 {
        margin-left: 16.666667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.333333%;
    }

    .offset-sm-5 {
        margin-left: 41.666667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.333333%;
    }

    .offset-sm-8 {
        margin-left: 66.666667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.333333%;
    }

    .offset-sm-11 {
        margin-left: 91.666667%;
    }
}

@media (min-width: 768px) {
    .b-col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .b-row-cols-md-1 > * {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .b-row-cols-md-2 > * {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .b-row-cols-md-3 > * {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .b-row-cols-md-4 > * {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .b-row-cols-md-5 > * {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .b-row-cols-md-6 > * {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .b-col-md-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .b-col-md-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .b-col-md-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .b-col-md-25 {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .b-col-md-aus {
        -ms-flex: 0 0 22%;
        flex: 0 0 22%;
        max-width: 22%;
    }
    .b-col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .b-col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .b-col-md-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .b-col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .b-col-md-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .b-col-md-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .b-col-md-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .b-col-md-prodlist {
        -ms-flex: 0 0 78%;
        flex: 0 0 78%;
        max-width: 78%;
    }

    .b-col-md-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .b-col-md-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .b-col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        -ms-flex-order: -1;
        order: -1;
    }

    .order-md-last {
        -ms-flex-order: 13;
        order: 13;
    }

    .order-md-0 {
        -ms-flex-order: 0;
        order: 0;
    }

    .order-md-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .order-md-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .order-md-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .order-md-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .order-md-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .order-md-6 {
        -ms-flex-order: 6;
        order: 6;
    }

    .order-md-7 {
        -ms-flex-order: 7;
        order: 7;
    }

    .order-md-8 {
        -ms-flex-order: 8;
        order: 8;
    }

    .order-md-9 {
        -ms-flex-order: 9;
        order: 9;
    }

    .order-md-10 {
        -ms-flex-order: 10;
        order: 10;
    }

    .order-md-11 {
        -ms-flex-order: 11;
        order: 11;
    }

    .order-md-12 {
        -ms-flex-order: 12;
        order: 12;
    }
    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.333333%;
    }

    .offset-md-2 {
        margin-left: 16.666667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.333333%;
    }

    .offset-md-5 {
        margin-left: 41.666667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.333333%;
    }

    .offset-md-8 {
        margin-left: 66.666667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.333333%;
    }

    .offset-md-11 {
        margin-left: 91.666667%;
    }
}

@media (min-width: 992px) {
    .b-col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .b-row-cols-lg-1 > * {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .b-row-cols-lg-2 > * {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .b-row-cols-lg-3 > * {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .b-row-cols-lg-4 > * {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .b-row-cols-lg-5 > * {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .b-row-cols-lg-6 > * {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .b-col-lg-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .b-col-lg-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .b-col-lg-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .b-col-lg-aus {
        -ms-flex: 0 0 22%;
        flex: 0 0 22%;
        max-width: 22%;
    }
    .b-col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .b-col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .b-col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .b-col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .b-col-lg-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .b-col-lg-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .b-col-lg-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .b-col-lg-prodlist {
        -ms-flex: 0 0 78%;
        flex: 0 0 78%;
        max-width: 78%;
    }

    .b-col-lg-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .b-col-lg-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .b-col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        -ms-flex-order: -1;
        order: -1;
    }

    .order-lg-last {
        -ms-flex-order: 13;
        order: 13;
    }

    .order-lg-0 {
        -ms-flex-order: 0;
        order: 0;
    }

    .order-lg-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .order-lg-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .order-lg-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .order-lg-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .order-lg-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .order-lg-6 {
        -ms-flex-order: 6;
        order: 6;
    }

    .order-lg-7 {
        -ms-flex-order: 7;
        order: 7;
    }

    .order-lg-8 {
        -ms-flex-order: 8;
        order: 8;
    }

    .order-lg-9 {
        -ms-flex-order: 9;
        order: 9;
    }

    .order-lg-10 {
        -ms-flex-order: 10;
        order: 10;
    }

    .order-lg-11 {
        -ms-flex-order: 11;
        order: 11;
    }

    .order-lg-12 {
        -ms-flex-order: 12;
        order: 12;
    }
    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.333333%;
    }

    .offset-lg-2 {
        margin-left: 16.666667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.333333%;
    }

    .offset-lg-5 {
        margin-left: 41.666667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.333333%;
    }

    .offset-lg-8 {
        margin-left: 66.666667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.333333%;
    }

    .offset-lg-11 {
        margin-left: 91.666667%;
    }
}
@media (min-width: 1200px) {
    .b-col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .b-row-cols-xl-1 > * {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .b-row-cols-xl-2 > * {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .b-row-cols-xl-3 > * {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .b-row-cols-xl-4 > * {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .b-row-cols-xl-5 > * {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .b-row-cols-xl-6 > * {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .b-col-xl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .b-col-xl-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .b-col-xl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .b-col-xl-aus {
        -ms-flex: 0 0 22%;
        flex: 0 0 22%;
        max-width: 22%;
    }

    .b-col-xl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .b-col-xl-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .b-col-xl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .b-col-xl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .b-col-xl-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .b-col-xl-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .b-col-xl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .b-col-xl-prodlist {
        -ms-flex: 0 0 78%;
        flex: 0 0 78%;
        max-width: 78%;
    }

    .b-col-xl-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .b-col-xl-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .b-col-xl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        -ms-flex-order: -1;
        order: -1;
    }

    .order-xl-last {
        -ms-flex-order: 13;
        order: 13;
    }

    .order-xl-0 {
        -ms-flex-order: 0;
        order: 0;
    }

    .order-xl-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .order-xl-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .order-xl-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .order-xl-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .order-xl-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .order-xl-6 {
        -ms-flex-order: 6;
        order: 6;
    }

    .order-xl-7 {
        -ms-flex-order: 7;
        order: 7;
    }

    .order-xl-8 {
        -ms-flex-order: 8;
        order: 8;
    }

    .order-xl-9 {
        -ms-flex-order: 9;
        order: 9;
    }

    .order-xl-10 {
        -ms-flex-order: 10;
        order: 10;
    }

    .order-xl-11 {
        -ms-flex-order: 11;
        order: 11;
    }

    .order-xl-12 {
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.333333%;
    }

    .offset-xl-2 {
        margin-left: 16.666667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.333333%;
    }

    .offset-xl-5 {
        margin-left: 41.666667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.333333%;
    }

    .offset-xl-8 {
        margin-left: 66.666667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.333333%;
    }

    .offset-xl-11 {
        margin-left: 91.666667%;
    }
}
@media (min-width: 1400px) {
    .b-col-xxl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .b-row-cols-xxl-1 > * {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .b-row-cols-xxl-2 > * {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .b-row-cols-xxl-3 > * {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .b-row-cols-xxl-4 > * {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .b-row-cols-xxl-5 > * {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .b-row-cols-xxl-6 > * {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .b-col-xxl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .b-col-xxl-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .b-col-xxl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .b-col-xxl-aus {
        -ms-flex: 0 0 22%;
        flex: 0 0 22%;
        max-width: 22%;
    }
    .b-col-xxl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .b-col-xxl-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .b-col-xxl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .b-col-xxl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .b-col-xxl-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .b-col-xxl-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .b-col-xxl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .b-col-xxl-prodlist {
        -ms-flex: 0 0 78%;
        flex: 0 0 78%;
        max-width: 78%;
    }

    .b-col-xxl-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .b-col-xxl-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .b-col-xxl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xxl-first {
        -ms-flex-order: -1;
        order: -1;
    }

    .order-xxl-last {
        -ms-flex-order: 13;
        order: 13;
    }

    .order-xxl-0 {
        -ms-flex-order: 0;
        order: 0;
    }

    .order-xxl-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .order-xxl-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .order-xxl-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .order-xxl-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .order-xxl-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .order-xxl-6 {
        -ms-flex-order: 6;
        order: 6;
    }

    .order-xxl-7 {
        -ms-flex-order: 7;
        order: 7;
    }

    .order-xxl-8 {
        -ms-flex-order: 8;
        order: 8;
    }

    .order-xxl-9 {
        -ms-flex-order: 9;
        order: 9;
    }

    .order-xxl-10 {
        -ms-flex-order: 10;
        order: 10;
    }

    .order-xxl-11 {
        -ms-flex-order: 11;
        order: 11;
    }

    .order-xxl-12 {
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-xxl-0 {
        margin-left: 0;
    }

    .offset-xxl-1 {
        margin-left: 8.333333%;
    }

    .offset-xxl-2 {
        margin-left: 16.666667%;
    }

    .offset-xxl-3 {
        margin-left: 25%;
    }

    .offset-xxl-4 {
        margin-left: 33.333333%;
    }

    .offset-xxl-5 {
        margin-left: 41.666667%;
    }

    .offset-xxl-6 {
        margin-left: 50%;
    }

    .offset-xxl-7 {
        margin-left: 58.333333%;
    }

    .offset-xxl-8 {
        margin-left: 66.666667%;
    }

    .offset-xxl-9 {
        margin-left: 75%;
    }

    .offset-xxl-10 {
        margin-left: 83.333333%;
    }

    .offset-xxl-11 {
        margin-left: 91.666667%;
    }
}
.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.flex-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
}

.flex-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
}

.justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}

.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

.align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
}

.align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
}
.flex-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -ms-flex-line-pack: center !important;
    align-content: center !important;
}
@media (min-width: 576px) {
    .flex-sm-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-sm-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-sm-space-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-sm-space-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-sm-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-sm-space-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-sm-space-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
    .flex-content-sm-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-md-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-md-space-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-md-space-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-md-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-md-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-md-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-md-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-md-space-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-md-space-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
    .flex-content-md-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-lg-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-lg-space-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-lg-space-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-lg-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-lg-space-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-lg-space-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
    .flex-content-lg-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
}
@media (min-width: 1200px) {
    .flex-xl-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-xl-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-xl-space-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-xl-space-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-xl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-xl-space-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-xl-space-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }

    .flex-content-xl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
}
@media (min-width: 1400px) {
    .flex-xxl-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-xxl-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-xxl-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-xxl-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-xxl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-xxl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-xxl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-xxl-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-xxl-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-xxl-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-xxl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-xxl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-xxl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-xxl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-xxl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-xxl-space-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-xxl-space-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-xxl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-xxl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-xxl-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-xxl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-xxl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-xxl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-xxl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-xxl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-xxl-space-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-xxl-space-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-xxl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-xxl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-xxl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-xxl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-xxl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-xxl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-xxl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }

    .flex-content-xxl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }
}
/** TYPOGRAPHY **/
.h1-heading, .h2-heading, .h3-heading, .h4-heading, .h5-heading {
    font-family: gibson_semibold, sans-serif;
    font-weight: 400;
}

.h1-heading {
    font-size: 1.95rem !important;
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

.h2-heading {
    font-size: 1.65rem !important;
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

.h3-heading {
    font-size: 1.45rem !important;
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

.h4-heading {
    font-size: 1.35rem !important;
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

.h5-heading {
    font-size: 1.25rem !important;
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

.h5-heading-light {
    font-size: 1.25rem !important;
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

.lead-text {
    font-size: 1.25rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    padding: 0;
    line-height: 1.3;
}
.bold, .font-weight-700 {
    font-weight: 700;
}
.font-semibold, .h1-heading, .h2-heading, .h3-heading, .h4-heading, .h5-heading {
    font-family: gibson_semibold, sans-serif;
    font-weight: normal;
}

.font-regular, p {
    font-family: gibson_regular, sans-serif;
    font-weight: normal;
}

.font-light, .h5-heading-light, .lead-text {
    font-family: gibson_light, sans-serif;
    font-weight: normal;
}

.font-italic {
    font-family: gibson_italic, sans-serif;
    font-weight: normal;
}

.font-semibold-italic {
    font-family: gibson_semibolditalic, sans-serif;
    font-weight: normal;
}
/** FONT SIZES **/
.font-size-07 {
    font-size: 0.75em !important;
}

.font-size-08 {
    font-size: 0.85em !important;
}

.font-size-09 {
    font-size: 0.95em !important;
}

.font-size-1 {
    font-size: 1em !important;
}

.font-size-11 {
    font-size: 1.15em !important;
}

.font-size-12 {
    font-size: 1.25em !important;
}

.font-size-13 {
    font-size: 1.35em !important;
}

.font-size-14 {
    font-size: 1.4em !important;
}

.font-size-15 {
    font-size: 1.5em !important;
}

.font-size-16 {
    font-size: 1.6em !important;
}

.font-size-18 {
    font-size: 1.8em !important;
}

.font-size-2 {
    font-size: 2em !important;
}

.font-size-22 {
    font-size: 2.2em !important;
}

.font-size-3 {
    font-size: 3em !important;
}

.font-size-35 {
    font-size: 3.5em !important;
}

.font-size-4 {
    font-size: 4em !important;
}

.font-size-45 {
    font-size: 4.5em !important;
}

.font-size-5 {
    font-size: 5em !important;
}

.font-size-55 {
    font-size: 5.5em !important;
}

.font-size-6 {
    font-size: 6em !important;
}

.font-size-65 {
    font-size: 6.5em !important;
}

.font-size-7 {
    font-size: 7em !important;
}

.font-size-75 {
    font-size: 7.5em !important;
}

.font-size-8 {
    font-size: 8em !important;
}

@media screen and (min-width: 576px) {
    .font-size-sm-07 {
        font-size: 0.75em !important;
    }

    .font-size-sm-08 {
        font-size: 0.85em !important;
    }

    .font-size-sm-09 {
        font-size: 0.95em !important;
    }

    .font-size-sm-1 {
        font-size: 1em !important;
    }

    .font-size-sm-11 {
        font-size: 1.15em !important;
    }

    .font-size-sm-12 {
        font-size: 1.2em !important;
    }

    .font-size-sm-13 {
        font-size: 1.3em !important;
    }

    .font-size-sm-14 {
        font-size: 1.4em !important;
    }

    .font-size-sm-15 {
        font-size: 1.5em !important;
    }

    .font-size-sm-16 {
        font-size: 1.6em !important;
    }

    .font-size-sm-18 {
        font-size: 1.8em !important;
    }

    .font-size-sm-2 {
        font-size: 2em !important;
    }

    .font-size-sm-22 {
        font-size: 2.2em !important;
    }

    .font-size-sm-3 {
        font-size: 3em !important;
    }

    .font-size-sm-35 {
        font-size: 3.5em !important;
    }

    .font-size-sm-4 {
        font-size: 4em !important;
    }

    .font-size-sm-45 {
        font-size: 4.5em !important;
    }

    .font-size-sm-5 {
        font-size: 5em !important;
    }

    .font-size-sm-55 {
        font-size: 5.5em !important;
    }

    .font-size-sm-6 {
        font-size: 6em !important;
    }

    .font-size-sm-65 {
        font-size: 6.5em !important;
    }

    .font-size-sm-7 {
        font-size: 7em !important;
    }

    .font-size-sm-75 {
        font-size: 7.5em !important;
    }

    .font-size-sm-8 {
        font-size: 8em !important;
    }
}

@media screen and (min-width: 768px) {
    .font-size-md-07 {
        font-size: 0.75em !important;
    }

    .font-size-md-08 {
        font-size: 0.85em !important;
    }

    .font-size-md-09 {
        font-size: 0.95em !important;
    }

    .font-size-md-1 {
        font-size: 1em !important;
    }

    .font-size-md-11 {
        font-size: 1.15em !important;
    }

    .font-size-md-12 {
        font-size: 1.2em !important;
    }

    .font-size-md-13 {
        font-size: 1.3em !important;
    }

    .font-size-md-14 {
        font-size: 1.4em !important;
    }

    .font-size-md-15 {
        font-size: 1.5em !important;
    }

    .font-size-md-16 {
        font-size: 1.6em !important;
    }

    .font-size-md-18 {
        font-size: 1.8em !important;
    }

    .font-size-md-2 {
        font-size: 2em !important;
    }

    .font-size-md-22 {
        font-size: 2.2em !important;
    }

    .font-size-md-3 {
        font-size: 3em !important;
    }

    .font-size-md-35 {
        font-size: 3.5em !important;
    }

    .font-size-md-4 {
        font-size: 4em !important;
    }

    .font-size-md-45 {
        font-size: 4.5em !important;
    }

    .font-size-md-5 {
        font-size: 5em !important;
    }

    .font-size-md-55 {
        font-size: 5.5em !important;
    }

    .font-size-md-6 {
        font-size: 6em !important;
    }

    .font-size-md-65 {
        font-size: 6.5em !important;
    }

    .font-size-md-7 {
        font-size: 7em !important;
    }

    .font-size-md-75 {
        font-size: 7.5em !important;
    }

    .font-size-md-8 {
        font-size: 8em !important;
    }
}

@media screen and (min-width: 992px) {
    .font-size-lg-07 {
        font-size: 0.75em !important;
    }

    .font-size-lg-08 {
        font-size: 0.85em !important;
    }

    .font-size-lg-09 {
        font-size: 0.95em !important;
    }

    .font-size-lg-1 {
        font-size: 1em !important;
    }

    .font-size-lg-11 {
        font-size: 1.15em !important;
    }

    .font-size-lg-12 {
        font-size: 1.2em !important;
    }

    .font-size-lg-13 {
        font-size: 1.3em !important;
    }

    .font-size-lg-14 {
        font-size: 1.4em !important;
    }

    .font-size-lg-15 {
        font-size: 1.5em !important;
    }

    .font-size-lg-16 {
        font-size: 1.6em !important;
    }

    .font-size-lg-18 {
        font-size: 1.8em !important;
    }

    .font-size-lg-2 {
        font-size: 2em !important;
    }

    .font-size-lg-22 {
        font-size: 2.2em !important;
    }

    .font-size-lg-3 {
        font-size: 3em !important;
    }

    .font-size-lg-35 {
        font-size: 3.5em !important;
    }

    .font-size-lg-4 {
        font-size: 4em !important;
    }

    .font-size-lg-45 {
        font-size: 4.5em !important;
    }

    .font-size-lg-5 {
        font-size: 5em !important;
    }

    .font-size-lg-55 {
        font-size: 5.5em !important;
    }

    .font-size-lg-6 {
        font-size: 6em !important;
    }

    .font-size-lg-65 {
        font-size: 6.5em !important;
    }

    .font-size-lg-7 {
        font-size: 7em !important;
    }

    .font-size-lg-75 {
        font-size: 7.5em !important;
    }

    .font-size-lg-8 {
        font-size: 8em !important;
    }
}

@media screen and (min-width: 1200px) {
    .font-size-xl-07 {
        font-size: 0.75em !important;
    }

    .font-size-xl-08 {
        font-size: 0.85em !important;
    }

    .font-size-xl-09 {
        font-size: 0.95em !important;
    }

    .font-size-xl-1 {
        font-size: 1em !important;
    }

    .font-size-xl-11 {
        font-size: 1.15em !important;
    }

    .font-size-xl-12 {
        font-size: 1.2em !important;
    }

    .font-size-xl-13 {
        font-size: 1.3em !important;
    }

    .font-size-xl-14 {
        font-size: 1.4em !important;
    }

    .font-size-xl-15 {
        font-size: 1.5em !important;
    }

    .font-size-xl-16 {
        font-size: 1.6em !important;
    }

    .font-size-xl-18 {
        font-size: 1.8em !important;
    }

    .font-size-xl-2 {
        font-size: 2em !important;
    }

    .font-size-xl-22 {
        font-size: 2.2em !important;
    }

    .font-size-xl-3 {
        font-size: 3em !important;
    }

    .font-size-xl-35 {
        font-size: 3.5em !important;
    }

    .font-size-xl-4 {
        font-size: 4em !important;
    }

    .font-size-xl-45 {
        font-size: 4.5em !important;
    }

    .font-size-xl-5 {
        font-size: 5em !important;
    }

    .font-size-xl-55 {
        font-size: 5.5em !important;
    }

    .font-size-xl-6 {
        font-size: 6em !important;
    }

    .font-size-xl-65 {
        font-size: 6.5em !important;
    }

    .font-size-xl-7 {
        font-size: 7em !important;
    }

    .font-size-xl-75 {
        font-size: 7.5em !important;
    }

    .font-size-xl-8 {
        font-size: 8em !important;
    }
}

@media screen and (min-width: 1400px) {
    .font-size-xxl-07 {
        font-size: 0.75em !important;
    }

    .font-size-xxl-08 {
        font-size: 0.85em !important;
    }

    .font-size-xxl-09 {
        font-size: 0.95em !important;
    }

    .font-size-xxl-1 {
        font-size: 1em !important;
    }

    .font-size-xxl-11 {
        font-size: 1.15em !important;
    }

    .font-size-xxl-12 {
        font-size: 1.2em !important;
    }

    .font-size-xxl-13 {
        font-size: 1.3em !important;
    }

    .font-size-xxl-14 {
        font-size: 1.4em !important;
    }

    .font-size-xxl-15 {
        font-size: 1.5em !important;
    }

    .font-size-xxl-16 {
        font-size: 1.6em !important;
    }

    .font-size-xxl-18 {
        font-size: 1.8em !important;
    }

    .font-size-xxl-2 {
        font-size: 2em !important;
    }

    .font-size-xxl-22 {
        font-size: 2.2em !important;
    }

    .font-size-xxl-3 {
        font-size: 3em !important;
    }

    .font-size-xxl-35 {
        font-size: 3.5em !important;
    }

    .font-size-xxl-4 {
        font-size: 4em !important;
    }

    .font-size-xxl-45 {
        font-size: 4.5em !important;
    }

    .font-size-xxl-5 {
        font-size: 5em !important;
    }

    .font-size-xxl-55 {
        font-size: 5.5em !important;
    }

    .font-size-xxl-6 {
        font-size: 6em !important;
    }

    .font-size-xxl-65 {
        font-size: 6.5em !important;
    }

    .font-size-xxl-7 {
        font-size: 7em !important;
    }

    .font-size-xxl-75 {
        font-size: 7.5em !important;
    }

    .font-size-xxl-8 {
        font-size: 8em !important;
    }
}

/* Display Styles */
.font-92px {
    font-size: 92px !important;
    line-height: 110% !important;
}

.font-72px {
    font-size: 72px !important;
    line-height: 110% !important;
}

.font-60px {
    font-size: 60px !important;
    line-height: 110% !important;
}

.font-48px {
    font-size: 48px !important;
    line-height: 110% !important;
}

.font-40px {
    font-size: 40px !important;
    line-height: 110% !important;
    font-weight: 600 !important;
}

.font-36px {
    font-size: 36px !important;
    line-height: 110% !important;
    font-weight: 600 !important;
}

.font-32px {
    font-size: 32px !important;
    line-height: 110% !important;
    font-weight: 600 !important;
}

.font-28px {
    font-size: 28px !important;
    line-height: 110% !important;
    font-weight: 600 !important;
}

.font-24px {
    font-size: 24px !important;
    line-height: 110% !important;
    font-weight: 600 !important;
}

.font-20px {
    font-size: 20px !important;
    line-height: 110% !important;
    font-weight: 600 !important;
}

.paragraph-18 {
    font-size: 18px;
    line-height: 140%;
}

.paragraph-16 {
    font-size: 16px;
    line-height: 140%;
}

.paragraph-14 {
    font-size: 14px;
    line-height: 140%;
}

.paragraph-12 {
    font-size: 12px;
    line-height: 140%;
}

@media screen and (min-width: 576px) {
    .font-sm-92px {
        font-size: 92px !important;
        line-height: 110% !important;
    }

    .font-sm-72px {
        font-size: 72px !important;
        line-height: 110% !important;
    }

    .font-sm-60px {
        font-size: 60px !important;
        line-height: 110% !important;
    }

    .font-sm-48px {
        font-size: 48px !important;
        line-height: 110% !important;
    }

    .font-sm-40px {
        font-size: 40px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-sm-36px {
        font-size: 36px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-sm-32px {
        font-size: 32px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-sm-28px {
        font-size: 28px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-sm-24px {
        font-size: 24px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-sm-20px {
        font-size: 20px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .paragraph-sm-18 {
        font-size: 18px;
        line-height: 140%;
    }

    .paragraph-sm-16 {
        font-size: 16px;
        line-height: 140%;
    }

    .paragraph-sm-14 {
        font-size: 14px;
        line-height: 140%;
    }

    .paragraph-sm-12 {
        font-size: 12px;
        line-height: 140%;
    }
}

@media screen and (min-width: 768px) {
    .font-md-92px {
        font-size: 92px !important;
        line-height: 110% !important;
    }

    .font-md-72px {
        font-size: 72px !important;
        line-height: 110% !important;
    }

    .font-md-60px {
        font-size: 60px !important;
        line-height: 110% !important;
    }

    .font-md-48px {
        font-size: 48px !important;
        line-height: 110% !important;
    }

    .font-md-40px {
        font-size: 40px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-md-36px {
        font-size: 36px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-md-32px {
        font-size: 32px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-md-28px {
        font-size: 28px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-md-24px {
        font-size: 24px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-md-20px {
        font-size: 20px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .paragraph-md-18 {
        font-size: 18px;
        line-height: 140%;
    }

    .paragraph-md-16 {
        font-size: 16px;
        line-height: 140%;
    }

    .paragraph-md-14 {
        font-size: 14px;
        line-height: 140%;
    }

    .paragraph-md-12 {
        font-size: 12px;
        line-height: 140%;
    }
}

@media screen and (min-width: 992px) {
    .font-lg-92px {
        font-size: 92px !important;
        line-height: 110% !important;
    }

    .font-lg-72px {
        font-size: 72px !important;
        line-height: 110% !important;
    }

    .font-lg-60px {
        font-size: 60px !important;
        line-height: 110% !important;
    }

    .font-lg-48px {
        font-size: 48px !important;
        line-height: 110% !important;
    }

    .font-lg-40px {
        font-size: 40px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-lg-36px {
        font-size: 36px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-lg-32px {
        font-size: 32px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-lg-28px {
        font-size: 28px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-lg-24px {
        font-size: 24px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-lg-20px {
        font-size: 20px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .paragraph-lg-18 {
        font-size: 18px;
        line-height: 140%;
    }

    .paragraph-lg-16 {
        font-size: 16px;
        line-height: 140%;
    }

    .paragraph-lg-14 {
        font-size: 14px;
        line-height: 140%;
    }

    .paragraph-lg-12 {
        font-size: 12px;
        line-height: 140%;
    }
}

@media screen and (min-width: 1200px) {
    .font-xl-92px {
        font-size: 92px !important;
        line-height: 110% !important;
    }

    .font-xl-72px {
        font-size: 72px !important;
        line-height: 110% !important;
    }

    .font-xl-60px {
        font-size: 60px !important;
        line-height: 110% !important;
    }

    .font-xl-48px {
        font-size: 48px !important;
        line-height: 110% !important;
    }

    .font-xl-40px {
        font-size: 40px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-xl-36px {
        font-size: 36px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-xl-32px {
        font-size: 32px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-xl-28px {
        font-size: 28px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-xl-24px {
        font-size: 24px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-xl-20px {
        font-size: 20px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .paragraph-xl-18 {
        font-size: 18px;
        line-height: 140%;
    }

    .paragraph-xl-16 {
        font-size: 16px;
        line-height: 140%;
    }

    .paragraph-xl-14 {
        font-size: 14px;
        line-height: 140%;
    }

    .paragraph-xl-12 {
        font-size: 12px;
        line-height: 140%;
    }
}

@media screen and (min-width: 1400px) {
    .font-xxl-92px {
        font-size: 92px !important;
        line-height: 110% !important;
    }

    .font-xxl-72px {
        font-size: 72px !important;
        line-height: 110% !important;
    }

    .font-xxl-60px {
        font-size: 60px !important;
        line-height: 110% !important;
    }

    .font-xxl-48px {
        font-size: 48px !important;
        line-height: 110% !important;
    }

    .font-xxl-40px {
        font-size: 40px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-xxl-36px {
        font-size: 36px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-xxl-32px {
        font-size: 32px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-xxl-28px {
        font-size: 28px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-xxl-24px {
        font-size: 24px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .font-xxl-20px {
        font-size: 20px !important;
        line-height: 110% !important;
        font-weight: 600 !important;
    }

    .paragraph-xxl-18 {
        font-size: 18px;
        line-height: 140%;
    }

    .paragraph-xxl-16 {
        font-size: 16px;
        line-height: 140%;
    }

    .paragraph-xxl-14 {
        font-size: 14px;
        line-height: 140%;
    }

    .paragraph-xxl-12 {
        font-size: 12px;
        line-height: 140%;
    }
}

/** LINE HEIGHT **/
.line-height-11 {
    line-height:1.1!important;
}
.line-height-12 {
    line-height: 1.2 !important;
}

.line-height-13 {
    line-height: 1.3 !important;
}

.line-height-14 {
    line-height: 1.4 !important;
}

.line-height-15 {
    line-height: 1.5 !important;
}

.line-height-normal {
    line-height: normal;
}

@media screen and (min-width:576px) {

    .line-height-sm-11 {
        line-height: 1.1;
    }
    .line-height-sm-12 {
        line-height: 1.2;
    }

    .line-height-sm-13 {
        line-height: 1.3;
    }

    .line-height-sm-14 {
        line-height: 1.4;
    }

    .line-height-sm-15 {
        line-height: 1.5;
    }

    .line-height-sm-normal {
        line-height: normal;
    }
}

@media screen and (min-width:768px) {

    .line-height-md-11 {
        line-height: 1.1;
    }
    .line-height-md-12 {
        line-height: 1.2;
    }

    .line-height-md-13 {
        line-height: 1.3;
    }

    .line-height-md-14 {
        line-height: 1.4;
    }

    .line-height-md-15 {
        line-height: 1.5;
    }

    .line-height-md-normal {
        line-height: normal;
    }
}

@media screen and (min-width:992px) {

    .line-height-lg-11 {
        line-height: 1.1;
    }
    .line-height-lg-12 {
        line-height: 1.2;
    }

    .line-height-lg-13 {
        line-height: 1.3;
    }

    .line-height-lg-14 {
        line-height: 1.4;
    }

    .line-height-lg-15 {
        line-height: 1.5;
    }

    .line-height-lg-normal {
        line-height: normal;
    }
}
@media screen and (min-width:1200px) {

    .line-height-xl-11 {
        line-height: 1.1;
    }

    .line-height-xl-12 {
        line-height: 1.2;
    }

    .line-height-xl-13 {
        line-height: 1.3;
    }

    .line-height-xl-14 {
        line-height: 1.4;
    }

    .line-height-xl-15 {
        line-height: 1.5;
    }

    .line-height-xl-normal {
        line-height: normal;
    }
}
@media screen and (min-width:1400px) {

    .line-height-xxl-11 {
        line-height: 1.1;
    }

    .line-height-xxl-12 {
        line-height: 1.2;
    }

    .line-height-xxl-13 {
        line-height: 1.3;
    }

    .line-height-xxl-14 {
        line-height: 1.4;
    }

    .line-height-xxl-15 {
        line-height: 1.5;
    }

    .line-height-xxl-normal {
        line-height: normal;
    }
}
/** IMAGES **/
.image-respond {
    max-width: 100% !important;
    display: block;
}
/** RESPONSIVE EMBED **/
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    box-sizing: border-box;
}

    .embed-responsive::before {
        display: block;
        content: "";
        box-sizing: border-box;
    }

    .embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        box-sizing: border-box;
    }

.embed-responsive-21by9::before {
    padding-top: 42.857143%;
    box-sizing: border-box;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
    box-sizing: border-box;
}

.embed-responsive-9by16::before {
    padding-right: 56.25%;
    box-sizing: border-box;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
    box-sizing: border-box;
}
/** BORDER STYLES **/

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}
.border-style-solid {
    border-style: solid;
}

.border-style-dashed {
    border-style: dashed;
}

.border-style-dotted {
    border-style: dotted;
}

.border-style-none {
    border-style: none;
}

.border-top-style-solid {
    border-top-style: solid;
}

.border-top-style-dashed {
    border-top-style: dashed;
}

.border-top-style-dotted {
    border-top-style: dotted;
}

.border-top-style-none {
    border-top-style: none;
}

.border-right-style-solid {
    border-right-style: solid;
}

.border-right-style-dashed {
    border-right-style: dashed;
}

.border-right-style-dotted {
    border-right-style: dotted;
}

.border-right-style-none {
    border-right-style: none;
}

.border-bottom-style-solid {
    border-bottom-style: solid;
}

.border-bottom-style-dashed {
    border-bottom-style: dashed!important;
}

.border-bottom-style-dotted {
    border-bottom-style: dotted;
}

.border-bottom-style-none {
    border-bottom-style: none;
}

.border-left-style-solid {
    border-left-style: solid;
}

.border-left-style-dashed {
    border-left-style: dashed;
}

.border-left-style-dotted {
    border-left-style: dotted;
}

.border-left-style-none {
    border-left-style: none;
}
/** BORDER WIDTH **/
.border-width-0 {
    border-width: 0px;
}

.border-width-1 {
    border-width: 1px;
}

.border-width-2 {
    border-width: 2px;
}

.border-width-3 {
    border-width: 3px;
}

.border-width-4 {
    border-width: 4px;
}

.border-width-5 {
    border-width: 5px;
}

.border-top-width-0 {
    border-top-width: 0px;
}

.border-top-width-1 {
    border-top-width: 1px;
}

.border-top-width-2 {
    border-top-width: 2px;
}

.border-top-width-3 {
    border-top-width: 3px;
}

.border-top-width-4 {
    border-top-width: 4px;
}

.border-top-width-5 {
    border-top-width: 5px;
}

.border-right-width-0 {
    border-right-width: 0px;
}

.border-right-width-1 {
    border-right-width: 1px;
}

.border-right-width-2 {
    border-right-width: 2px;
}

.border-right-width-3 {
    border-right-width: 3px;
}

.border-right-width-4 {
    border-right-width: 4px;
}

.border-right-width-5 {
    border-right-width: 5px;
}

.border-bottom-width-0 {
    border-bottom-width: 0px;
}

.border-bottom-width-1 {
    border-bottom-width: 1px;
}

.border-bottom-width-2 {
    border-bottom-width: 2px;
}

.border-bottom-width-3 {
    border-bottom-width: 3px;
}

.border-bottom-width-4 {
    border-bottom-width: 4px;
}

.border-bottom-width-5 {
    border-bottom-width: 5px;
}

.border-left-width-0 {
    border-left-width: 0px;
}

.border-left-width-1 {
    border-left-width: 1px;
}

.border-left-width-2 {
    border-left-width: 2px;
}

.border-left-width-3 {
    border-left-width: 3px;
}

.border-left-width-4 {
    border-left-width: 4px;
}

.border-left-width-5 {
    border-left-width: 5px;
}
.border-none {
    border: 0 !important;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
    .border-width-md-0 {
        border-width: 0;
    }
}

@media (min-width: 992px) {
    
}

@media (min-width: 1200px) {
    
}

@media (min-width: 1500px) {
    
}

/** BORDER RADIUS **/
.border-radius-0 {
    border-radius: 0 !important;
}

.border-radius-small {
    border-radius: 0.4rem !important;
}

.border-radius-medium {
    border-radius: 0.3rem !important;
}

.border-radius-large {
    border-radius: 0.6rem !important;
}

.border-radius-xlarge {
    border-radius: 1rem !important;
}

.border-radius-circle {
    border-radius: 50% !important;
}

.border-radius-pill {
    border-radius: 50rem !important;
}

.border-radius-top-small {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
}

.border-radius-top-medium {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.border-radius-top-large {
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
}

.border-radius-top-xlarge {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.border-radius-right-small {
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
}

.border-radius-right-medium {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.border-radius-right-large {
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
}

.border-radius-right-xlarge {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.border-radius-bottom-small {
    border-bottom-right-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
}

.border-radius-bottom-medium {
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.border-radius-bottom-large {
    border-bottom-right-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
}

.border-radius-bottom-xlarge {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.border-radius-left-small {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.border-radius-left-medium {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.border-radius-left-large {
    border-top-left-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
}

.border-radius-left-xlarge {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}
/** HOVER **/
.hover-effect-bottom-shadow {
    transition: box-shadow 450ms ease-out;
}

    .hover-effect-bottom-shadow:hover {
        box-shadow: 0px 10px 45px -35px rgb(0 0 0 / 95%);
        cursor: pointer;
    }

.hover-effect-transform-shadow {
    transform: translateY(0) translateZ(0);
    transition: transform 450ms ease-out, box-shadow 450ms ease-out;
}

    .hover-effect-transform-shadow:hover {
        transform: translateY(-5px) translateZ(0);
        box-shadow: 0px 10px 45px -35px rgb(0 0 0 / 75%);
        cursor: pointer;
    }

.hover-effect-opacity {
    opacity: 1;
    transition: opacity 450ms ease-in-out;
}

    .hover-effect-opacity:hover {
        opacity: 0.8;
    }
/** ANIMATIONS **/
.pulse-black {
    box-shadow: 0 0 0 rgba(0,0,0,1);
    animation: pulse-black 2s infinite
}

@-webkit-keyframes pulse-black {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0.6);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(0,0,0, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
    }
}

@keyframes pulse-black {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0.6);
        box-shadow: 0 0 0 0 rgba(0,0,0, 0.6);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(0,0,0, 0);
        box-shadow: 0 0 0 10px rgba(0,0,0, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
        box-shadow: 0 0 0 0 rgba(0,0,0, 0);
    }
}

.pulse-teal {
    box-shadow: 0 0 0 rgba(63,180,152,1);
    animation: pulse-teal 2s infinite
}

@-webkit-keyframes pulse-teal {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(63,180,152, 0.6);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(63,180,152, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(63,180,152, 0);
    }
}

@keyframes pulse-teal {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(63,180,152, 0.6);
        box-shadow: 0 0 0 0 rgba(63,180,152, 0.6);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(63,180,152, 0);
        box-shadow: 0 0 0 10px rgba(63,180,152, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(63,180,152, 0);
        box-shadow: 0 0 0 0 rgba(63,180,152, 0);
    }
}

.pulse-red {
    box-shadow: 0 0 0 rgba(197,0,12,1);
    animation: pulse-red 2s infinite
}

@-webkit-keyframes pulse-red {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(197,0,12, 0.6);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(197,0,12, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(197,0,12, 0);
    }
}

@keyframes pulse-red {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(197,0,12, 0.6);
        box-shadow: 0 0 0 0 rgba(197,0,12, 0.6);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(197,0,12, 0);
        box-shadow: 0 0 0 10px rgba(197,0,12, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(197,0,12, 0);
        box-shadow: 0 0 0 0 rgba(197,0,12, 0);
    }
}
/** MARGIN & PADDING **/
.margin-0 {
    margin: 0 !important;
}

.margin-top-0, .margin-y-0 {
    margin-top: 0 !important;
}

.margin-right-0, .margin-x-0 {
    margin-right: 0 !important;
}

.margin-bottom-0, .margin-y-0 {
    margin-bottom: 0 !important;
}

.margin-left-0, .margin-x-0 {
    margin-left: 0 !important;
}

.margin-05 {
    margin: 0.5rem !important;
}

.margin-top-05, .margin-y-05 {
    margin-top: 0.5rem !important;
}

.margin-right-05, .margin-x-05 {
    margin-right: 0.5rem !important;
}

.margin-bottom-05, .margin-y-05 {
    margin-bottom: 0.5rem !important;
}

.margin-left-05, .margin-x-05 {
    margin-left: 0.5rem !important;
}

.margin-1 {
    margin: 1rem !important;
}

.margin-top-1, .margin-y-1 {
    margin-top: 1rem !important;
}

.margin-right-1, .margin-x-1 {
    margin-right: 1rem !important;
}

.margin-bottom-1, .margin-y-1 {
    margin-bottom: 1rem !important;
}

.margin-left-1, .margin-x-1 {
    margin-left: 1rem !important;
}

.margin-15 {
    margin: 1.5rem !important;
}

.margin-top-15, .margin-y-15 {
    margin-top: 1.5rem !important;
}

.margin-right-15, .margin-x-15 {
    margin-right: 1.5rem !important;
}

.margin-bottom-15, .margin-y-15 {
    margin-bottom: 1.5rem !important;
}

.margin-left-15, .margin-x-15 {
    margin-left: 1.5rem !important;
}

.margin-2 {
    margin: 2rem !important;
}

.margin-top-2, .margin-y-2 {
    margin-top: 2rem !important;
}

.margin-right-2, .margin-x-2 {
    margin-right: 2rem !important;
}

.margin-bottom-2, .margin-y-2 {
    margin-bottom: 2rem !important;
}

.margin-left-2, .margin-x-2 {
    margin-left: 2rem !important;
}

.margin-25 {
    margin: 2.5rem !important;
}

.margin-top-25, .margin-y-25 {
    margin-top: 2.5rem !important;
}

.margin-right-25, .margin-x-25 {
    margin-right: 2.5rem !important;
}

.margin-bottom-25, .margin-y-25 {
    margin-bottom: 2.5rem !important;
}

.margin-left-25, .margin-x-25 {
    margin-left: 2.5rem !important;
}

.margin-3 {
    margin: 3rem !important;
}

.margin-top-3, .margin-y-3 {
    margin-top: 3rem !important;
}

.margin-right-3, .margin-x-3 {
    margin-right: 3rem !important;
}

.margin-bottom-3, .margin-y-3 {
    margin-bottom: 3rem !important;
}

.margin-left-3, .margin-x-3 {
    margin-left: 3rem !important;
}

.margin-35 {
    margin: 3.5rem !important;
}

.margin-top-35, .margin-y-35 {
    margin-top: 3.5rem !important;
}

.margin-right-35, .margin-x-35 {
    margin-right: 3.5rem !important;
}

.margin-bottom-35, .margin-y-35 {
    margin-bottom: 3.5rem !important;
}

.margin-left-35, .margin-x-35 {
    margin-left: 3.5rem !important;
}

.margin-4 {
    margin: 4rem !important;
}

.margin-top-4, .margin-y-4 {
    margin-top: 4rem !important;
}

.margin-right-4, .margin-x-4 {
    margin-right: 4rem !important;
}

.margin-bottom-4, .margin-y-4 {
    margin-bottom: 4rem !important;
}

.margin-left-4, .margin-x-4 {
    margin-left: 4rem !important;
}

.margin-45 {
    margin: 4.5rem !important;
}

.margin-top-45, .margin-y-45 {
    margin-top: 4.5rem !important;
}

.margin-right-45, .margin-x-45 {
    margin-right: 4.5rem !important;
}

.margin-bottom-45, .margin-y-45 {
    margin-bottom: 4.5rem !important;
}

.margin-left-45, .margin-x-45 {
    margin-left: 4.5rem !important;
}

.margin-5 {
    margin: 5rem !important;
}

.margin-top-5, .margin-y-5 {
    margin-top: 5rem !important;
}

.margin-right-5, .margin-x-5 {
    margin-right: 5rem !important;
}

.margin-bottom-5, .margin-y-5 {
    margin-bottom: 5rem !important;
}

.margin-left-5, .margin-x-5 {
    margin-left: 5rem !important;
}

.padding-0 {
    padding: 0 !important;
}

.padding-top-0, .padding-y-0 {
    padding-top: 0 !important;
}

.padding-right-0, .padding-x-0 {
    padding-right: 0 !important;
}

.padding-bottom-0, .padding-y-0 {
    padding-bottom: 0 !important;
}

.padding-left-0, .padding-x-0 {
    padding-left: 0 !important;
}

.padding-05 {
    padding: 0.5rem !important;
}

.padding-top-05, .padding-y-05 {
    padding-top: 0.5rem !important;
}

.padding-right-05, .padding-x-05 {
    padding-right: 0.5rem !important;
}

.padding-bottom-05, .padding-y-05 {
    padding-bottom: 0.5rem !important;
}

.padding-left-05, .padding-x-05 {
    padding-left: 0.5rem !important;
}

.padding-1 {
    padding: 1rem !important;
}

.padding-top-1, .padding-y-1 {
    padding-top: 1rem !important;
}

.padding-right-1, .padding-x-1 {
    padding-right: 1rem !important;
}

.padding-bottom-1, .padding-y-1 {
    padding-bottom: 1rem !important;
}

.padding-left-1, .padding-x-1 {
    padding-left: 1rem !important;
}

.padding-15 {
    padding: 1.5rem !important;
}

.padding-top-15, .padding-y-15 {
    padding-top: 1.5rem !important;
}

.padding-right-15, .padding-x-15 {
    padding-right: 1.5rem !important;
}

.padding-bottom-15, .padding-y-15 {
    padding-bottom: 1.5rem !important;
}

.padding-left-15, .padding-x-15 {
    padding-left: 1.5rem !important;
}

.padding-2 {
    padding: 2rem !important;
}

.padding-top-2, .padding-y-2 {
    padding-top: 2rem !important;
}

.padding-right-2, .padding-x-2 {
    padding-right: 2rem !important;
}

.padding-bottom-2, .padding-y-2 {
    padding-bottom: 2rem !important;
}

.padding-left-2, .padding-x-2 {
    padding-left: 2rem !important;
}

.padding-25 {
    padding: 2.5rem !important;
}

.padding-top-25, .padding-y-25 {
    padding-top: 2.5rem !important;
}

.padding-right-25, .padding-x-25 {
    padding-right: 2.5rem !important;
}

.padding-bottom-25, .padding-y-25 {
    padding-bottom: 2.5rem !important;
}

.padding-left-25, .padding-x-25 {
    padding-left: 2.5rem !important;
}

.padding-3 {
    padding: 3rem !important;
}

.padding-top-3, .padding-y-3 {
    padding-top: 3rem !important;
}

.padding-right-3, .padding-x-3 {
    padding-right: 3rem !important;
}

.padding-bottom-3, .padding-y-3 {
    padding-bottom: 3rem !important;
}

.padding-left-3, .padding-x-3 {
    padding-left: 3rem !important;
}

.padding-35 {
    padding: 3.5rem !important;
}

.padding-top-35, .padding-y-35 {
    padding-top: 3.5rem !important;
}

.padding-right-35, .padding-x-35 {
    padding-right: 3.5rem !important;
}

.padding-bottom-35, .padding-y-35 {
    padding-bottom: 3.5rem !important;
}

.padding-left-35, .padding-x-35 {
    padding-left: 3.5rem !important;
}

.padding-4 {
    padding: 4rem !important;
}

.padding-top-4, .padding-y-4 {
    padding-top: 4rem !important;
}

.padding-right-4, .padding-x-4 {
    padding-right: 4rem !important;
}

.padding-bottom-4, .padding-y-4 {
    padding-bottom: 4rem !important;
}

.padding-left-4, .padding-x-4 {
    padding-left: 4rem !important;
}

.padding-45 {
    padding: 4.5rem !important;
}

.padding-top-45, .padding-y-45 {
    padding-top: 4.5rem !important;
}

.padding-right-45, .padding-x-45 {
    padding-right: 4.5rem !important;
}

.padding-bottom-45, .padding-y-45 {
    padding-bottom: 4.5rem !important;
}

.padding-left-45, .padding-x-45 {
    padding-left: 4.5rem !important;
}

.padding-5 {
    padding: 5rem !important;
}

.padding-top-5, .padding-y-5 {
    padding-top: 5rem !important;
}

.padding-right-5, .padding-x-5 {
    padding-right: 5rem !important;
}

.padding-bottom-5, .padding-y-5 {
    padding-bottom: 5rem !important;
}

.padding-left-5, .padding-x-5 {
    padding-left: 5rem !important;
}

.margin-auto {
    margin: auto !important;
}

.margin-top-auto, .margin-y-auto {
    margin-top: auto !important;
}

.margin-right-auto, .margin-x-auto {
    margin-right: auto !important;
}

.margin-bottom-auto, .margin-y-auto {
    margin-bottom: auto !important;
}

.margin-left-auto, .margin-x-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .margin-sm-0 {
        margin: 0 !important;
    }

    .margin-top-sm-0, .margin-y-sm-0 {
        margin-top: 0 !important;
    }

    .margin-right-sm-0, .margin-x-sm-0 {
        margin-right: 0 !important;
    }

    .margin-bottom-sm-0, .margin-y-sm-0 {
        margin-bottom: 0 !important;
    }

    .margin-left-sm-0, .margin-x-sm-0 {
        margin-left: 0 !important;
    }

    .margin-sm-05 {
        margin: 0.5rem !important;
    }

    .margin-top-sm-05, .margin-y-sm-05 {
        margin-top: 0.5rem !important;
    }

    .margin-right-sm-05, .margin-x-sm-05 {
        margin-right: 0.5rem !important;
    }

    .margin-bottom-sm-05, .margin-y-sm-05 {
        margin-bottom: 0.5rem !important;
    }

    .margin-left-sm-05, .margin-x-sm-05 {
        margin-left: 0.5rem !important;
    }

    .margin-sm-1 {
        margin: 1rem !important;
    }

    .margin-top-sm-1, .margin-y-sm-1 {
        margin-top: 1rem !important;
    }

    .margin-right-sm-1, .margin-x-sm-1 {
        margin-right: 1rem !important;
    }

    .margin-bottom-sm-1, .margin-y-sm-1 {
        margin-bottom: 1rem !important;
    }

    .margin-left-sm-1, .margin-x-sm-1 {
        margin-left: 1rem !important;
    }

    .margin-sm-15 {
        margin: 1.5rem !important;
    }

    .margin-top-sm-15, .margin-y-sm-15 {
        margin-top: 1.5rem !important;
    }

    .margin-right-sm-15, .margin-x-sm-15 {
        margin-right: 1.5rem !important;
    }

    .margin-bottom-sm-15, .margin-y-sm-15 {
        margin-bottom: 1.5rem !important;
    }

    .margin-left-sm-15, .margin-x-sm-15 {
        margin-left: 1.5rem !important;
    }

    .margin-sm-2 {
        margin: 2rem !important;
    }

    .margin-top-sm-2, .margin-y-sm-2 {
        margin-top: 2rem !important;
    }

    .margin-right-sm-2, .margin-x-sm-2 {
        margin-right: 2rem !important;
    }

    .margin-bottom-sm-2, .margin-y-sm-2 {
        margin-bottom: 2rem !important;
    }

    .margin-left-sm-2, .margin-x-sm-2 {
        margin-left: 2rem !important;
    }

    .margin-sm-25 {
        margin: 2.5rem !important;
    }

    .margin-top-sm-25, .margin-y-sm-25 {
        margin-top: 2.5rem !important;
    }

    .margin-right-sm-25, .margin-x-sm-25 {
        margin-right: 2.5rem !important;
    }

    .margin-bottom-sm-25, .margin-y-sm-25 {
        margin-bottom: 2.5rem !important;
    }

    .margin-left-sm-25, .margin-x-sm-25 {
        margin-left: 2.5rem !important;
    }

    .margin-sm-3 {
        margin: 3rem !important;
    }

    .margin-top-sm-3, .margin-y-sm-3 {
        margin-top: 3rem !important;
    }

    .margin-right-sm-3, .margin-x-sm-3 {
        margin-right: 3rem !important;
    }

    .margin-bottom-sm-3, .margin-y-sm-3 {
        margin-bottom: 3rem !important;
    }

    .margin-left-sm-3, .margin-x-sm-3 {
        margin-left: 3rem !important;
    }

    .margin-sm-35 {
        margin: 3.5rem !important;
    }

    .margin-top-sm-35, .margin-y-sm-35 {
        margin-top: 3.5rem !important;
    }

    .margin-right-sm-35, .margin-x-sm-35 {
        margin-right: 3.5rem !important;
    }

    .margin-bottom-sm-35, .margin-y-sm-35 {
        margin-bottom: 3.5rem !important;
    }

    .margin-left-sm-35, .margin-x-sm-35 {
        margin-left: 3.5rem !important;
    }

    .margin-sm-4 {
        margin: 4rem !important;
    }

    .margin-top-sm-4, .margin-y-sm-4 {
        margin-top: 4rem !important;
    }

    .margin-right-sm-4, .margin-x-sm-4 {
        margin-right: 4rem !important;
    }

    .margin-bottom-sm-4, .margin-y-sm-4 {
        margin-bottom: 4rem !important;
    }

    .margin-left-sm-4, .margin-x-sm-4 {
        margin-left: 4rem !important;
    }

    .margin-sm-45 {
        margin: 4.5rem !important;
    }

    .margin-top-sm-45, .margin-y-sm-45 {
        margin-top: 4.5rem !important;
    }

    .margin-right-sm-45, .margin-x-sm-45 {
        margin-right: 4.5rem !important;
    }

    .margin-bottom-sm-45, .margin-y-sm-45 {
        margin-bottom: 4.5rem !important;
    }

    .margin-left-sm-45, .margin-x-sm-45 {
        margin-left: 4.5rem !important;
    }

    .margin-sm-5 {
        margin: 5rem !important;
    }

    .margin-top-sm-5, .margin-y-sm-5 {
        margin-top: 5rem !important;
    }

    .margin-right-sm-5, .margin-x-sm-5 {
        margin-right: 5rem !important;
    }

    .margin-bottom-sm-5, .margin-y-sm-5 {
        margin-bottom: 5rem !important;
    }

    .margin-left-sm-5, .margin-x-sm-5 {
        margin-left: 5rem !important;
    }

    .padding-sm-0 {
        padding: 0 !important;
    }

    .padding-top-sm-0, .padding-y-sm-0 {
        padding-top: 0 !important;
    }

    .padding-right-sm-0, .padding-x-sm-0 {
        padding-right: 0 !important;
    }

    .padding-bottom-sm-0, .padding-y-sm-0 {
        padding-bottom: 0 !important;
    }

    .padding-left-sm-0, .padding-x-sm-0 {
        padding-left: 0 !important;
    }

    .padding-sm-05 {
        padding: 0.5rem !important;
    }

    .padding-top-sm-05, .padding-y-sm-05 {
        padding-top: 0.5rem !important;
    }

    .padding-right-sm-05, .padding-x-sm-05 {
        padding-right: 0.5rem !important;
    }

    .padding-bottom-sm-05, .padding-y-sm-05 {
        padding-bottom: 0.5rem !important;
    }

    .padding-left-sm-05, .padding-x-sm-05 {
        padding-left: 0.5rem !important;
    }

    .padding-sm-1 {
        padding: 1rem !important;
    }

    .padding-top-sm-1, .padding-y-sm-1 {
        padding-top: 1rem !important;
    }

    .padding-right-sm-1, .padding-x-sm-1 {
        padding-right: 1rem !important;
    }

    .padding-bottom-sm-1, .padding-y-sm-1 {
        padding-bottom: 1rem !important;
    }

    .padding-left-sm-1, .padding-x-sm-1 {
        padding-left: 1rem !important;
    }

    .padding-sm-15 {
        padding: 1.5rem !important;
    }

    .padding-top-sm-15, .padding-y-sm-15 {
        padding-top: 1.5rem !important;
    }

    .padding-right-sm-15, .padding-x-sm-15 {
        padding-right: 1.5rem !important;
    }

    .padding-bottom-sm-15, .padding-y-sm-15 {
        padding-bottom: 1.5rem !important;
    }

    .padding-left-sm-15, .padding-x-sm-15 {
        padding-left: 1.5rem !important;
    }

    .padding-sm-2 {
        padding: 2rem !important;
    }

    .padding-top-sm-2, .padding-y-sm-2 {
        padding-top: 2rem !important;
    }

    .padding-right-sm-2, .padding-x-sm-2 {
        padding-right: 2rem !important;
    }

    .padding-bottom-sm-2, .padding-y-sm-2 {
        padding-bottom: 2rem !important;
    }

    .padding-left-sm-2, .padding-x-sm-2 {
        padding-left: 2rem !important;
    }

    .padding-sm-25 {
        padding: 2.5rem !important;
    }

    .padding-top-sm-25, .padding-y-sm-25 {
        padding-top: 2.5rem !important;
    }

    .padding-right-sm-25, .padding-x-sm-25 {
        padding-right: 2.5rem !important;
    }

    .padding-bottom-sm-25, .padding-y-sm-25 {
        padding-bottom: 2.5rem !important;
    }

    .padding-left-sm-25, .padding-x-sm-25 {
        padding-left: 2.5rem !important;
    }

    .padding-sm-3 {
        padding: 3rem !important;
    }

    .padding-top-sm-3, .padding-y-sm-3 {
        padding-top: 3rem !important;
    }

    .padding-right-sm-3, .padding-x-sm-3 {
        padding-right: 3rem !important;
    }

    .padding-bottom-sm-3, .padding-y-sm-3 {
        padding-bottom: 3rem !important;
    }

    .padding-left-sm-3, .padding-x-sm-3 {
        padding-left: 3rem !important;
    }

    .padding-sm-35 {
        padding: 3.5rem !important;
    }

    .padding-top-sm-35, .padding-y-sm-35 {
        padding-top: 3.5rem !important;
    }

    .padding-right-sm-35, .padding-x-sm-35 {
        padding-right: 3.5rem !important;
    }

    .padding-bottom-sm-35, .padding-y-sm-35 {
        padding-bottom: 3.5rem !important;
    }

    .padding-left-sm-35, .padding-x-sm-35 {
        padding-left: 3.5rem !important;
    }

    .padding-sm-4 {
        padding: 4rem !important;
    }

    .padding-top-sm-4, .padding-y-sm-4 {
        padding-top: 4rem !important;
    }

    .padding-right-sm-4, .padding-x-sm-4 {
        padding-right: 4rem !important;
    }

    .padding-bottom-sm-4, .padding-y-sm-4 {
        padding-bottom: 4rem !important;
    }

    .padding-left-sm-4, .padding-x-sm-4 {
        padding-left: 4rem !important;
    }

    .padding-sm-45 {
        padding: 4.5rem !important;
    }

    .padding-top-sm-45, .padding-y-sm-45 {
        padding-top: 4.5rem !important;
    }

    .padding-right-sm-45, .padding-x-sm-45 {
        padding-right: 4.5rem !important;
    }

    .padding-bottom-sm-45, .padding-y-sm-45 {
        padding-bottom: 4.5rem !important;
    }

    .padding-left-sm-45, .padding-x-sm-45 {
        padding-left: 4.5rem !important;
    }

    .padding-sm-5 {
        padding: 5rem !important;
    }

    .padding-top-sm-5, .padding-y-sm-5 {
        padding-top: 5rem !important;
    }

    .padding-right-sm-5, .padding-x-sm-5 {
        padding-right: 5rem !important;
    }

    .padding-bottom-sm-5, .padding-y-sm-5 {
        padding-bottom: 5rem !important;
    }

    .padding-left-sm-5, .padding-x-sm-5 {
        padding-left: 5rem !important;
    }

    .margin-sm-auto {
        margin: auto !important;
    }

    .margin-top-sm-auto, .margin-y-sm-auto {
        margin-top: auto !important;
    }

    .margin-right-sm-auto, .margin-x-sm-auto {
        margin-right: auto !important;
    }

    .margin-bottom-sm-auto, .margin-y-sm-auto {
        margin-bottom: auto !important;
    }

    .margin-left-sm-auto, .margin-x-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .margin-md-0 {
        margin: 0 !important;
    }

    .margin-top-md-0, .margin-y-md-0 {
        margin-top: 0 !important;
    }

    .margin-right-md-0, .margin-x-md-0 {
        margin-right: 0 !important;
    }

    .margin-bottom-md-0, .margin-y-md-0 {
        margin-bottom: 0 !important;
    }

    .margin-left-md-0, .margin-x-md-0 {
        margin-left: 0 !important;
    }

    .margin-md-05 {
        margin: 0.5rem !important;
    }

    .margin-top-md-05, .margin-y-md-05 {
        margin-top: 0.5rem !important;
    }

    .margin-right-md-05, .margin-x-md-05 {
        margin-right: 0.5rem !important;
    }

    .margin-bottom-md-05, .margin-y-md-05 {
        margin-bottom: 0.5rem !important;
    }

    .margin-left-md-05, .margin-x-md-05 {
        margin-left: 0.5rem !important;
    }

    .margin-md-1 {
        margin: 1rem !important;
    }

    .margin-top-md-1, .margin-y-md-1 {
        margin-top: 1rem !important;
    }

    .margin-right-md-1, .margin-x-md-1 {
        margin-right: 1rem !important;
    }

    .margin-bottom-md-1, .margin-y-md-1 {
        margin-bottom: 1rem !important;
    }

    .margin-left-md-1, .margin-x-md-1 {
        margin-left: 1rem !important;
    }

    .margin-md-15 {
        margin: 1.5rem !important;
    }

    .margin-top-md-15, .margin-y-md-15 {
        margin-top: 1.5rem !important;
    }

    .margin-right-md-15, .margin-x-md-15 {
        margin-right: 1.5rem !important;
    }

    .margin-bottom-md-15, .margin-y-md-15 {
        margin-bottom: 1.5rem !important;
    }

    .margin-left-md-15, .margin-x-md-15 {
        margin-left: 1.5rem !important;
    }

    .margin-md-2 {
        margin: 4rem !important;
    }

    .margin-top-md-2, .margin-y-md-2 {
        margin-top: 2rem !important;
    }

    .margin-right-md-2, .margin-x-md-2 {
        margin-right: 2rem !important;
    }

    .margin-bottom-md-2, .margin-y-md-2 {
        margin-bottom: 2rem !important;
    }

    .margin-left-md-2, .margin-x-md-2 {
        margin-left: 2rem !important;
    }

    .margin-md-25 {
        margin: 2.5rem !important;
    }

    .margin-top-md-25, .margin-y-md-25 {
        margin-top: 2.5rem !important;
    }

    .margin-right-md-25, .margin-x-md-25 {
        margin-right: 2.5rem !important;
    }

    .margin-bottom-md-25, .margin-y-md-25 {
        margin-bottom: 2.5rem !important;
    }

    .margin-left-md-25, .margin-x-md-25 {
        margin-left: 2.5rem !important;
    }

    .margin-md-3 {
        margin: 3rem !important;
    }

    .margin-top-md-3, .margin-y-md-3 {
        margin-top: 3rem !important;
    }

    .margin-right-md-3, .margin-x-md-3 {
        margin-right: 3rem !important;
    }

    .margin-bottom-md-3, .margin-y-md-3 {
        margin-bottom: 3rem !important;
    }

    .margin-left-md-3, .margin-x-md-3 {
        margin-left: 3rem !important;
    }

    .margin-md-35 {
        margin: 3.5rem !important;
    }

    .margin-top-md-35, .margin-y-md-35 {
        margin-top: 3.5rem !important;
    }

    .margin-right-md-35, .margin-x-md-35 {
        margin-right: 3.5rem !important;
    }

    .margin-bottom-md-35, .margin-y-md-35 {
        margin-bottom: 3.5rem !important;
    }

    .margin-left-md-35, .margin-x-md-35 {
        margin-left: 3.5rem !important;
    }

    .margin-md-4 {
        margin: 4rem !important;
    }

    .margin-top-md-4, .margin-y-md-4 {
        margin-top: 4rem !important;
    }

    .margin-right-md-4, .margin-x-md-4 {
        margin-right: 4rem !important;
    }

    .margin-bottom-md-4, .margin-y-md-4 {
        margin-bottom: 4rem !important;
    }

    .margin-left-md-4, .margin-x-md-4 {
        margin-left: 4rem !important;
    }

    .margin-md-45 {
        margin: 4.5rem !important;
    }

    .margin-top-md-45, .margin-y-md-45 {
        margin-top: 4.5rem !important;
    }

    .margin-right-md-45, .margin-x-md-45 {
        margin-right: 4.5rem !important;
    }

    .margin-bottom-md-45, .margin-y-md-45 {
        margin-bottom: 4.5rem !important;
    }

    .margin-left-md-45, .margin-x-md-45 {
        margin-left: 4.5rem !important;
    }

    .margin-md-5 {
        margin: 5rem !important;
    }

    .margin-top-md-5, .margin-y-md-5 {
        margin-top: 5rem !important;
    }

    .margin-right-md-5, .margin-x-md-5 {
        margin-right: 5rem !important;
    }

    .margin-bottom-md-5, .margin-y-md-5 {
        margin-bottom: 5rem !important;
    }

    .margin-left-md-5, .margin-x-md-5 {
        margin-left: 5rem !important;
    }

    .padding-md-0 {
        padding: 0 !important;
    }

    .padding-top-md-0, .padding-y-md-0 {
        padding-top: 0 !important;
    }

    .padding-right-md-0, .padding-x-md-0 {
        padding-right: 0 !important;
    }

    .padding-bottom-md-0, .padding-y-md-0 {
        padding-bottom: 0 !important;
    }

    .padding-left-md-0, .padding-x-md-0 {
        padding-left: 0 !important;
    }

    .padding-md-05 {
        padding: 0.5rem !important;
    }

    .padding-top-md-05, .padding-y-md-05 {
        padding-top: 0.5rem !important;
    }

    .padding-right-md-05, .padding-x-md-05 {
        padding-right: 0.5rem !important;
    }

    .padding-bottom-md-05, .padding-y-md-05 {
        padding-bottom: 0.5rem !important;
    }

    .padding-left-md-05, .padding-x-md-05 {
        padding-left: 0.5rem !important;
    }

    .padding-md-1 {
        padding: 1rem !important;
    }

    .padding-top-md-1, .padding-y-md-1 {
        padding-top: 1rem !important;
    }

    .padding-right-md-1, .padding-x-md-1 {
        padding-right: 1rem !important;
    }

    .padding-bottom-md-1, .padding-y-md-1 {
        padding-bottom: 1rem !important;
    }

    .padding-left-md-1, .padding-x-md-1 {
        padding-left: 1rem !important;
    }

    .padding-md-15 {
        padding: 1.5rem !important;
    }

    .padding-top-md-15, .padding-y-md-15 {
        padding-top: 1.5rem !important;
    }

    .padding-right-md-15, .padding-x-md-15 {
        padding-right: 1.5rem !important;
    }

    .padding-bottom-md-15, .padding-y-md-15 {
        padding-bottom: 1.5rem !important;
    }

    .padding-left-md-15, .padding-x-md-15 {
        padding-left: 1.5rem !important;
    }

    .padding-md-2 {
        padding: 2rem !important;
    }

    .padding-top-md-2, .padding-y-md-2 {
        padding-top: 2rem !important;
    }

    .padding-right-md-2, .padding-x-md-2 {
        padding-right: 2rem !important;
    }

    .padding-bottom-md-2, .padding-y-md-2 {
        padding-bottom: 2rem !important;
    }

    .padding-left-md-2, .padding-x-md-2 {
        padding-left: 2rem !important;
    }

    .padding-md-25 {
        padding: 2.5rem !important;
    }

    .padding-top-md-25, .padding-y-md-25 {
        padding-top: 2.5rem !important;
    }

    .padding-right-md-25, .padding-x-md-25 {
        padding-right: 2.5rem !important;
    }

    .padding-bottom-md-25, .padding-y-md-25 {
        padding-bottom: 2.5rem !important;
    }

    .padding-left-md-25, .padding-x-md-25 {
        padding-left: 2.5rem !important;
    }

    .padding-md-3 {
        padding: 3rem !important;
    }

    .padding-top-md-3, .padding-y-md-3 {
        padding-top: 3rem !important;
    }

    .padding-right-md-3, .padding-x-md-3 {
        padding-right: 3rem !important;
    }

    .padding-bottom-md-3, .padding-y-md-3 {
        padding-bottom: 3rem !important;
    }

    .padding-left-md-3, .padding-x-md-3 {
        padding-left: 3rem !important;
    }

    .padding-md-35 {
        padding: 3.5rem !important;
    }

    .padding-top-md-35, .padding-y-md-35 {
        padding-top: 3.5rem !important;
    }

    .padding-right-md-35, .padding-x-md-35 {
        padding-right: 3.5rem !important;
    }

    .padding-bottom-md-35, .padding-y-md-35 {
        padding-bottom: 3.5rem !important;
    }

    .padding-left-md-35, .padding-x-md-35 {
        padding-left: 3.5rem !important;
    }

    .padding-md-4 {
        padding: 4rem !important;
    }

    .padding-top-md-4, .padding-y-md-4 {
        padding-top: 4rem !important;
    }

    .padding-right-md-4, .padding-x-md-4 {
        padding-right: 4rem !important;
    }

    .padding-bottom-md-4, .padding-y-md-4 {
        padding-bottom: 4rem !important;
    }

    .padding-left-md-4, .padding-x-md-4 {
        padding-left: 4rem !important;
    }

    .padding-md-45 {
        padding: 4.5rem !important;
    }

    .padding-top-md-45, .padding-y-md-45 {
        padding-top: 4.5rem !important;
    }

    .padding-right-md-45, .padding-x-md-45 {
        padding-right: 4.5rem !important;
    }

    .padding-bottom-md-45, .padding-y-md-45 {
        padding-bottom: 4.5rem !important;
    }

    .padding-left-md-45, .padding-x-md-45 {
        padding-left: 4.5rem !important;
    }

    .padding-md-5 {
        padding: 5rem !important;
    }

    .padding-top-md-5, .padding-y-md-5 {
        padding-top: 5rem !important;
    }

    .padding-right-md-5, .padding-x-md-5 {
        padding-right: 5rem !important;
    }

    .padding-bottom-md-5, .padding-y-md-5 {
        padding-bottom: 5rem !important;
    }

    .padding-left-md-5, .padding-x-md-5 {
        padding-left: 5rem !important;
    }

    .margin-md-auto {
        margin: auto !important;
    }

    .margin-top-md-auto, .margin-y-md-auto {
        margin-top: auto !important;
    }

    .margin-right-md-auto, .margin-x-md-auto {
        margin-right: auto !important;
    }

    .margin-bottom-md-auto, .margin-y-md-auto {
        margin-bottom: auto !important;
    }

    .margin-left-md-auto, .margin-x-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .margin-lg-0 {
        margin: 0 !important;
    }

    .margin-top-lg-0, .margin-y-lg-0 {
        margin-top: 0 !important;
    }

    .margin-right-lg-0, .margin-x-lg-0 {
        margin-right: 0 !important;
    }

    .margin-bottom-lg-0, .margin-y-lg-0 {
        margin-bottom: 0 !important;
    }

    .margin-left-lg-0, .margin-x-lg-0 {
        margin-left: 0 !important;
    }

    .margin-lg-05 {
        margin: 0.5rem !important;
    }

    .margin-top-lg-05, .margin-y-lg-05 {
        margin-top: 0.5rem !important;
    }

    .margin-right-lg-05, .margin-x-lg-05 {
        margin-right: 0.5rem !important;
    }

    .margin-bottom-lg-05, .margin-y-lg-05 {
        margin-bottom: 0.5rem !important;
    }

    .margin-left-lg-05, .margin-x-lg-05 {
        margin-left: 0.5rem !important;
    }

    .margin-lg-1 {
        margin: 1rem !important;
    }

    .margin-top-lg-1, .margin-y-lg-1 {
        margin-top: 1rem !important;
    }

    .margin-right-lg-1, .margin-x-lg-1 {
        margin-right: 1rem !important;
    }

    .margin-bottom-lg-1, .margin-y-lg-1 {
        margin-bottom: 1rem !important;
    }

    .margin-left-lg-1, .margin-x-lg-1 {
        margin-left: 1rem !important;
    }

    .margin-lg-15 {
        margin: 1.5rem !important;
    }

    .margin-top-lg-15, .margin-y-lg-15 {
        margin-top: 1.5rem !important;
    }

    .margin-right-lg-15, .margin-x-lg-15 {
        margin-right: 1.5rem !important;
    }

    .margin-bottom-lg-15, .margin-y-lg-15 {
        margin-bottom: 1.5rem !important;
    }

    .margin-left-lg-15, .margin-x-lg-15 {
        margin-left: 1.5rem !important;
    }

    .margin-lg-2 {
        margin: 2rem !important;
    }

    .margin-top-lg-2, .margin-y-lg-2 {
        margin-top: 2rem !important;
    }

    .margin-right-lg-2, .margin-x-lg-2 {
        margin-right: 2rem !important;
    }

    .margin-bottom-lg-2, .margin-y-lg-2 {
        margin-bottom: 2rem !important;
    }

    .margin-left-lg-2, .margin-x-lg-2 {
        margin-left: 2rem !important;
    }

    .margin-lg-25 {
        margin: 2.5rem !important;
    }

    .margin-top-lg-25, .margin-y-lg-25 {
        margin-top: 2.5rem !important;
    }

    .margin-right-lg-25, .margin-x-lg-25 {
        margin-right: 2.5rem !important;
    }

    .margin-bottom-lg-25, .margin-y-lg-25 {
        margin-bottom: 2.5rem !important;
    }

    .margin-left-lg-25, .margin-x-lg-25 {
        margin-left: 2.5rem !important;
    }

    .margin-lg-3 {
        margin: 3rem !important;
    }

    .margin-top-lg-3, .margin-y-lg-3 {
        margin-top: 3rem !important;
    }

    .margin-right-lg-3, .margin-x-lg-3 {
        margin-right: 3rem !important;
    }

    .margin-bottom-lg-3, .margin-y-lg-3 {
        margin-bottom: 3rem !important;
    }

    .margin-left-lg-3, .margin-x-lg-3 {
        margin-left: 3rem !important;
    }

    .margin-lg-35 {
        margin: 3.5rem !important;
    }

    .margin-top-lg-35, .margin-y-lg-35 {
        margin-top: 3.5rem !important;
    }

    .margin-right-lg-35, .margin-x-lg-35 {
        margin-right: 3.5rem !important;
    }

    .margin-bottom-lg-35, .margin-y-lg-35 {
        margin-bottom: 3.5rem !important;
    }

    .margin-left-lg-35, .margin-x-lg-35 {
        margin-left: 3.5rem !important;
    }

    .margin-lg-4 {
        margin: 4rem !important;
    }

    .margin-top-lg-4, .margin-y-lg-4 {
        margin-top: 4rem !important;
    }

    .margin-right-lg-4, .margin-x-lg-4 {
        margin-right: 4rem !important;
    }

    .margin-bottom-lg-4, .margin-y-lg-4 {
        margin-bottom: 4rem !important;
    }

    .margin-left-lg-4, .margin-x-lg-4 {
        margin-left: 4rem !important;
    }

    .margin-lg-45 {
        margin: 4.5rem !important;
    }

    .margin-top-lg-45, .margin-y-lg-45 {
        margin-top: 4.5rem !important;
    }

    .margin-right-lg-45, .margin-x-lg-45 {
        margin-right: 4.5rem !important;
    }

    .margin-bottom-lg-45, .margin-y-lg-45 {
        margin-bottom: 4.5rem !important;
    }

    .margin-left-lg-45, .margin-x-lg-45 {
        margin-left: 4.5rem !important;
    }

    .margin-lg-5 {
        margin: 5rem !important;
    }

    .margin-top-lg-5, .margin-y-lg-5 {
        margin-top: 5rem !important;
    }

    .margin-right-lg-5, .margin-x-lg-5 {
        margin-right: 5rem !important;
    }

    .margin-bottom-lg-5, .margin-y-lg-5 {
        margin-bottom: 5rem !important;
    }

    .margin-left-lg-5, .margin-x-lg-5 {
        margin-left: 5rem !important;
    }

    .padding-lg-0 {
        padding: 0 !important;
    }

    .padding-top-lg-0, .padding-y-lg-0 {
        padding-top: 0 !important;
    }

    .padding-right-lg-0, .padding-x-lg-0 {
        padding-right: 0 !important;
    }

    .padding-bottom-lg-0, .padding-y-lg-0 {
        padding-bottom: 0 !important;
    }

    .padding-left-lg-0, .padding-x-lg-0 {
        padding-left: 0 !important;
    }

    .padding-lg-05 {
        padding: 0.5rem !important;
    }

    .padding-top-lg-05, .padding-y-lg-05 {
        padding-top: 0.5rem !important;
    }

    .padding-right-lg-05, .padding-x-lg-05 {
        padding-right: 0.5rem !important;
    }

    .padding-bottom-lg-05, .padding-y-lg-05 {
        padding-bottom: 0.5rem !important;
    }

    .padding-left-lg-05, .padding-x-lg-05 {
        padding-left: 0.5rem !important;
    }

    .padding-lg-1 {
        padding: 1rem !important;
    }

    .padding-top-lg-1, .padding-y-lg-1 {
        padding-top: 1rem !important;
    }

    .padding-right-lg-1, .padding-x-lg-1 {
        padding-right: 1rem !important;
    }

    .padding-bottom-lg-1, .padding-y-lg-1 {
        padding-bottom: 1rem !important;
    }

    .padding-left-lg-1, .padding-x-lg-1 {
        padding-left: 1rem !important;
    }

    .padding-lg-15 {
        padding: 1.5rem !important;
    }

    .padding-top-lg-15, .padding-y-lg-15 {
        padding-top: 1.5rem !important;
    }

    .padding-right-lg-15, .padding-x-lg-15 {
        padding-right: 1.5rem !important;
    }

    .padding-bottom-lg-15, .padding-y-lg-15 {
        padding-bottom: 1.5rem !important;
    }

    .padding-left-lg-15, .padding-x-lg-15 {
        padding-left: 1.5rem !important;
    }

    .padding-lg-2 {
        padding: 2rem !important;
    }

    .padding-top-lg-2, .padding-y-lg-2 {
        padding-top: 2rem !important;
    }

    .padding-right-lg-2, .padding-x-lg-2 {
        padding-right: 2rem !important;
    }

    .padding-bottom-lg-2, .padding-y-lg-2 {
        padding-bottom: 2rem !important;
    }

    .padding-left-lg-2, .padding-x-lg-2 {
        padding-left: 2rem !important;
    }

    .padding-lg-25 {
        padding: 2.5rem !important;
    }

    .padding-top-lg-25, .padding-y-lg-25 {
        padding-top: 2.5rem !important;
    }

    .padding-right-lg-25, .padding-x-lg-25 {
        padding-right: 2.5rem !important;
    }

    .padding-bottom-lg-25, .padding-y-lg-25 {
        padding-bottom: 2.5rem !important;
    }

    .padding-left-lg-25, .padding-x-lg-25 {
        padding-left: 2.5rem !important;
    }

    .padding-lg-3 {
        padding: 3rem !important;
    }

    .padding-top-lg-3, .padding-y-lg-3 {
        padding-top: 3rem !important;
    }

    .padding-right-lg-3, .padding-x-lg-3 {
        padding-right: 3rem !important;
    }

    .padding-bottom-lg-3, .padding-y-lg-3 {
        padding-bottom: 3rem !important;
    }

    .padding-left-lg-3, .padding-x-lg-3 {
        padding-left: 3rem !important;
    }

    .padding-lg-35 {
        padding: 3.5rem !important;
    }

    .padding-top-lg-35, .padding-y-lg-35 {
        padding-top: 3.5rem !important;
    }

    .padding-right-lg-35, .padding-x-lg-35 {
        padding-right: 3.5rem !important;
    }

    .padding-bottom-lg-35, .padding-y-lg-35 {
        padding-bottom: 3.5rem !important;
    }

    .padding-left-lg-35, .padding-x-lg-35 {
        padding-left: 3.5rem !important;
    }

    .padding-lg-4 {
        padding: 4rem !important;
    }

    .padding-top-lg-4, .padding-y-lg-4 {
        padding-top: 4rem !important;
    }

    .padding-right-lg-4, .padding-x-lg-4 {
        padding-right: 4rem !important;
    }

    .padding-bottom-lg-4, .padding-y-lg-4 {
        padding-bottom: 4rem !important;
    }

    .padding-left-lg-4, .padding-x-lg-4 {
        padding-left: 4rem !important;
    }

    .padding-lg-45 {
        padding: 4.5rem !important;
    }

    .padding-top-lg-45, .padding-y-lg-45 {
        padding-top: 4.5rem !important;
    }

    .padding-right-lg-45, .padding-x-lg-45 {
        padding-right: 4.5rem !important;
    }

    .padding-bottom-lg-45, .padding-y-lg-45 {
        padding-bottom: 4.5rem !important;
    }

    .padding-left-lg-45, .padding-x-lg-45 {
        padding-left: 4.5rem !important;
    }

    .padding-lg-5 {
        padding: 5rem !important;
    }

    .padding-top-lg-5, .padding-y-lg-5 {
        padding-top: 5rem !important;
    }

    .padding-right-lg-5, .padding-x-lg-5 {
        padding-right: 5rem !important;
    }

    .padding-bottom-lg-5, .padding-y-lg-5 {
        padding-bottom: 5rem !important;
    }

    .padding-left-lg-5, .padding-x-lg-5 {
        padding-left: 5rem !important;
    }

    .margin-lg-auto {
        margin: auto !important;
    }

    .margin-top-lg-auto, .margin-y-lg-auto {
        margin-top: auto !important;
    }

    .margin-right-lg-auto, .margin-x-lg-auto {
        margin-right: auto !important;
    }

    .margin-bottom-lg-auto, .margin-y-lg-auto {
        margin-bottom: auto !important;
    }

    .margin-left-lg-auto, .margin-x-lg-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 1200px) {
    .margin-xl-0 {
        margin: 0 !important;
    }

    .margin-top-xl-0, .margin-y-xl-0 {
        margin-top: 0 !important;
    }

    .margin-right-xl-0, .margin-x-xl-0 {
        margin-right: 0 !important;
    }

    .margin-bottom-xl-0, .margin-y-xl-0 {
        margin-bottom: 0 !important;
    }

    .margin-left-xl-0, .margin-x-xl-0 {
        margin-left: 0 !important;
    }

    .margin-xl-05 {
        margin: 0.5rem !important;
    }

    .margin-top-xl-05, .margin-y-xl-05 {
        margin-top: 0.5rem !important;
    }

    .margin-right-xl-05, .margin-x-xl-05 {
        margin-right: 0.5rem !important;
    }

    .margin-bottom-xl-05, .margin-y-xl-05 {
        margin-bottom: 0.5rem !important;
    }

    .margin-left-xl-05, .margin-x-xl-05 {
        margin-left: 0.5rem !important;
    }

    .margin-xl-1 {
        margin: 1rem !important;
    }

    .margin-top-xl-1, .margin-y-xl-1 {
        margin-top: 1rem !important;
    }

    .margin-right-xl-1, .margin-x-xl-1 {
        margin-right: 1rem !important;
    }

    .margin-bottom-xl-1, .margin-y-xl-1 {
        margin-bottom: 1rem !important;
    }

    .margin-left-xl-1, .margin-x-xl-1 {
        margin-left: 1rem !important;
    }

    .margin-xl-15 {
        margin: 1.5rem !important;
    }

    .margin-top-xl-15, .margin-y-xl-15 {
        margin-top: 1.5rem !important;
    }

    .margin-right-xl-15, .margin-x-xl-15 {
        margin-right: 1.5rem !important;
    }

    .margin-bottom-xl-15, .margin-y-xl-15 {
        margin-bottom: 1.5rem !important;
    }

    .margin-left-xl-15, .margin-x-xl-15 {
        margin-left: 1.5rem !important;
    }

    .margin-xl-2 {
        margin: 2rem !important;
    }

    .margin-top-xl-2, .margin-y-xl-2 {
        margin-top: 2rem !important;
    }

    .margin-right-xl-2, .margin-x-xl-2 {
        margin-right: 2rem !important;
    }

    .margin-bottom-xl-2, .margin-y-xl-2 {
        margin-bottom: 2rem !important;
    }

    .margin-left-xl-2, .margin-x-xl-2 {
        margin-left: 2rem !important;
    }

    .margin-xl-25 {
        margin: 2.5rem !important;
    }

    .margin-top-xl-25, .margin-y-xl-25 {
        margin-top: 2.5rem !important;
    }

    .margin-right-xl-25, .margin-x-xl-25 {
        margin-right: 2.5rem !important;
    }

    .margin-bottom-xl-25, .margin-y-xl-25 {
        margin-bottom: 2.5rem !important;
    }

    .margin-left-xl-25, .margin-x-xl-25 {
        margin-left: 2.5rem !important;
    }

    .margin-xl-3 {
        margin: 3rem !important;
    }

    .margin-top-xl-3, .margin-y-xl-3 {
        margin-top: 3rem !important;
    }

    .margin-right-xl-3, .margin-x-xl-3 {
        margin-right: 3rem !important;
    }

    .margin-bottom-xl-3, .margin-y-xl-3 {
        margin-bottom: 3rem !important;
    }

    .margin-left-xl-3, .margin-x-xl-3 {
        margin-left: 3rem !important;
    }

    .margin-xl-35 {
        margin: 3.5rem !important;
    }

    .margin-top-xl-35, .margin-y-xl-35 {
        margin-top: 3.5rem !important;
    }

    .margin-right-xl-35, .margin-x-xl-35 {
        margin-right: 3.5rem !important;
    }

    .margin-bottom-xl-35, .margin-y-xl-35 {
        margin-bottom: 3.5rem !important;
    }

    .margin-left-xl-35, .margin-x-xl-35 {
        margin-left: 3.5rem !important;
    }

    .margin-xl-4 {
        margin: 4rem !important;
    }

    .margin-top-xl-4, .margin-y-xl-4 {
        margin-top: 4rem !important;
    }

    .margin-right-xl-4, .margin-x-xl-4 {
        margin-right: 4rem !important;
    }

    .margin-bottom-xl-4, .margin-y-xl-4 {
        margin-bottom: 4rem !important;
    }

    .margin-left-xl-4, .margin-x-xl-4 {
        margin-left: 4rem !important;
    }

    .margin-xl-45 {
        margin: 4.5rem !important;
    }

    .margin-top-xl-45, .margin-y-xl-45 {
        margin-top: 4.5rem !important;
    }

    .margin-right-xl-45, .margin-x-xl-45 {
        margin-right: 4.5rem !important;
    }

    .margin-bottom-xl-45, .margin-y-xl-45 {
        margin-bottom: 4.5rem !important;
    }

    .margin-left-xl-45, .margin-x-xl-45 {
        margin-left: 4.5rem !important;
    }

    .margin-xl-5 {
        margin: 5rem !important;
    }

    .margin-top-xl-5, .margin-y-xl-5 {
        margin-top: 5rem !important;
    }

    .margin-right-xl-5, .margin-x-xl-5 {
        margin-right: 5rem !important;
    }

    .margin-bottom-xl-5, .margin-y-xl-5 {
        margin-bottom: 5rem !important;
    }

    .margin-left-xl-5, .margin-x-xl-5 {
        margin-left: 5rem !important;
    }

    .padding-xl-0 {
        padding: 0 !important;
    }

    .padding-top-xl-0, .padding-y-xl-0 {
        padding-top: 0 !important;
    }

    .padding-right-xl-0, .padding-x-xl-0 {
        padding-right: 0 !important;
    }

    .padding-bottom-xl-0, .padding-y-xl-0 {
        padding-bottom: 0 !important;
    }

    .padding-left-xl-0, .padding-x-xl-0 {
        padding-left: 0 !important;
    }

    .padding-xl-05 {
        padding: 0.5rem !important;
    }

    .padding-top-xl-05, .padding-y-xl-05 {
        padding-top: 0.5rem !important;
    }

    .padding-right-xl-05, .padding-x-xl-05 {
        padding-right: 0.5rem !important;
    }

    .padding-bottom-xl-05, .padding-y-xl-05 {
        padding-bottom: 0.5rem !important;
    }

    .padding-left-xl-05, .padding-x-xl-05 {
        padding-left: 0.5rem !important;
    }

    .padding-xl-1 {
        padding: 1rem !important;
    }

    .padding-top-xl-1, .padding-y-xl-1 {
        padding-top: 1rem !important;
    }

    .padding-right-xl-1, .padding-x-xl-1 {
        padding-right: 1rem !important;
    }

    .padding-bottom-xl-1, .padding-y-xl-1 {
        padding-bottom: 1rem !important;
    }

    .padding-left-xl-1, .padding-x-xl-1 {
        padding-left: 1rem !important;
    }

    .padding-xl-15 {
        padding: 1.5rem !important;
    }

    .padding-top-xl-15, .padding-y-xl-15 {
        padding-top: 1.5rem !important;
    }

    .padding-right-xl-15, .padding-x-xl-15 {
        padding-right: 1.5rem !important;
    }

    .padding-bottom-xl-15, .padding-y-xl-15 {
        padding-bottom: 1.5rem !important;
    }

    .padding-left-xl-15, .padding-x-xl-15 {
        padding-left: 1.5rem !important;
    }

    .padding-xl-2 {
        padding: 2rem !important;
    }

    .padding-top-xl-2, .padding-y-xl-2 {
        padding-top: 2rem !important;
    }

    .padding-right-xl-2, .padding-x-xl-2 {
        padding-right: 2rem !important;
    }

    .padding-bottom-xl-2, .padding-y-xl-2 {
        padding-bottom: 2rem !important;
    }

    .padding-left-xl-2, .padding-x-xl-2 {
        padding-left: 2rem !important;
    }

    .padding-xl-25 {
        padding: 2.5rem !important;
    }

    .padding-top-xl-25, .padding-y-xl-25 {
        padding-top: 2.5rem !important;
    }

    .padding-right-xl-25, .padding-x-xl-25 {
        padding-right: 2.5rem !important;
    }

    .padding-bottom-xl-25, .padding-y-xl-25 {
        padding-bottom: 2.5rem !important;
    }

    .padding-left-xl-25, .padding-x-xl-25 {
        padding-left: 2.5rem !important;
    }

    .padding-xl-3 {
        padding: 3rem !important;
    }

    .padding-top-xl-3, .padding-y-xl-3 {
        padding-top: 3rem !important;
    }

    .padding-right-xl-3, .padding-x-xl-3 {
        padding-right: 3rem !important;
    }

    .padding-bottom-xl-3, .padding-y-xl-3 {
        padding-bottom: 3rem !important;
    }

    .padding-left-xl-3, .padding-x-xl-3 {
        padding-left: 3rem !important;
    }

    .padding-xl-35 {
        padding: 3.5rem !important;
    }

    .padding-top-xl-35, .padding-y-xl-35 {
        padding-top: 3.5rem !important;
    }

    .padding-right-xl-35, .padding-x-xl-35 {
        padding-right: 3.5rem !important;
    }

    .padding-bottom-xl-35, .padding-y-xl-35 {
        padding-bottom: 3.5rem !important;
    }

    .padding-left-xl-35, .padding-x-xl-35 {
        padding-left: 3.5rem !important;
    }

    .padding-xl-4 {
        padding: 4rem !important;
    }

    .padding-top-xl-4, .padding-y-xl-4 {
        padding-top: 4rem !important;
    }

    .padding-right-xl-4, .padding-x-xl-4 {
        padding-right: 4rem !important;
    }

    .padding-bottom-xl-4, .padding-y-xl-4 {
        padding-bottom: 4rem !important;
    }

    .padding-left-xl-4, .padding-x-xl-4 {
        padding-left: 4rem !important;
    }

    .padding-xl-45 {
        padding: 4.5rem !important;
    }

    .padding-top-xl-45, .padding-y-xl-45 {
        padding-top: 4.5rem !important;
    }

    .padding-right-xl-45, .padding-x-xl-45 {
        padding-right: 4.5rem !important;
    }

    .padding-bottom-xl-45, .padding-y-xl-45 {
        padding-bottom: 4.5rem !important;
    }

    .padding-left-xl-45, .padding-x-xl-45 {
        padding-left: 4.5rem !important;
    }

    .padding-xl-5 {
        padding: 5rem !important;
    }

    .padding-top-xl-5, .padding-y-xl-5 {
        padding-top: 5rem !important;
    }

    .padding-right-xl-5, .padding-x-xl-5 {
        padding-right: 5rem !important;
    }

    .padding-bottom-xl-5, .padding-y-xl-5 {
        padding-bottom: 5rem !important;
    }

    .padding-left-xl-5, .padding-x-xl-5 {
        padding-left: 5rem !important;
    }

    .margin-xl-auto {
        margin: auto !important;
    }

    .margin-top-xl-auto, .margin-y-xl-auto {
        margin-top: auto !important;
    }

    .margin-right-xl-auto, .margin-x-xl-auto {
        margin-right: auto !important;
    }

    .margin-bottom-xl-auto, .margin-y-xl-auto {
        margin-bottom: auto !important;
    }

    .margin-left-xl-auto, .margin-x-xl-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 1400px) {
    .margin-xxl-0 {
        margin: 0 !important;
    }

    .margin-top-xxl-0, .margin-y-xxl-0 {
        margin-top: 0 !important;
    }

    .margin-right-xxl-0, .margin-x-xxl-0 {
        margin-right: 0 !important;
    }

    .margin-bottom-xxl-0, .margin-y-xxl-0 {
        margin-bottom: 0 !important;
    }

    .margin-left-xxl-0, .margin-x-xxl-0 {
        margin-left: 0 !important;
    }

    .margin-xxl-05 {
        margin: 0.5rem !important;
    }

    .margin-top-xxl-05, .margin-y-xxl-05 {
        margin-top: 0.5rem !important;
    }

    .margin-right-xxl-05, .margin-x-xxl-05 {
        margin-right: 0.5rem !important;
    }

    .margin-bottom-xxl-05, .margin-y-xxl-05 {
        margin-bottom: 0.5rem !important;
    }

    .margin-left-xxl-05, .margin-x-xxl-05 {
        margin-left: 0.5rem !important;
    }

    .margin-xxl-1 {
        margin: 1rem !important;
    }

    .margin-top-xxl-1, .margin-y-xxl-1 {
        margin-top: 1rem !important;
    }

    .margin-right-xxl-1, .margin-x-xxl-1 {
        margin-right: 1rem !important;
    }

    .margin-bottom-xxl-1, .margin-y-xxl-1 {
        margin-bottom: 1rem !important;
    }

    .margin-left-xxl-1, .margin-x-xxl-1 {
        margin-left: 1rem !important;
    }

    .margin-xxl-15 {
        margin: 1.5rem !important;
    }

    .margin-top-xxl-15, .margin-y-xxl-15 {
        margin-top: 1.5rem !important;
    }

    .margin-right-xxl-15, .margin-x-xxl-15 {
        margin-right: 1.5rem !important;
    }

    .margin-bottom-xxl-15, .margin-y-xxl-15 {
        margin-bottom: 1.5rem !important;
    }

    .margin-left-xxl-15, .margin-x-xxl-15 {
        margin-left: 1.5rem !important;
    }

    .margin-xxl-2 {
        margin: 2rem !important;
    }

    .margin-top-xxl-2, .margin-y-xxl-2 {
        margin-top: 2rem !important;
    }

    .margin-right-xxl-2, .margin-x-xxl-2 {
        margin-right: 2rem !important;
    }

    .margin-bottom-xxl-2, .margin-y-xxl-2 {
        margin-bottom: 2rem !important;
    }

    .margin-left-xxl-2, .margin-x-xxl-2 {
        margin-left: 2rem !important;
    }

    .margin-xxl-25 {
        margin: 2.5rem !important;
    }

    .margin-top-xxl-25, .margin-y-xxl-25 {
        margin-top: 2.5rem !important;
    }

    .margin-right-xxl-25, .margin-x-xxl-25 {
        margin-right: 2.5rem !important;
    }

    .margin-bottom-xxl-25, .margin-y-xxl-25 {
        margin-bottom: 2.5rem !important;
    }

    .margin-left-xxl-25, .margin-x-xxl-25 {
        margin-left: 2.5rem !important;
    }

    .margin-xxl-3 {
        margin: 3rem !important;
    }

    .margin-top-xxl-3, .margin-y-xxl-3 {
        margin-top: 3rem !important;
    }

    .margin-right-xxl-3, .margin-x-xxl-3 {
        margin-right: 3rem !important;
    }

    .margin-bottom-xxl-3, .margin-y-xxl-3 {
        margin-bottom: 3rem !important;
    }

    .margin-left-xxl-3, .margin-x-xxl-3 {
        margin-left: 3rem !important;
    }

    .margin-xxl-35 {
        margin: 3.5rem !important;
    }

    .margin-top-xxl-35, .margin-y-xxl-35 {
        margin-top: 3.5rem !important;
    }

    .margin-right-xxl-35, .margin-x-xxl-35 {
        margin-right: 3.5rem !important;
    }

    .margin-bottom-xxl-35, .margin-y-xxl-35 {
        margin-bottom: 3.5rem !important;
    }

    .margin-left-xxl-35, .margin-x-xxl-35 {
        margin-left: 3.5rem !important;
    }

    .margin-xxl-4 {
        margin: 4rem !important;
    }

    .margin-top-xxl-4, .margin-y-xxl-4 {
        margin-top: 4rem !important;
    }

    .margin-right-xxl-4, .margin-x-xxl-4 {
        margin-right: 4rem !important;
    }

    .margin-bottom-xxl-4, .margin-y-xxl-4 {
        margin-bottom: 4rem !important;
    }

    .margin-left-xxl-4, .margin-x-xxl-4 {
        margin-left: 4rem !important;
    }

    .margin-xxl-45 {
        margin: 4.5rem !important;
    }

    .margin-top-xxl-45, .margin-y-xxl-45 {
        margin-top: 4.5rem !important;
    }

    .margin-right-xxl-45, .margin-x-xxl-45 {
        margin-right: 4.5rem !important;
    }

    .margin-bottom-xxl-45, .margin-y-xxl-45 {
        margin-bottom: 4.5rem !important;
    }

    .margin-left-xxl-45, .margin-x-xxl-45 {
        margin-left: 4.5rem !important;
    }

    .margin-xxl-5 {
        margin: 5rem !important;
    }

    .margin-top-xxl-5, .margin-y-xxl-5 {
        margin-top: 5rem !important;
    }

    .margin-right-xxl-5, .margin-x-xxl-5 {
        margin-right: 5rem !important;
    }

    .margin-bottom-xxl-5, .margin-y-xxl-5 {
        margin-bottom: 5rem !important;
    }

    .margin-left-xxl-5, .margin-x-xxl-5 {
        margin-left: 5rem !important;
    }

    .padding-xxl-0 {
        padding: 0 !important;
    }

    .padding-top-xxl-0, .padding-y-xxl-0 {
        padding-top: 0 !important;
    }

    .padding-right-xxl-0, .padding-x-xxl-0 {
        padding-right: 0 !important;
    }

    .padding-bottom-xxl-0, .padding-y-xxl-0 {
        padding-bottom: 0 !important;
    }

    .padding-left-xxl-0, .padding-x-xxl-0 {
        padding-left: 0 !important;
    }

    .padding-xxl-05 {
        padding: 0.5rem !important;
    }

    .padding-top-xxl-05, .padding-y-xxl-05 {
        padding-top: 0.5rem !important;
    }

    .padding-right-xxl-05, .padding-x-xxl-05 {
        padding-right: 0.5rem !important;
    }

    .padding-bottom-xxl-05, .padding-y-xxl-05 {
        padding-bottom: 0.5rem !important;
    }

    .padding-left-xxl-05, .padding-x-xxl-05 {
        padding-left: 0.5rem !important;
    }

    .padding-xxl-1 {
        padding: 1rem !important;
    }

    .padding-top-xxl-1, .padding-y-xxl-1 {
        padding-top: 1rem !important;
    }

    .padding-right-xxl-1, .padding-x-xxl-1 {
        padding-right: 1rem !important;
    }

    .padding-bottom-xxl-1, .padding-y-xxl-1 {
        padding-bottom: 1rem !important;
    }

    .padding-left-xxl-1, .padding-x-xxl-1 {
        padding-left: 1rem !important;
    }

    .padding-xxl-15 {
        padding: 1.5rem !important;
    }

    .padding-top-xxl-15, .padding-y-xxl-15 {
        padding-top: 1.5rem !important;
    }

    .padding-right-xxl-15, .padding-x-xxl-15 {
        padding-right: 1.5rem !important;
    }

    .padding-bottom-xxl-15, .padding-y-xxl-15 {
        padding-bottom: 1.5rem !important;
    }

    .padding-left-xxl-15, .padding-x-xxl-15 {
        padding-left: 1.5rem !important;
    }

    .padding-xxl-2 {
        padding: 2rem !important;
    }

    .padding-top-xxl-2, .padding-y-xxl-2 {
        padding-top: 2rem !important;
    }

    .padding-right-xxl-2, .padding-x-xxl-2 {
        padding-right: 2rem !important;
    }

    .padding-bottom-xxl-2, .padding-y-xxl-2 {
        padding-bottom: 2rem !important;
    }

    .padding-left-xxl-2, .padding-x-xxl-2 {
        padding-left: 2rem !important;
    }

    .padding-xxl-25 {
        padding: 2.5rem !important;
    }

    .padding-top-xxl-25, .padding-y-xxl-25 {
        padding-top: 2.5rem !important;
    }

    .padding-right-xxl-25, .padding-x-xxl-25 {
        padding-right: 2.5rem !important;
    }

    .padding-bottom-xxl-25, .padding-y-xxl-25 {
        padding-bottom: 2.5rem !important;
    }

    .padding-left-xxl-25, .padding-x-xxl-25 {
        padding-left: 2.5rem !important;
    }

    .padding-xxl-3 {
        padding: 3rem !important;
    }

    .padding-top-xxl-3, .padding-y-xxl-3 {
        padding-top: 3rem !important;
    }

    .padding-right-xxl-3, .padding-x-xxl-3 {
        padding-right: 3rem !important;
    }

    .padding-bottom-xxl-3, .padding-y-xxl-3 {
        padding-bottom: 3rem !important;
    }

    .padding-left-xxl-3, .padding-x-xxl-3 {
        padding-left: 3rem !important;
    }

    .padding-xxl-35 {
        padding: 3.5rem !important;
    }

    .padding-top-xxl-35, .padding-y-xxl-35 {
        padding-top: 3.5rem !important;
    }

    .padding-right-xxl-35, .padding-x-xxl-35 {
        padding-right: 3.5rem !important;
    }

    .padding-bottom-xxl-35, .padding-y-xxl-35 {
        padding-bottom: 3.5rem !important;
    }

    .padding-left-xxl-35, .padding-x-xxl-35 {
        padding-left: 3.5rem !important;
    }

    .padding-xxl-4 {
        padding: 4rem !important;
    }

    .padding-top-xxl-4, .padding-y-xxl-4 {
        padding-top: 4rem !important;
    }

    .padding-right-xxl-4, .padding-x-xxl-4 {
        padding-right: 4rem !important;
    }

    .padding-bottom-xxl-4, .padding-y-xxl-4 {
        padding-bottom: 4rem !important;
    }

    .padding-left-xxl-4, .padding-x-xxl-4 {
        padding-left: 4rem !important;
    }

    .padding-xxl-45 {
        padding: 4.5rem !important;
    }

    .padding-top-xxl-45, .padding-y-xxl-45 {
        padding-top: 4.5rem !important;
    }

    .padding-right-xxl-45, .padding-x-xxl-45 {
        padding-right: 4.5rem !important;
    }

    .padding-bottom-xxl-45, .padding-y-xxl-45 {
        padding-bottom: 4.5rem !important;
    }

    .padding-left-xxl-45, .padding-x-xxl-45 {
        padding-left: 4.5rem !important;
    }

    .padding-xxl-5 {
        padding: 5rem !important;
    }

    .padding-top-xxl-5, .padding-y-xxl-5 {
        padding-top: 5rem !important;
    }

    .padding-right-xxl-5, .padding-x-xxl-5 {
        padding-right: 5rem !important;
    }

    .padding-bottom-xxl-5, .padding-y-xxl-5 {
        padding-bottom: 5rem !important;
    }

    .padding-left-xxl-5, .padding-x-xxl-5 {
        padding-left: 5rem !important;
    }

    .margin-xxl-auto {
        margin: auto !important;
    }

    .margin-top-xxl-auto, .margin-y-xxl-auto {
        margin-top: auto !important;
    }

    .margin-right-xxl-auto, .margin-x-xxl-auto {
        margin-right: auto !important;
    }

    .margin-bottom-xxl-auto, .margin-y-xxl-auto {
        margin-bottom: auto !important;
    }

    .margin-left-xxl-auto, .margin-x-xxl-auto {
        margin-left: auto !important;
    }
}
/** OVERFLOWS **/
.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-scroll {
    overflow: scroll !important;
}

.overflow-x-auto {
    overflow-x: auto !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.overflow-x-visible {
    overflow-x: visible !important;
}

.overflow-x-scroll {
    overflow-x: scroll !important;
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.overflow-y-hidden {
    overflow-y: hidden !important;
}

.overflow-y-visible {
    overflow-y: visible !important;
}

.overflow-y-scroll {
    overflow-y: scroll;
}
/** POSITIONS **/
.position-absolute {
    position: absolute !important;
}

.position-relative {
    position: relative !important;
}

.position-static {
    position: static !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
    position: -webkit-sticky !important;
}
@media (min-width: 576px) {
    .position-sm-absolute {
        position: absolute !important;
    }

    .position-sm-relative {
        position: relative !important;
    }

    .position-sm-static {
        position: static !important;
    }

    .position-sm-fixed {
        position: fixed !important;
    }

    .position-sm-sticky {
        position: sticky !important;
        position: -webkit-sticky !important;
    }
}

@media (min-width: 768px) {
    .position-md-absolute {
        position: absolute !important;
    }

    .position-md-relative {
        position: relative !important;
    }

    .position-md-static {
        position: static !important;
    }

    .position-md-fixed {
        position: fixed !important;
    }

    .position-md-sticky {
        position: sticky !important;
        position: -webkit-sticky !important;
    }
}

@media (min-width: 992px) {
    .position-lg-absolute {
        position: absolute !important;
    }

    .position-lg-relative {
        position: relative !important;
    }

    .position-lg-static {
        position: static !important;
    }

    .position-lg-fixed {
        position: fixed !important;
    }

    .position-lg-sticky {
        position: sticky !important;
        position: -webkit-sticky !important;
    }
}

@media (min-width: 1200px) {
    .position-xl-absolute {
        position: absolute !important;
    }

    .position-xl-relative {
        position: relative !important;
    }

    .position-xl-static {
        position: static !important;
    }

    .position-xl-fixed {
        position: fixed !important;
    }

    .position-xl-sticky {
        position: sticky !important;
        position: -webkit-sticky !important;
    }
}

@media (min-width: 1400px) {
    .position-xxl-absolute {
        position: absolute !important;
    }

    .position-xxl-relative {
        position: relative !important;
    }

    .position-xxl-static {
        position: static !important;
    }

    .position-xxl-fixed {
        position: fixed !important;
    }

    .position-xxl-sticky {
        position: sticky !important;
        position: -webkit-sticky !important;
    }
}

/*
--------------------
  Shadows
--------------------  
*/

.box-shadow-light {
    box-shadow: 0 0 5px rgba(0, 1, 5, 0.2) !important;
}

.box-shadow-medium {
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.4) !important;
}

.box-shadow-dark {
    box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.4) !important;
}

.shadow-xs {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.shadow-s {
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
}

.shadow-m {
    box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
}

.shadow-l {
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
}

.shadow-xl {
    box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1);
}

.shadow-2xl {
    box-shadow: 0px 25px 50px -12px rgba(16, 24, 40, 0.25);
}
/** WIDTHS & HEIGHTS **/
.width-auto {
    width: auto !important;
}

.width-10 {
    width: 10% !important;
}

.width-15 {
    width: 15% !important;
}

.width-20 {
    width: 20% !important;
}

.width-25 {
    width: 25% !important;
}

.width-30 {
    width: 30% !important;
}

.width-35 {
    width: 35% !important;
}

.width-40 {
    width: 40% !important;
}

.width-45 {
    width: 45% !important;
}

.width-50 {
    width: 50% !important;
}

.width-55 {
    width: 55% !important;
}

.width-60 {
    width: 60% !important;
}

.width-65 {
    width: 65% !important;
}

.width-70 {
    width: 70% !important;
}

.width-75 {
    width: 75% !important;
}

.width-80 {
    width: 80% !important;
}

.width-85 {
    width: 85% !important;
}

.width-90 {
    width: 90% !important;
}

.width-95 {
    width: 95% !important;
}

.width-100 {
    width: 100% !important;
}

.min-width-100 {
    min-width: 100% !important;
}

.max-width-100 {
    max-width: 100% !important;
}

.height-auto {
    height: auto !important;
}

.height-100 {
    height: 100% !important;
}

.min-height-100 {
    min-height: 100% !important;
}

.max-height-100 {
    max-height: 100% !important;
}

@media screen and (min-width:576px) {
    .width-sm-auto {
        width: auto !important;
    }

    .width-sm-10 {
        width: 10% !important;
    }

    .width-sm-15 {
        width: 15% !important;
    }

    .width-sm-20 {
        width: 20% !important;
    }

    .width-sm-25 {
        width: 25% !important;
    }

    .width-sm-30 {
        width: 30% !important;
    }

    .width-sm-35 {
        width: 35% !important;
    }

    .width-sm-40 {
        width: 40% !important;
    }

    .width-sm-45 {
        width: 45% !important;
    }

    .width-sm-50 {
        width: 50% !important;
    }

    .width-sm-55 {
        width: 55% !important;
    }

    .width-sm-60 {
        width: 60% !important;
    }

    .width-sm-65 {
        width: 65% !important;
    }

    .width-sm-70 {
        width: 70% !important;
    }

    .width-sm-75 {
        width: 75% !important;
    }

    .width-sm-80 {
        width: 80% !important;
    }

    .width-sm-85 {
        width: 85% !important;
    }

    .width-sm-90 {
        width: 90% !important;
    }

    .width-sm-95 {
        width: 95% !important;
    }

    .width-sm-100 {
        width: 100% !important;
    }

    .min-width-sm-100 {
        min-width: 100% !important;
    }

    .max-width-sm-100 {
        max-width: 100% !important;
    }

    .height-sm-auto {
        height: auto !important;
    }

    .height-sm-100 {
        height: 100% !important;
    }

    .min-height-sm-100 {
        min-height: 100% !important;
    }

    .max-height-sm-100 {
        max-height: 100% !important;
    }
}

@media screen and (min-width: 768px) {
    .width-md-auto {
        width: auto !important;
    }

    .width-md-10 {
        width: 10% !important;
    }

    .width-md-15 {
        width: 15% !important;
    }

    .width-md-20 {
        width: 20% !important;
    }

    .width-md-25 {
        width: 25% !important;
    }

    .width-md-30 {
        width: 30% !important;
    }

    .width-md-35 {
        width: 35% !important;
    }

    .width-md-40 {
        width: 40% !important;
    }

    .width-md-45 {
        width: 45% !important;
    }

    .width-md-50 {
        width: 50% !important;
    }

    .width-md-55 {
        width: 55% !important;
    }

    .width-md-60 {
        width: 60% !important;
    }

    .width-md-65 {
        width: 65% !important;
    }

    .width-md-70 {
        width: 70% !important;
    }

    .width-md-75 {
        width: 75% !important;
    }

    .width-md-80 {
        width: 80% !important;
    }

    .width-md-85 {
        width: 85% !important;
    }

    .width-md-90 {
        width: 90% !important;
    }

    .width-md-95 {
        width: 95% !important;
    }

    .width-md-100 {
        width: 100% !important;
    }

    .min-width-md-100 {
        min-width: 100% !important;
    }

    .max-width-md-100 {
        max-width: 100% !important;
    }

    .height-md-auto {
        height: auto !important;
    }

    
    .height-md-100 {
        height: 100% !important;
    }

    .min-height-md-100 {
        min-height: 100% !important;
    }

    .max-height-md-100 {
        max-height: 100% !important;
    }
}

@media screen and (min-width: 992px) {
    .width-lg-auto {
        width: auto !important;
    }

    .width-lg-10 {
        width: 10% !important;
    }

    .width-lg-15 {
        width: 15% !important;
    }

    .width-lg-40 {
        width: 40% !important;
    }

    .width-lg-45 {
        width: 45% !important;
    }

    .width-lg-30 {
        width: 30% !important;
    }

    .width-lg-35 {
        width: 35% !important;
    }

    .width-lg-40 {
        width: 40% !important;
    }

    .width-lg-45 {
        width: 45% !important;
    }

    .width-lg-50 {
        width: 50% !important;
    }

    .width-lg-55 {
        width: 55% !important;
    }

    .width-lg-60 {
        width: 60% !important;
    }

    .width-lg-65 {
        width: 65% !important;
    }

    .width-lg-70 {
        width: 70% !important;
    }

    .width-lg-75 {
        width: 75% !important;
    }

    .width-lg-80 {
        width: 80% !important;
    }

    .width-lg-85 {
        width: 85% !important;
    }

    .width-lg-90 {
        width: 90% !important;
    }

    .width-lg-95 {
        width: 95% !important;
    }

    .width-lg-100 {
        width: 100% !important;
    }

    .min-width-lg-100 {
        min-width: 100% !important;
    }

    .max-width-lg-100 {
        max-width: 100% !important;
    }

    .height-lg-auto {
        height: auto !important;
    }

    .height-lg-100 {
        height: 100% !important;
    }

    .min-height-lg-100 {
        min-height: 100% !important;
    }

    .max-height-lg-100 {
        max-height: 100% !important;
    }
}
@media screen and (min-width: 1200px) {
    .width-xl-auto {
        width: auto !important;
    }

    .width-xl-10 {
        width: 10% !important;
    }

    .width-xl-15 {
        width: 15% !important;
    }

    .width-xl-40 {
        width: 40% !important;
    }

    .width-xl-45 {
        width: 45% !important;
    }

    .width-xl-30 {
        width: 30% !important;
    }

    .width-xl-35 {
        width: 35% !important;
    }

    .width-xl-40 {
        width: 40% !important;
    }

    .width-xl-45 {
        width: 45% !important;
    }

    .width-xl-50 {
        width: 50% !important;
    }

    .width-xl-55 {
        width: 55% !important;
    }

    .width-xl-60 {
        width: 60% !important;
    }

    .width-xl-65 {
        width: 65% !important;
    }

    .width-xl-70 {
        width: 70% !important;
    }

    .width-xl-75 {
        width: 75% !important;
    }

    .width-xl-80 {
        width: 80% !important;
    }

    .width-xl-85 {
        width: 85% !important;
    }

    .width-xl-90 {
        width: 90% !important;
    }

    .width-xl-95 {
        width: 95% !important;
    }

    .width-xl-100 {
        width: 100% !important;
    }

    .min-width-xl-100 {
        min-width: 100% !important;
    }

    .max-width-xl-100 {
        max-width: 100% !important;
    }

    .height-xl-auto {
        height: auto !important;
    }

    .height-xl-100 {
        height: 100% !important;
    }

    .min-height-xl-100 {
        min-height: 100% !important;
    }

    .max-height-xl-100 {
        max-height: 100% !important;
    }
}
@media screen and (min-width: 1400px) {
    .width-xxl-auto {
        width: auto !important;
    }

    .width-xxl-10 {
        width: 10% !important;
    }

    .width-xxl-15 {
        width: 15% !important;
    }

    .width-xxl-40 {
        width: 40% !important;
    }

    .width-xxl-45 {
        width: 45% !important;
    }

    .width-xxl-30 {
        width: 30% !important;
    }

    .width-xxl-35 {
        width: 35% !important;
    }

    .width-xxl-40 {
        width: 40% !important;
    }

    .width-xxl-45 {
        width: 45% !important;
    }

    .width-xxl-50 {
        width: 50% !important;
    }

    .width-xxl-55 {
        width: 55% !important;
    }

    .width-xxl-60 {
        width: 60% !important;
    }

    .width-xxl-65 {
        width: 65% !important;
    }

    .width-xxl-70 {
        width: 70% !important;
    }

    .width-xxl-75 {
        width: 75% !important;
    }

    .width-xxl-80 {
        width: 80% !important;
    }

    .width-xxl-85 {
        width: 85% !important;
    }

    .width-xxl-90 {
        width: 90% !important;
    }

    .width-xxl-95 {
        width: 95% !important;
    }

    .width-xxl-100 {
        width: 100% !important;
    }

    .min-width-xxl-100 {
        min-width: 100% !important;
    }

    .max-width-xxl-100 {
        max-width: 100% !important;
    }

    .height-xxl-auto {
        height: auto !important;
    }

    .height-xxl-100 {
        height: 100% !important;
    }

    .min-height-xxl-100 {
        min-height: 100% !important;
    }

    .max-height-xxl-100 {
        max-height: 100% !important;
    }
}
/** TEXT ALIGNMENT, TRANSFORM & DECORATION **/
.text-align-left {
    text-align: left !important;
}

.text-align-right {
    text-align: right !important;
}

.text-align-center {
    text-align: center !important;
}
@media (min-width: 576px) {
    .text-align-sm-left {
        text-align: left !important;
    }

    .text-align-sm-right {
        text-align: right !important;
    }

    .text-align-sm-center {
        text-align: center !important;
    }
}
@media (min-width: 768px) {
    .text-align-md-left {
        text-align: left !important;
    }

    .text-align-md-right {
        text-align: right !important;
    }

    .text-align-md-center {
        text-align: center !important;
    }
}
@media (min-width: 992px) {
    .text-align-lg-left {
        text-align: left !important;
    }

    .text-align-lg-right {
        text-align: right !important;
    }

    .text-align-lg-center {
        text-align: center !important;
    }
}
@media (min-width: 1200px) {
    .text-align-xl-left {
        text-align: left !important;
    }

    .text-align-xl-right {
        text-align: right !important;
    }

    .text-align-xl-center {
        text-align: center !important;
    }
}
@media (min-width: 1400px) {
    .text-align-xxl-left {
        text-align: left !important;
    }

    .text-align-xxl-right {
        text-align: right !important;
    }

    .text-align-xxl-center {
        text-align: center !important;
    }
}
    .text-transform-uppercase {
        text-transform: uppercase !important;
    }

    .text-transform-capitalize {
        text-transform: capitalize !important;
    }

    .text-transform-none {
        text-transform: none !important;
    }

    .text-transform-lowercase {
        text-transform: lowercase !important;
    }

    .text-transform-initial {
        text-transform: initial !important;
    }

    .text-transform-inherit {
        text-transform: inherit !important;
    }
    .text-decoration-none {
        text-decoration:none!important;
    }
    .text-decoration-underline {
        text-decoration:underline!important;
    }
    .text-decoration-dotted {
        text-decoration: underline!important;
        text-decoration-style: dotted!important;
    }
    /** DISPLAY **/
    .display-none {
        display: none !important;
    }

    .display-inline {
        display: inline !important;
    }

    .display-inline-block {
        display: inline-block !important;
    }

    .display-block {
        display: block !important;
    }

    .display-table {
        display: table !important;
    }

    .display-table-row {
        display: table-row !important;
    }

    .display-table-cell {
        display: table-cell !important;
    }

    .display-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .display-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }

    .visibility-hidden {
        visibility: hidden !important;
    }

    .visibility-visible {
        visibility: visible !important;
    }

    @media (min-width: 576px) {
        .display-sm-none {
            display: none !important;
        }

        .display-sm-inline {
            display: inline !important;
        }

        .display-sm-inline-block {
            display: inline-block !important;
        }

        .display-sm-block {
            display: block !important;
        }

        .display-sm-table {
            display: table !important;
        }

        .display-sm-table-row {
            display: table-row !important;
        }

        .display-sm-table-cell {
            display: table-cell !important;
        }

        .display-sm-flex {
            display: -ms-flexbox !important;
            display: flex !important;
        }

        .display-sm-inline-flex {
            display: -ms-inline-flexbox !important;
            display: inline-flex !important;
        }

        .visibility-sm-hidden {
            visibility: hidden !important;
        }

        .visibility-sm-visible {
            visibility: visible !important;
        }
    }

    @media (min-width: 768px) {
        .display-md-none {
            display: none !important;
        }

        .display-md-inline {
            display: inline !important;
        }

        .display-md-inline-block {
            display: inline-block !important;
        }

        .display-md-block {
            display: block !important;
        }

        .display-md-table {
            display: table !important;
        }

        .display-md-table-row {
            display: table-row !important;
        }

        .display-md-table-cell {
            display: table-cell !important;
        }

        .display-md-flex {
            display: -ms-flexbox !important;
            display: flex !important;
        }

        .display-md-inline-flex {
            display: -ms-inline-flexbox !important;
            display: inline-flex !important;
        }

        .visibility-md-hidden {
            visibility: hidden !important;
        }

        .visibility-md-visible {
            visibility: visible !important;
        }
    }

    @media (min-width: 992px) {
        .display-lg-none {
            display: none !important;
        }

        .display-lg-inline {
            display: inline !important;
        }

        .display-lg-inline-block {
            display: inline-block !important;
        }

        .display-lg-block {
            display: block !important;
        }

        .display-lg-table {
            display: table !important;
        }

        .display-lg-table-row {
            display: table-row !important;
        }

        .display-lg-table-cell {
            display: table-cell !important;
        }

        .display-lg-flex {
            display: -ms-flexbox !important;
            display: flex !important;
        }

        .display-lg-inline-flex {
            display: -ms-inline-flexbox !important;
            display: inline-flex !important;
        }

        .visibility-lg-hidden {
            visibility: hidden !important;
        }

        .visibility-lg-visible {
            visibility: visible !important;
        }
    }
@media (min-width: 1200px) {
    .display-xl-none {
        display: none !important;
    }

    .display-xl-inline {
        display: inline !important;
    }

    .display-xl-inline-block {
        display: inline-block !important;
    }

    .display-xl-block {
        display: block !important;
    }

    .display-xl-table {
        display: table !important;
    }

    .display-xl-table-row {
        display: table-row !important;
    }

    .display-xl-table-cell {
        display: table-cell !important;
    }

    .display-xl-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .display-xl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }

    .visibility-xl-hidden {
        visibility: hidden !important;
    }

    .visibility-xl-visible {
        visibility: visible !important;
    }
}
@media (min-width: 1400px) {
    .display-xxl-none {
        display: none !important;
    }

    .display-xxl-inline {
        display: inline !important;
    }

    .display-xxl-inline-block {
        display: inline-block !important;
    }

    .display-xxl-block {
        display: block !important;
    }

    .display-xxl-table {
        display: table !important;
    }

    .display-xxl-table-row {
        display: table-row !important;
    }

    .display-xxl-table-cell {
        display: table-cell !important;
    }

    .display-xxl-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .display-xxl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }

    .visibility-xxl-hidden {
        visibility: hidden !important;
    }

    .visibility-xxl-visible {
        visibility: visible !important;
    }
}
    /** OPACITY */
    .opacity-0 {
        opacity: 0 !important;
    }

    .opacity-1 {
        opacity: 1 !important;
    }
    /** VERTICAL ALIGN **/
    .vertical-align-baseline {
        vertical-align: baseline;
    }

    .vertical-align-top {
        vertical-align: top;
    }

    .vertical-align-middle {
        vertical-align: middle;
    }

    .vertical-align-bottom {
        vertical-align: bottom;
    }
    /** FLOATS **/
    .float-left {
        float: left !important;
    }

    .float-right {
        float: right !important;
    }

    .float-none {
        float: none !important;
    }

    .clear-left {
        clear: left !important;
    }

    .clear-right {
        clear: right !important;
    }

    .clear-both {
        clear: both !important;
    }

    @media (min-width: 576px) {
        .float-sm-left {
            float: left !important;
        }

        .float-sm-right {
            float: right !important;
        }

        .float-sm-none {
            float: none !important;
        }

        .clear-sm-left {
            clear: left !important;
        }

        .clear-sm-right {
            clear: right !important;
        }

        .clear-sm-both {
            clear: both !important;
        }
    }

    @media (min-width: 768px) {
        .float-md-left {
            float: left !important;
        }

        .float-md-right {
            float: right !important;
        }

        .float-md-none {
            float: none !important;
        }

        .clear-md-left {
            clear: left !important;
        }

        .clear-md-right {
            clear: right !important;
        }

        .clear-md-both {
            clear: both !important;
        }
    }

    @media (min-width: 992px) {
        .float-lg-left {
            float: left !important;
        }

        .float-lg-right {
            float: right !important;
        }

        .float-lg-none {
            float: none !important;
        }

        .clear-lg-left {
            clear: left !important;
        }

        .clear-lg-right {
            clear: right !important;
        }

        .clear-lg-both {
            clear: both !important;
        }
    }
@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }

    .clear-xl-left {
        clear: left !important;
    }

    .clear-xl-right {
        clear: right !important;
    }

    .clear-xl-both {
        clear: both !important;
    }
}
@media (min-width: 1400px) {
    .float-xxl-left {
        float: left !important;
    }

    .float-xxl-right {
        float: right !important;
    }

    .float-xxl-none {
        float: none !important;
    }

    .clear-xxl-left {
        clear: left !important;
    }

    .clear-xxl-right {
        clear: right !important;
    }

    .clear-xxl-both {
        clear: both !important;
    }
}
    /* Box Sizing */
    .box-border {
        box-sizing: border-box !important;
    }

    .box-content {
        box-sizing: content-box !important;
    }
    /* Icon Sizes */
    .icon-small {
        font-size: 0.9rem !important;
    }

    .icon-medium {
        font-size: 1.4rem !important;
    }

    .icon-large {
        font-size: 2.4rem !important;
    }

    .icon-1x {
        font-size: 1rem !important;
    }

    .icon-2x {
        font-size: 2rem !important;
    }

    .icon-3x {
        font-size: 3rem !important;
    }

    .icon-4x {
        font-size: 4rem !important;
    }

    .icon-5x {
        font-size: 5rem !important;
    }

    .icon-6x {
        font-size: 6rem !important;
    }
    /** FORMS **/
    .form-input {
        display: block;
        width: 100%;
        height: auto!important;
        padding: 10px 20px;
        font-size: 0.95rem;
        font-weight: normal;
        line-height: 1.4;
        color: inherit;
        background-color: #fff;
        border: 1px solid var(--neutral-400);
        border-radius: 8px;
        box-sizing: border-box;
        margin: 0;
    }

.form-input:hover,
.form-input:hover + .input-button-right {
    border-color: var(--neutral-500);
}

        .form-input:focus, .form-input:focus + .input-button-right {
            outline: none;
        }

    .form-input-small {
        height: auto;
        padding: 6px 12px;
        font-size: 0.9rem;
        box-sizing: border-box;
    }

    .input-with-button {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
        box-sizing: border-box;
    }

    .form-select-wrapper select.form-input {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        text-overflow:ellipsis;
        padding:0.6rem 1.8rem 0.6rem 1rem;
        cursor:pointer;
    }

    .form-select-wrapper {
        position: relative;
        cursor: pointer;
    }

        .form-select-wrapper:after {
            position: absolute;
            top: 31%;
            right: 1rem;
            pointer-events: none;
            line-height: 1;
        }

        .form-select-wrapper select.form-input::-ms-expand {
            display: none;
        }

    .input-with-button > .custom-file, .input-with-button > .form-input {
        position: relative;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0;
        box-sizing: border-box;
    }

    .input-with-button:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after, .input-with-button:not(.has-validation) > .form-input:not(:last-child) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

.input-button-right {
    margin-left: -1px;
    display: flex;
    display: -ms-flexbox;
    border: 1px solid var(--neutral-400);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: white;
    align-items:center;
}

    .input-button-right > .button {
        border-left: 0;
        transition: opacity .15s ease-in-out;
        opacity: 1;        
        border-radius: 0 !important;
        padding-left: 1rem;
        padding-right: 0.9rem;
        min-height: auto;
        padding-top: 0;
        padding-bottom: 0;
        background:transparent;
    }

            .input-button-right > .button:hover {
                border-left: 0;
                opacity: 0.7;
            }

    .border-radius-pill + .input-button-right {
        border-top-right-radius: 50rem!important;
        border-bottom-right-radius: 50rem!important;
        background:white;
    }

    textarea.form-input {
        height: auto;
        margin: 0;
    }

    .form-checkbox, .form-radio {
        position: relative;
        z-index: 1;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
        margin-bottom: .5rem;
        box-sizing: border-box;
    }

    .form-checkbox-input, .form-radio-input {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1.3rem;
        height: 1.3rem;
        opacity: 0;
        box-sizing: border-box;
        margin: 0;
    }

    .form-checkbox-inline, .form-radio-inline {
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin-right: 1.5rem;
    }

    .form-checkbox-label, .form-radio-label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
        padding-left: 0.2rem;
        box-sizing: border-box;
        display: inline-block;
        line-height: 1.55em;
    }

    .form-checkbox-input:checked ~ .form-checkbox-label::before {
        border-radius: 0.3rem;
    }

    .form-radio .form-radio-label:before {
        border-radius: 50%;
    }

.form-checkbox-label::before,
.form-radio-label::before {
    position: absolute;
    top: 0;
    left: -1.5rem;
    display: block;
    width: 1.3rem;
    height: 1.3rem;
    pointer-events: none;
    content: "";
    background-color: var(--white);
    border: var(--neutral-200) solid 1px;
    border-radius: 0.3rem;
    box-sizing: border-box;
}

    .form-checkbox-label::after, .form-radio-label::after {
        position: absolute;
        top: 0;
        left: -1.5rem;
        display: block;
        width: 1.3rem;
        height: 1.3rem;
        content: "";
        background: 50%/50% 50% no-repeat;
        box-sizing: border-box;
    }

    .form-checkbox .form-checkbox-input:checked ~ .form-checkbox-label::after {
        font-size: 0.8rem;
        padding-left: 0.2rem;
    }

    .form-radio .form-radio-input:checked ~ .form-radio-label::after {
        border-radius: 50%;
        padding: 0.3rem;
    }

    .form-checkbox-small label {
        font-size: 0.9rem;
    }

    .form-checkbox-small .form-checkbox-label:after, .form-checkbox-small .form-checkbox-label:before {
        width: 1.1rem;
        height: 1.1rem;
    }

    .form-checkbox-small .form-checkbox-input:checked ~ .form-checkbox-label::after {
        font-size: 0.7rem;
    }

    .form-radio-small label {
        font-size: 0.9rem;
    }

    .form-radio-small .form-radio-label:after, .form-radio-small .form-radio-label:before {
        width: 1.1rem;
        height: 1.1rem;
    }

    .form-radio-small .form-radio-input:checked ~ .form-radio-label::after {
        font-size: 0.7rem;
    }

    .form-radio label:hover, .form-checkbox label:hover {
        cursor: pointer;
    }
    /** LISTS **/
    .list, .list-stacked, .list-accordion {
        margin: 0;
        padding: 0 !important;
        list-style-type: none;
        color: #000;
        box-sizing: border-box;
    }

        .list li, .list-stacked li {
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
        }

    .ordered-list-solid li, .ordered-list-outline li {
        counter-increment: step-counter;
    }

        .ordered-list-solid li::before, .ordered-list-outline li::before {
            content: counter(step-counter);
            margin-right: 0.6em;
            border-radius: 50%;
            width: 1.2em;
            height: 1.2em;
            display: inline-block;
            padding: 0.2em;
            box-sizing: content-box;
            text-align: center;
            line-height: normal;
        }

        .ordered-list-outline li::before {
            border: 1px solid transparent;
            background-color: #fff;
        }

    .unordered-list-tick li::before, .unordered-list-tick-circle li::before {
        margin-right: 0.8em;
        font-size: 1rem;
    }

    .unordered-list-disc li {
        margin-right: 0.8em;
        list-style: disc;
    }

    .unordered-list-circle li {
        margin-right: 0.8em;
        list-style: circle;
    }

    .list-stacked, .list-accordion {
        border-radius: 0.3rem;
        list-style: none;
        padding: 0;
    }

    ul.list-accordion {
        padding-left: 0;
    }

    .list-stacked li:first-child, .list-accordion li:first-child, .list-accordion li:first-child .toggle-accordion.open, .list-accordion li:first-child .toggle-accordion:hover {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }

    .list-stacked li {
        position: relative;
        display: block;
        padding: .65rem 1.25rem;
        background-color: #fff;
        border: 1px solid rgba(223,223,223,1);
    }

    .list-item + .list-item, .accordion-item + .accordion-item {
        border-top-width: 0 !important;
    }

    .list-stacked li:last-child, .list-accordion li:last-child {
        border-bottom-right-radius: inherit;
        border-bottom-left-radius: inherit;
    }

    .list-accordion li.accordion-item {
        position: relative;
        border: 1px solid rgba(223,223,223,1);
    }

    .list-accordion .toggle-accordion {
        display: block;
        position: relative;
        padding: .65rem 2rem 0.65rem 1.2rem !important;
    }
.toggle-accordion svg {
    pointer-events:none;
}

.list-accordion.chevron-right .toggle-accordion:after, .list-accordion.chevron-left .toggle-accordion:before {
    transform: none;
    transform: rotate(0deg);
    transition: transform .5s linear;
    position: absolute;
    right: 0.5rem;
    top: 0.65rem;
}

    .list-accordion.chevron-right .toggle-accordion.open:after, .list-accordion.chevron-left .toggle-accordion.open:before {
        transform: rotate(180deg);
    }

    .list-accordion.plus-right .toggle-accordion:after, .list-accordion.plus-left .toggle-accordion:before {
        transform: none;
        transform: rotate(0deg);
        transition: transform .5s linear;
        position: absolute;
        right: 0.5rem;
        top: 0.65rem;
    }

    .list-accordion.plus-right .toggle-accordion.open:after, .list-accordion.plus-left .toggle-accordion.open:before {
        transform: rotate(180deg);
    }

    .chevron-left .accordion-item .toggle-accordion, .plus-left .accordion-item .toggle-accordion {
        padding-left: 3.5rem !important;
    }

    .list-accordion.chevron-left .toggle-accordion:before, .list-accordion.plus-left .toggle-accordion:before {
        right: auto;
        left: 1.2rem !important;
    }

    .toggle-accordion.open, .toggle-accordion:hover {
        background-color: #f8f8f8;
    }

    .list-accordion .toggle-accordion:hover {
        cursor: pointer;
    }

    .accordion-inner {
        display: none;
        list-style: none;
        padding: 0.5rem 0;
        box-sizing: border-box;
    }

        .accordion-inner li {
            padding: .3rem 1.6rem;
            box-sizing: border-box;
        }

        .accordion-inner .toggle-accordion {
            padding-right: 0;
            padding-left: 0;
        }

        .accordion-inner.list-stacked li:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        .accordion-inner.list-stacked li:last-child, .list-accordion li:last-child .toggle-accordion:hover {
            border-bottom-left-radius: inherit;
            border-bottom-right-radius: inherit;
        }

    .list-no-border .accordion-item {
        border: 0 !important;
    }
.list-faq {
    border-radius: 0 !important;
}

    .list-faq .accordion-item {
        border-top: 0 !important;
        border-right: 0 !important;
        border-left: 0 !important;
    }
    .list-stack .accordion-item {
        margin-bottom: 0.8rem;
        border-radius: 0.3rem;
    }

        .list-stack .accordion-item + .accordion-item {
            border-top-width: 1px !important;
        }

    .list-accordion li:last-child .toggle-accordion.open:hover {
        border-radius: 0;
    }
    /** PAGINATION **/
    .page-navigation-container {
        background-color: transparent !important;
    }

    .page-navigation {
        display: -ms-flexbox;
        display: flex;
        padding-left: 0;
        list-style: none;
        border-radius: 0.3rem;
        box-sizing: border-box;
        align-items: center;
    }

    .page-link {
        position: relative;
        display: block;
        font-size: 0.9rem;
        padding: 0.5rem 0.75rem;
        margin-right: 0.5em;
        line-height: 1;
        color: #1B1C1D;
        background-color: #fff;
        border: 1px solid #dddddd;
        border-radius: 0.3rem;
        box-sizing: border-box;
        text-decoration: none;
    }

        .page-link:hover {
            z-index: 2;
            text-decoration: none;
        }

        .page-link:focus {
            z-index: 3;
            outline: 0;
        }

    .page-item:first-child .page-link {
        margin-left: 0;
        background-color: transparent;
        border-color: transparent;
    }

    .page-item:last-child .page-link {
        border-radius: 0;
        margin-right: 0;
        background-color: transparent;
        border-color: transparent;
    }

    .page-item.active .page-link {
        z-index: 3;
    }

    .page-item.disabled .page-link {
        color: #dddddd;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dddddd;
    }
    /** BADGES **/
.badge {
    display: inline-block;
    font-size: 0.8rem !important;
    padding: 5px 10px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-small {
    font-size: 12px !important;
    padding: 4px 8px;
}

.badge-large {
    padding: 6px 8px;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}

a.badge:hover, a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.button .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}
/** ALERTS **/
.alert {
    position: relative;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 6px;
}
    /** ROUNDELS **/
    .roundel {
        display: inline-flex;
        display: -ms-inline-flexbox;
        border-radius: 50%;
        box-sizing: border-box;
        text-align: center;
        z-index: 1;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        box-sizing: border-box;
    }

        .roundel span {
            display: block;
            font-family: gibson_regular,sans-serif;
            text-transform: uppercase;
            line-height: normal;
            padding-left: 0.3em;
            padding-right: 0.3em;
            box-sizing: border-box;
        }

    .roundel-xsmall {
        width: 50px;
        height: 50px;
        font-size: 0.75rem;
    }

    .roundel-small {
        width: 65px;
        height: 65px;
        font-size: 0.85rem;
    }

    .roundel-medium {
        width: 75px;
        height: 75px;
        font-size: 0.95rem;
        font-weight: 600;
    }

    .roundel-large {
        width: 85px;
        height: 85px;
        font-size: 1.1rem;
        font-weight: 600;
    }

.roundel-top-right {
    position: absolute;
    top: 1.5em;
    right: 1.5em;
}

.roundel-bottom-right {
    position: absolute;
    bottom: 1.5em;
    right: 1.5em;
}

.roundel-top-left{
    position: absolute;
    top: 1.5em;
    left: 1.5em;
}

.roundel-bottom-left {
    position: absolute;
    bottom: 1.5em;
    left: 1.5em;
}

/** SASHES **/
.b-sash {
    display: inline-flex;
    display: -ms-inline-flexbox;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    z-index: 1;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    box-sizing: border-box;
    border: 1px solid transparent;
    font-size: 0.9em;
    padding: 4px 8px;
    line-height:1.4;
}

    .b-sash span {
        display: block;
        font-family: gibson_regular,sans-serif;
        line-height: normal;
        box-sizing: border-box;
    }
.b-sash-top-right {
    position: absolute;
    top: 1em;
    right: 1em;
}

.b-sash-bottom-right {
    position: absolute;
    bottom: 1em;
    right: 1em;
}

.b-sash-top-left {
    position: absolute;
    top: 1em;
    left: 1em;
}

.b-sash-bottom-left {
    position: absolute;
    bottom: 1em;
    left: 1em;
}
.b-sash-small {
    font-size: 0.9em;
}

.b-sash-medium {
    font-size: 1em;
    padding: 8px 16px;
}

.b-sash-large {
    font-size: 1.1em;
    padding: 8px 16px;
}
@media (min-width: 576px) {
    .b-sash-medium-sm {
        font-size: 1em;
    }
    .b-sash-large-sm {
        font-size: 1.1em;
    }
}

@media (min-width: 768px) {
    .b-sash-medium-md {
        font-size: 1em;
    }

    .b-sash-large-md {
        font-size: 1.1em;
    }
}

@media (min-width: 992px) {
    .b-sash-medium-lg {
        font-size: 1em;
    }

    .b-sash-large-lg {
        font-size: 1.1em;
    }
}

@media (min-width: 1200px) {
    .b-sash-medium-xl {
        font-size: 1em;
    }

    .b-sash-large-xl {
        font-size: 1.1em;
    }
}

@media (min-width: 1400px) {
    .b-sash-medium-xxl {
        font-size: 1em;
    }

    .b-sash-large-xxl {
        font-size: 1.1em;
    }
}
    /** TABLES **/
    .table {
        width: 100%;
        margin-bottom: 1rem;
        box-sizing: border-box;
        border-collapse: collapse;
    }

        .table th, .table td {
            padding: 0.75rem;
            vertical-align: top;
            border-top: 1px solid var(--neutral-200);
            box-sizing: border-box;
        }

        .table thead th {
            vertical-align: bottom;
            border-bottom: 2px solid var(--neutral-200);
        }

        .table tbody + tbody {
            bborder-top: 2px solid var(--neutral-200);
        }

    .table-sm th, .table-sm td {
        padding: 0.3rem;
    }

.table-bordered {
    border: 1px solid var(--neutral-200);
}

        .table-bordered th, .table-bordered td {
            border: 1px solid var(--neutral-200);
        }

        .table-bordered thead th, .table-bordered thead td {
            border-bottom-width: 2px;
        }

    .table-no-borders th, .table-no-borders td, .table-no-borders thead th, .table-no-borders tbody + tbody {
        border: 0;
    }

.table-striped tbody tr:nth-of-type(odd) {
    background-color: var(--neutral-50);
}

    @media (max-width: 575.98px) {
        .table-responsive-sm {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

            .table-responsive-sm > .table-bordered {
                border: 0;
            }
    }

    @media (max-width: 767.98px) {
        .table-responsive-md {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

            .table-responsive-md > .table-bordered {
                border: 0;
            }
    }

    @media (max-width: 991.98px) {
        .table-responsive-lg {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

            .table-responsive-lg > .table-bordered {
                border: 0;
            }
    }
@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

        .table-responsive-xl > .table-bordered {
            border: 0;
        }
}
@media (max-width: 1399.98px) {
    .table-responsive-xxl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

        .table-responsive-xxl > .table-bordered {
            border: 0;
        }
}

    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

        .table-responsive > .table-bordered {
            border: 0;
        }
    /** TABS **/
    .tabs-navigation-wrapper {
        margin-bottom: -1px !important;
        position: relative;
        overflow: hidden;
    }

    .tabs-navigation {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        box-sizing: border-box;
    }

        .tabs-navigation .tab-item {
            margin-bottom: -1px;
            border: 1px solid transparent;
            border-bottom-width: 4px;
            border-top-left-radius: 0.3rem;
            border-top-right-radius: 0.3rem;
            padding: 0.5rem 1.1rem;
            font-family: 'gibson_regular', sans-serif;
            font-weight: 400;
            box-sizing: border-box;
            white-space: nowrap;
            width: auto;
            font-size: 0.9rem;
            transition: all 0.5s;
        }

            .tabs-navigation .tab-item:hover, .tabs-navigation .tab-item:focus {
                cursor: pointer;
                color: var(--neutral-900);
            }

            .tabs-navigation .tab-item.disabled {
                color: var(--neutral-300);
                background-color: transparent;
                border-color: transparent;
            }

            .tabs-navigation .tab-item.active, .tabs-navigation .tab-item.show .nav-link {
                font-family: 'gibson_semibold';
            }




.tab-content-wrapper {
    border-radius: 0 0.3rem 0.3rem 0.3rem;
    border: 1px solid var(--neutral-200);
    box-sizing: border-box;
    background: var(--white);
}

    .tab-content {
        display: none;
        padding: 1.4rem;
        box-sizing: border-box;
    }

        .tab-content.active {
            display: inherit;
        }
    /** CARDS **/
    .card {
        padding: 1.2rem 0.8rem;
        position: relative;
        display: grid;
        display: -ms-grid;
        background-color: #ffffff;
        overflow: hidden;
        box-sizing: border-box;
    }

    .category-card {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto;
    }

    .category-card-image {
        min-height: 1%; /*Added for IE*/
    }

    .category-card-title, .category-card-buttons, .category-hub-title {
        text-align: center;
    }

    .category-hub {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .category-card-buttons .button {
        white-space: nowrap;
    }

    .card-product-tile {
        height: 100%;
    }

        .card-product-tile div.FPpercent {
            padding-top: 0;
        }

    .multiple-buttons .category-card-buttons .button, .category-hub-content .button {
        min-width: 110px !important;
        box-sizing: border-box;
        font-family: gibson_regular !important;
        font-weight: 400;
    }

    a:hover .card * {
        text-decoration: none !important;
    }

    .cover-link::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: auto;
        content: "";
        background-color: rgba(0,0,0,0);
    }
    /** FANCYBOX **/
    .fancybox-bg {
        background: #000613 !important;
    }

    .fancybox-container--ready .fancybox-bg {
        opacity: 0.8 !important;
    }
    /** SLIDE-IN PANEL **/
    .slide-in-panel {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        visibility: hidden;
        -webkit-transition: visibility 0.6s 0s;
        transition: visibility 0.6s 0s;
        z-index: 99999 !important;
        box-sizing: border-box;
    }

        .slide-in-panel::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            cursor: pointer;
            -webkit-transition: background 0.6s 0.4s;
            transition: background 0.6s 0.4s;
        }

        .slide-in-panel.slide-in-panel--is-visible {
            visibility: visible;
            -webkit-transition: visibility 0.6s 0s;
            transition: visibility 0.6s 0s;
        }

    .slide-in-close-icon {
        position: relative;
        transition: 0s ease all;
    }

        .slide-in-close-icon:hover {
            opacity: 0.9;
        }

        .slide-in-close-icon i {
            position: absolute;
            top: 1rem;
            cursor: pointer;
            z-index: 999999999;
            font-size: 1.4rem;
            color: #fff;
            box-sizing: border-box;
        }
.slide-in-panel--from-left .slide-in-panel__container .slide-in-close-icon i {
    right:-2.2rem;
}
.slide-in-panel--from-right .slide-in-panel__container .slide-in-close-icon i {
    left: -2.2rem;
}

.slide-in-panel.slide-in-panel--is-visible::after {
    background: rgba(0, 0, 0, 0.65);
    -webkit-transition: background 0.6s 0s;
    transition: background 0.6s 0s;
}

    .slide-in-panel__container {
        position: fixed;
        width: 90%;
        height: 100%;
        top: 0;
        background: #ffffff;
        z-index: 9999999;
        -webkit-transition: -webkit-transform 0.6s 0s;
        transition: -webkit-transform 0.8s 0s;
        transition: transform 0.6s 0s;
        transition: transform 0.6s 0.8s, -webkit-transform 0.6s 0s;
    }

    .slide-in-panel--from-right .slide-in-panel__container {
        right: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }

    .slide-in-panel--from-left .slide-in-panel__container {
        left: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    .slide-in-panel--is-visible .slide-in-panel__container {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
    }

    @media only screen and (min-width: 768px) {
        .slide-in-panel__container {
            width: 70%;
        }
    }

    @media only screen and (min-width: 1170px) {
        .slide-in-panel__container {
            width: 42%;
        }
    }

    @media only screen and (min-width: 2540px) {
        .slide-in-panel__container {
            width: 30%;
        }
    }

    .slide-in-panel-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x:hidden;
        -webkit-overflow-scrolling: touch;
        box-sizing: border-box;
    }
    .slide-in-panel-trigger:hover {
        cursor:pointer!important;
    }
    /* SWIPER */
    .bidSwiper, .bidSwiperMulti {
        overflow: hidden;
    }
    /* JQUERY MOBILE OVER RIDES */
    .ui-btn, .ui-btn-icon-right {
        /*padding: 0;*/
        border: none;
    }

    .form-select-wrapper .ui-btn-icon-left:after, .form-select-wrapper .ui-btn-icon-right:after, .form-select-wrapper .ui-btn-icon-top:after, .form-select-wrapper .ui-btn-icon-bottom:after, .form-select-wrapper .ui-btn-icon-notext:after {
        display: none !important;
    }
    /* TOOLTIPS */
.b-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    line-break: auto;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    font-size: 12px;
    filter: alpha(opacity=0);
    opacity: 0;
}

    .b-tooltip.in {
        filter: alpha(opacity=90);
        opacity: 0.9;
    }

    .b-tooltip.top {
        padding: 5px 0;
        margin-top: -3px;
    }

    .b-tooltip.right {
        padding: 0 5px;
        margin-left: 3px;
    }

    .b-tooltip.bottom {
        padding: 5px 0;
        margin-top: 3px;
    }

    .b-tooltip.left {
        padding: 0 5px;
        margin-left: -3px;
    }

    .b-tooltip.top .b-tooltip-arrow {
        bottom: 0;
        left: 50%;
        margin-left: -5px;
        border-width: 5px 5px 0;
        border-top-color: #000000;
    }

    .b-tooltip.top-left .b-tooltip-arrow {
        right: 5px;
        bottom: 0;
        margin-bottom: -5px;
        border-width: 5px 5px 0;
        border-top-color: #000000;
    }

    .b-tooltip.top-right .b-tooltip-arrow {
        bottom: 0;
        left: 5px;
        margin-bottom: -5px;
        border-width: 5px 5px 0;
        border-top-color: #000000;
    }

    .b-tooltip.right .b-tooltip-arrow {
        top: 50%;
        left: 0;
        margin-top: -5px;
        border-width: 5px 5px 5px 0;
        border-right-color: #000000;
    }

    .b-tooltip.left .b-tooltip-arrow {
        top: 50%;
        right: 0;
        margin-top: -5px;
        border-width: 5px 0 5px 5px;
        border-left-color: #000000;
    }

    .b-tooltip.bottom .b-tooltip-arrow {
        top: 0;
        left: 50%;
        margin-left: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: #000000;
    }

    .b-tooltip.bottom-left .b-tooltip-arrow {
        top: 0;
        right: 5px;
        margin-top: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: #000000;
    }

    .b-tooltip.bottom-right .b-tooltip-arrow {
        top: 0;
        left: 5px;
        margin-top: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: #000000;
    }

    .b-tooltip-inner {
        max-width: 200px;
        padding: 3px 8px;
        color: #ffffff;
        text-align: center;
        background-color: #000000;
        border-radius: 4px;
    }

    .b-tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }


    .cursor-pointer {
        cursor: pointer;
    }

    .pointer-events-none {
        pointer-events: none;
    }

    .greyout {
        position: relative;
    }

    .greyout::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(255 255 255 / 80%);
        z-index: 1;
    }

.b-custom-control {
    --brand-color: #081A44;
    
    position: relative;
    z-index: 1;
    display: block;
    min-height: 24px;
    padding-left: 24px;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
}

.b-custom-control-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
}

.b-custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.b-custom-control-input:checked~.b-custom-control-label::before {
    color: #fff;
    border-color: var(--brand-color);
    background-color: var(--brand-color);
}

.b-custom-control-input:focus~.b-custom-control-label::before {
    /*box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);*/
    box-shadow: none;
}

.b-custom-control-input:focus:not(:checked)~.b-custom-control-label::before {
    border-color: #adb5bd;
}

.b-custom-control-input:not(:disabled):active~.b-custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
}

.b-custom-control-input[disabled]~.b-custom-control-label,
.b-custom-control-input:disabled~.b-custom-control-label {
    color: #6c757d;
    pointer-events: none;
}

.b-custom-control-input[disabled]~.b-custom-control-label::before,
.b-custom-control-input:disabled~.b-custom-control-label::before {
    background-color: #e9ecef;
    pointer-events: none;
}

.b-custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    cursor: pointer;
}

.b-custom-control-label::before {
    position: absolute;
    top: 0.1rem;
    left: -24px;
    display: block;
    width: 16px;
    height: 16px;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd;
}

.b-custom-control-label::after {
    position: absolute;
    top: 0.1rem;
    left: -24px;
    display: block;
    width: 16px;
    height: 16px;
    content: "";
    background: 50% / 50% 50% no-repeat;
}

.b-custom-checkbox .b-custom-control-label::before {
    border-radius: 4px;
}

.b-custom-checkbox .b-custom-control-input:checked~.b-custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.b-custom-checkbox .b-custom-control-input:indeterminate~.b-custom-control-label::before {
    border-color: var(--brand-color);
    background-color: var(--brand-color);
}

.b-custom-checkbox .b-custom-control-input:indeterminate~.b-custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.b-custom-checkbox .b-custom-control-input:disabled:checked~.b-custom-control-label::before {
    background-color: #e9ecef;
    border-color: #adb5bd;
}

.b-custom-checkbox .b-custom-control-input:disabled:indeterminate~.b-custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.b-custom-radio .b-custom-control-label::before {
    border-radius: 50%;
}

.b-custom-radio .b-custom-control-input:checked~.b-custom-control-label::before {
    background-color: #fff;
}

.b-custom-radio .b-custom-control-input:checked~.b-custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23081a44'/%3e%3c/svg%3e");
}

.b-custom-radio .b-custom-control-input:disabled:checked~.b-custom-control-label::after {    
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.b-custom-radio .b-custom-control-input:disabled:checked~.b-custom-control-label::before {    
    background-color: #e9ecef;
    border-color: #adb5bd;
}

.b-custom-switch {
    padding-left: 34px;
}

.b-custom-switch .b-custom-control-label::before {
    top: 0;
    left: -34px;
    width: 26px;
    pointer-events: all;
    border-radius: 50px;
}

.b-custom-switch .b-custom-control-label::after {
    top: 2px;
    left: calc(-34px + 2px);
    width: calc(16px - 4px);
    height: calc(16px - 4px);
    background-color: #adb5bd;
    border-radius: 50%;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .b-custom-switch .b-custom-control-label::after {
        transition: none;
    }
}

.b-custom-switch .b-custom-control-input:checked~.b-custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
}

.b-custom-switch .b-custom-control-input:disabled:checked~.b-custom-control-label::after {
    background-color: #adb5bd;
}

.b-custom-switch .b-custom-control-input:disabled:checked~.b-custom-control-label::before {
    background-color: #e9ecef;
    border-color: #adb5bd;
}

.b-custom-control-lg {
    min-height: 36px;
    padding-left: 32px;
}

.b-custom-control-lg .b-custom-control-label::before {
    top: -4px;
    left: -32px;
    width: 24px;
    height: 24px;
}

.b-custom-control-lg .b-custom-control-label::after {
    top: -4px;
    left: -32px;
    width: 24px;
    height: 24px;
}

.b-custom-control-lg .b-custom-control-label span {
    font-size: 18px;
}

.b-custom-control-lg.b-custom-switch {
    padding-left: 47px;
}

.b-custom-control-lg.b-custom-switch .b-custom-control-label::before {
    left: -47px;
    width: 39px;
    border-radius: 50px;
}

.b-custom-control-lg.b-custom-switch .b-custom-control-label::after {
    top: -2px;
    left: calc(-47px + 2px);
    width: 20px;
    height: 20px;
}

.b-custom-control-lg.b-custom-switch .b-custom-control-input:checked~.b-custom-control-label::after {
    -webkit-transform: translateX(16px);
    transform: translateX(16px);
}

.button-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.button-check + .button {
    cursor: pointer;
}

.button-check:active+.button, .button-check:checked+.button, .button.active, .button:active, .show>.button.dropdown-toggle {
    opacity: 0.85;
}

.button-check[disabled] + .button, .button-check:disabled + .button {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.button-check:active+.button-dark-blue-outline,
.button-check:checked+.button-dark-blue-outline,
.button-dark-blue-outline.active,
.button-dark-blue-outline.dropdown-toggle.show,
.button-dark-blue-outline:active {
    background-color: var(--dark-blue-600);
    color: var(--white)!important;
    opacity: 0.85;
}

.button-check:active+.button-aqua,
.button-check:checked+.button-aqua,
.button-check:active+.button-aqua-outline,
.button-check:checked+.button-aqua-outline,
.button-aqua-outline.active,
.button-aqua-outline.dropdown-toggle.show,
.button-aqua-outline:active {
    border-color: #50CEAF;
    background-color: #50CEAF;
    color: var(--white)!important;
    opacity: 0.85;
}

.button-check:active+.button-dark-teal,
.button-check:checked+.button-dark-teal,
.button-check:active+.button-dark-teal-outline,
.button-check:checked+.button-dark-teal-outline,
.button-dark-teal-outline.active,
.button-dark-teal-outline.dropdown-toggle.show,
.button-dark-teal-outline:active {
    border-color: #4d9b94;
    background-color: #4d9b94;
    color: var(--white)!important;
    opacity: 0.85;
}

.button-toggle-icon .icon-2 {
    display: none;
}

.button-check:active+.button-toggle-icon .icon-1,
.button-check:checked+.button-toggle-icon .icon-1,
.button-toggle-icon.active .icon-1,
.button-toggle-icon.dropdown-toggle.show .icon-1,
.button-toggle-icon:active .icon-1 {
    display: none;
}

.button-check:active+.button-toggle-icon .icon-2,
.button-check:checked+.button-toggle-icon .icon-2,
.button-toggle-icon.active .icon-2,
.button-toggle-icon.dropdown-toggle.show .icon-2,
.button-toggle-icon:active .icon-2 {
    display: inline-block;
}

.button-compare {
    border-color: var(--dark-blue-600);
    background-color: transparent;
    color: var(--dark-blue-600);
}

.button-check:active+.button-compare,
.button-check:checked+.button-compare,
.button-check:active+.button-compare-outline,
.button-check:checked+.button-compare-outline,
.button-compare-outline.active,
.button-compare-outline.dropdown-toggle.show,
.button-compare-outline:active {
    border-color: var(--green-500);
    background-color: transparent;
    color: var(--green-500);
}