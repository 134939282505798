/* You can add global styles to this file, and also import other style files */

html {
    font-family: Arial;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
}

body {
    background-color: #242424;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

label, input {
    display: block;
}

img {
    max-height: 300px;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}


.eventdescription {
    text-align: left;
    padding-left: 20px;
}

    .button:hover {
        opacity: 0.8;
    }

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.maincontainer {
    max-width: 1340px !important;
}

.table tbody td {
    font-weight: 100;
}
.table thead th {
    vertical-align: middle !important;
}

@media only screen and (max-width: 700px) {
    .maincontainer {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    .header-logo {
        width: 130px;
    }

    .headerbox {
        background-color: white;
        text-align: center;
        border-bottom: 5px solid darkred;
        padding-top: 1px;
        padding-bottom: 1px;
    }

        .headerbox p {
            font-size: 18pt;
            font-weight: bolder;
        }

    .inputdiv {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4%;
        margin-bottom: 4%;
        padding: 1%;
    }

    .bigtextbox {
        height: 20px;
        font-size: 10pt;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1%;
        margin-bottom: 1%;
    }

    .heading {
        font-size: 12pt;
    }

    .centrebox {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 25%;
        padding-left: 2%;
        padding-right: 2%;
    }

    .box {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        margin: 30px 0px 30px 0px;
    }

    
}

@media only screen and (min-width: 700px) and (max-width: 1500px) {
    .maincontainer {
        -ms-flex: 0 0 90%;
        flex: 0 0 90%;
        max-width: 90%;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    .headerbox {
        background-color: white;
        text-align: center;
        border-bottom: 5px solid darkred;
        margin-left: 20%;
        margin-right: 20%;
        padding-top: 1px;
        padding-bottom: 1px;
    }

        .headerbox p {
            font-size: 22pt;
            font-weight: bolder;
        }

    .inputdiv {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4%;
        margin-bottom: 4%;
    }

    .bigtextbox {
        height: 20px;
        font-size: 14pt;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1%;
        margin-bottom: 1%;
    }

    .heading {
        font-size: 14pt;
    }

    .centrebox {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        margin: 10% 10% 15% 10%;
    }

    .box {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        margin: 50px;
    }

 
    .header-logo {
        width: 180px;
    }
}

@media only screen and (min-width: 1500px) {
    .maincontainer {
        -ms-flex: 0 0 70%;
        flex: 0 0 70%;
        max-width: 70%;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .headerbox {
        background-color: white;
        text-align: center;
        border-bottom: 5px solid darkred;
        margin-left: 20%;
        margin-right: 20%;
        padding-top: 1px;
        padding-bottom: 1px;
    }

        .headerbox p {
            font-size: 22pt;
            font-weight: bolder;
        }

    .inputdiv {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4%;
        margin-bottom: 4%;
    }

    .bigtextbox {
        height: 20px;
        font-size: 14pt;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1%;
        margin-bottom: 1%;
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
    }

    .heading {
        font-size: 16pt;
    }

    .centrebox {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        margin: 20% 20% 25% 20%;
    }

    .box {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        margin: 50px;
    }

    
    .header-logo {
        width: 166px;
    }
}


/* loading spinner */
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }

        .lds-ring div:nth-child(1) {
            animation-delay: -0.45s;
        }

        .lds-ring div:nth-child(2) {
            animation-delay: -0.3s;
        }

        .lds-ring div:nth-child(3) {
            animation-delay: -0.15s;
        }

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
