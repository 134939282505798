:root {
    /*
  ----------
    Colours
  ----------  
  */
    /* Neutral Colours */
    --neutral-50: #fafafa;
    --neutral-100: #f4f4f5;
    --neutral-200: #e4e4e7;
    --neutral-300: #d4d4d8;
    --neutral-400: #a1a1aa;
    --neutral-500: #71717a;
    --neutral-600: #52525b;
    --neutral-700: #3f3f46;
    --neutral-800: #27272a;
    --neutral-900: #18181b;
    /* Dark Blue */
    --dark-blue-50: #e6ecfe;
    --dark-blue-100: #c9d6fd;
    --dark-blue-200: #98b5fb;
    --dark-blue-300: #7298ee;
    --dark-blue-400: #113991;
    --dark-blue-500: #081a49;
    --dark-blue-600: #061637;
    --dark-blue-700: #051029;
    --dark-blue-800: #030a1b;
    --dark-blue-900: #02050e;
    /* Light Blue */
    --light-blue-50: #e5f8ff;
    --light-blue-100: #c6efff;
    --light-blue-200: #8eddff;
    --light-blue-300: #55ccff;
    --light-blue-400: #1dbbff;
    --light-blue-500: #009fe3;
    --light-blue-600: #007fb6;
    --light-blue-700: #005f88;
    --light-blue-800: #00405b;
    --light-blue-900: #00202d;
    /* Turquoise */
    --turquoise-50: #ecfefc;
    --turquoise-100: #ccfef9;
    --turquoise-200: #a1f5ed;
    --turquoise-300: #73efe3;
    --turquoise-400: #44eadb;
    --turquoise-500: #1edcca;
    --turquoise-600: #0ebaaa;
    --turquoise-700: #098d80;
    --turquoise-800: #065e56;
    --turquoise-900: #032f2a;
    /* Teal */
    --teal-50: #ecfef9;
    --teal-100: #cefbef;
    --teal-200: #a9eadb;
    --teal-300: #7fdfc8;
    --teal-400: #58d0b4;
    --teal-500: #3fb498;
    --teal-600: #2b977e;
    --teal-700: #1a7861;
    --teal-800: #0a5745;
    --teal-900: #022f23;
    /* Green */
    --green-50: #e0fceb;
    --green-100: #ace7c6;
    --green-200: #7de3a8;
    --green-300: #4dcb83;
    --green-400: #3caf6e;
    --green-500: #287e4d;
    --green-600: #1b7c45;
    --green-700: #0c6935;
    --green-800: #045226;
    --green-900: #033016;
    /* Red */
    --red-50: #feeced;
    --red-100: #fbc4c7;
    --red-200: #f28e95;
    --red-300: #e55d65;
    --red-400: #d2323c;
    --red-500: #c5000c;
    --red-600: #9e000a;
    --red-700: #760007;
    --red-800: #4f0005;
    --red-900: #270002;
    /* Magenta */
    --magenta-50: #feecf4;
    --magenta-100: #fccae0;
    --magenta-200: #f49ac1;
    --magenta-300: #ee69a3;
    --magenta-400: #e93684;
    --magenta-500: #e20565;
    --magenta-600: #b50451;
    --magenta-700: #88033d;
    --magenta-800: #5a0228;
    --magenta-900: #2d0114;
    /* Orange */
    --orange-50: #fffbeb;
    --orange-100: #fef3c7;
    --orange-200: #fde68a;
    --orange-300: #fcd34d;
    --orange-400: #fbbf24;
    --orange-500: #f59e0b;
    --orange-600: #d97706;
    --orange-700: #b45309;
    --orange-800: #92400e;
    --orange-900: #78350f;
    /* Yellow */
    --yellow-50: #fffce6;
    --yellow-100: #fff5b0;
    --yellow-200: #fff08a;
    --yellow-300: #ffe954;
    --yellow-400: #ffe533;
    --yellow-500: #ffde00;
    --yellow-600: #e8ca00;
    --yellow-700: #b59e00;
    --yellow-800: #665900;
    --yellow-900: #332c00;
    /* Shades */
    --black: #000000;
    --white: #ffffff;
}

/** BACKGROUND COLOURS **/
/*
--------------------
  Background colours
--------------------  
*/

/* Background Neutral */
.background-off-white,
.background-neutral-50,
.alert-neutral {
    background-color: var(--neutral-50) !important;
}

.background-neutral-100 {
    background-color: var(--neutral-100) !important;
}

.background-light-grey,
.background-neutral-200 {
    background-color: var(--neutral-200) !important;
}

.background-neutral-300 {
    background-color: var(--neutral-300) !important;
}

.background-neutral-400 {
    background-color: var(--neutral-400) !important;
}

.background-mid-grey,
.background-neutral-500 {
    background-color: var(--neutral-500) !important;
}

.background-neutral-600 {
    background-color: var(--neutral-600) !important;
}

.background-grey,
.background-neutral-700 {
    background-color: var(--neutral-700) !important;
}

.background-neutral-800 {
    background-color: var(--neutral-800) !important;
}

.background-charcoal,
.background-neutral-900 {
    background-color: var(--neutral-900) !important;
}

/* Background Dark Blue */
.background-dark-blue-50,
.alert-info {
    background-color: var(--dark-blue-50) !important;
}

.background-dark-blue-100, .b-sash-solid-blue {
    background-color: var(--dark-blue-100) !important;
}

.background-dark-blue-200 {
    background-color: var(--dark-blue-200) !important;
}

.background-dark-blue-300 {
    background-color: var(--dark-blue-300) !important;
}

.background-dark-blue-400 {
    background-color: var(--dark-blue-400) !important;
}

.background-dark-blue,
.background-dark-blue-500,
.background-primary {
    background-color: var(--dark-blue-500) !important;
}

.background-dark-blue-600 {
    background-color: var(--dark-blue-600) !important;
}

.background-dark-blue-700 {
    background-color: var(--dark-blue-700) !important;
}

.background-dark-blue-800 {
    background-color: var(--dark-blue-800) !important;
}

.background-dark-blue-900 {
    background-color: var(--dark-blue-900) !important;
}

/* Background Light Blue */
.background-light-blue-50 {
    background-color: var(--light-blue-50) !important;
}

.background-light-blue-100 {
    background-color: var(--light-blue-100) !important;
}

.background-light-blue-200 {
    background-color: var(--light-blue-200) !important;
}

.background-light-blue-300 {
    background-color: var(--light-blue-300) !important;
}

.background-light-blue-400 {
    background-color: var(--light-blue-400) !important;
}

.background-light-blue,
.background-light-blue-500 {
    background-color: var(--light-blue-500) !important;
}

.background-light-blue-600 {
    background-color: var(--light-blue-600) !important;
}

.background-light-blue-700 {
    background-color: var(--light-blue-700) !important;
}

.background-light-blue-800 {
    background-color: var(--light-blue-800) !important;
}

.background-light-blue-900 {
    background-color: var(--light-blue-900) !important;
}

/* Background Turquoise */
.background-turquoise-50 {
    background-color: var(--turquoise-50) !important;
}

.background-turquoise-100, .b-sash-solid-turquoise {
    background-color: var(--turquoise-100) !important;
}

.background-turquoise-200 {
    background-color: var(--turquoise-200) !important;
}

.background-turquoise-300 {
    background-color: var(--turquoise-300) !important;
}

.background-turquoise-400 {
    background-color: var(--turquoise-400) !important;
}

.background-turquoise,
.background-turquoise-500 {
    background-color: var(--turquoise-500) !important;
}

.background-turquoise-600 {
    background-color: var(--turquoise-600) !important;
}

.background-turquoise-700 {
    background-color: var(--turquoise-700) !important;
}

.background-turquoise-800 {
    background-color: var(--turquoise-800) !important;
}

.background-turquoise-900 {
    background-color: var(--turquoise-900) !important;
}

/* Background Teal */
.background-teal-50 {
    background-color: var(--teal-50) !important;
}

.background-teal-100 {
    background-color: var(--teal-100) !important;
}

.background-teal-200 {
    background-color: var(--teal-200) !important;
}

.background-teal-300 {
    background-color: var(--teal-300) !important;
}

.background-teal-400 {
    background-color: var(--teal-400) !important;
}

.background-teal,
.background-refurb,
.background-teal-500 {
    background-color: var(--teal-500) !important;
}

.background-teal-600 {
    background-color: var(--teal-600) !important;
}

.background-teal-700 {
    background-color: var(--teal-700) !important;
}

.background-teal-800 {
    background-color: var(--teal-800) !important;
}

.background-teal-900 {
    background-color: var(--teal-900) !important;
}

/* Background Green */
.background-green-50,
.alert-success {
    background-color: var(--green-50) !important;
}

.background-green-100, .b-sash-solid-green {
    background-color: var(--green-100) !important;
}

.background-green-200 {
    background-color: var(--green-200) !important;
}

.background-green-300 {
    background-color: var(--green-300) !important;
}

.background-green-400 {
    background-color: var(--green-400) !important;
}

.background-green,
.background-green-500 {
    background-color: var(--green-500) !important;
}

.background-green-600 {
    background-color: var(--green-600) !important;
}

.background-green-700 {
    background-color: var(--green-700) !important;
}

.background-green-800 {
    background-color: var(--green-800) !important;
}

.background-green-900 {
    background-color: var(--green-900) !important;
}

/* Background Red */
.background-red-50,
.alert-error {
    background-color: var(--red-50) !important;
}

.background-red-100 {
    background-color: var(--red-100) !important;
}

.background-red-200 {
    background-color: var(--red-200) !important;
}

.background-red-300 {
    background-color: var(--red-300) !important;
}

.background-red-400, .b-sash-solid-red {
    background-color: var(--red-400) !important;
}

.background-red,
.background-red-500 {
    background-color: var(--red-500) !important;
}

.background-red-600 {
    background-color: var(--red-600) !important;
}

.background-red-700 {
    background-color: var(--red-700) !important;
}

.background-red-800 {
    background-color: var(--red-800) !important;
}

.background-red-900 {
    background-color: var(--red-900) !important;
}

/* Background Magenta */
.background-magenta-50 {
    background-color: var(--magenta-50) !important;
}

.background-magenta-100 {
    background-color: var(--magenta-100) !important;
}

.background-magenta-200 {
    background-color: var(--magenta-200) !important;
}

.background-magenta-300 {
    background-color: var(--magenta-300) !important;
}

.background-magenta-400 {
    background-color: var(--magenta-400) !important;
}

.background-magenta,
.background-magenta-500 {
    background-color: var(--magenta-500) !important;
}

.background-magenta-600 {
    background-color: var(--magenta-600) !important;
}

.background-magenta-700 {
    background-color: var(--magenta-700) !important;
}

.background-magenta-800 {
    background-color: var(--magenta-800) !important;
}

.background-magenta-900 {
    background-color: var(--magenta-900) !important;
}

/* Background Orange */
.background-orange-50,
.alert-warning {
    background-color: var(--orange-50) !important;
}

.background-orange-100 , .b-sash-solid-orange {
    background-color: var(--orange-100) !important;
}

.background-orange-200 {
    background-color: var(--orange-200) !important;
}

.background-orange-300 {
    background-color: var(--orange-300) !important;
}

.background-orange-400 {
    background-color: var(--orange-400) !important;
}

.background-orange,
.background-orange-500 {
    background-color: var(--orange-500) !important;
}

.background-orange-600 {
    background-color: var(--orange-600) !important;
}

.background-orange-700 {
    background-color: var(--orange-700) !important;
}

.background-orange-800 {
    background-color: var(--orange-800) !important;
}

.background-orange-900 {
    background-color: var(--orange-900) !important;
}

/* Background Yellow */
.background-yellow-50 {
    background-color: var(--yellow-50) !important;
}

.background-yellow-100 {
    background-color: var(--yellow-100) !important;
}

.background-yellow-200 {
    background-color: var(--yellow-200) !important;
}

.background-yellow-300 {
    background-color: var(--yellow-300) !important;
}

.background-yellow-400 {
    background-color: var(--yellow-400) !important;
}

.background-yellow,
.background-yellow-500 {
    background-color: var(--yellow-500) !important;
}

.background-yellow-600 {
    background-color: var(--yellow-600) !important;
}

.background-yellow-700 {
    background-color: var(--yellow-700) !important;
}

.background-yellow-800 {
    background-color: var(--yellow-800) !important;
}

.background-yellow-900 {
    background-color: var(--yellow-900) !important;
}

.background-white {
    background-color: var(--white) !important;
}

.background-black {
    background-color: var(--black) !important;
}

.background-transparent {
    background: transparent !important;
}

/*
--------------------
  Gradients
--------------------  
*/

.background-gradient-dark-light-blue-x,
.background-gradient-dark-light-blue-y,
.background-gradient-dark-light-blue {
    background: linear-gradient(120deg, #081a44 0%, #009fe3 100%) !important;
    background: -webkit-gradient(120deg, #081a44 0%, #009fe3 100%) !important;
}

.background-gradient-light-dark-blue-x,
.background-gradient-light-dark-blue-y,
.background-gradient-light-dark-blue {
    background: linear-gradient(120deg, #009fe3 100%, #081a44 0%) !important;
    background: -webkit-gradient(120deg,#009fe3 100%, #081a44 0%) !important;
}

.background-gradient-turquoise-light-blue-x,
.background-gradient-turquoise-light-blue-y,
.background-gradient-turquoise-light-blue {
    background: linear-gradient(120deg, #1edcca 0%, #009fe3 100%) !important;
    background: -webkit-gradient(120deg, #1edcca 0%, #009fe3 100%) !important;
}

.background-gradient-light-blue-turquoise-x,
.background-gradient-light-blue-turquoise-y,
.background-gradient-light-blue-turquoise {
    background: linear-gradient(120deg, #009fe3 0%, #1edcca 100%) !important;
    background: -webkit-gradient(120deg, #009fe3 0%, #1edcca 100%) !important;
}

.background-gradient-teal-turquoise-x,
.background-gradient-teal-turquoise-y,
.background-gradient-teal-turquoise {
    background: linear-gradient(120deg, #1edcca 0%, #3fb498 100%) !important;
    background: -webkit-gradient(120deg, #1edcca 0%, #3fb498 100%) !important;
}

.background-gradient-turquoise-teal-x,
.background-gradient-turquoise-teal-y,
.background-gradient-turquoise-teal {
    background: linear-gradient(120deg, #3fb498 0%, #1edcca 100%) !important;
    background: -webkit-gradient(120deg, #3fb498 0%, #1edcca 100%) !important;
}

.background-gradient-orange-yellow-x,
.background-gradient-orange-yellow-y,
.background-gradient-orange-yellow {
    background: linear-gradient(120deg, #f2711c 0%, #fbeb08 100%) !important;
    background: -webkit-gradient(120deg, #f2711c 0%, #fbeb08 100%) !important;
}

.background-gradient-yellow-orange-x,
.background-gradient-yellow-orange-y,
.background-gradient-yellow-orange {
    background: linear-gradient(120deg, #fbeb08 0%, #f2711c 100%) !important;
    background: -webkit-gradient(120deg, #fbeb08 0%, #f2711c 100%) !important;
}

.background-gradient-dark-red-red-x,
.background-gradient-dark-red-red-y,
.background-gradient-dark-red-red {
    background: linear-gradient(120deg, #bb1017 0%, #ed1c24 100%) !important;
    background: -webkit-gradient(120deg, #bb1017 0%, #ed1c24 100%) !important;
}

.background-gradient-red-dark-red-x,
.background-gradient-red-dark-red-y,
.background-gradient-red-dark-red {
    background: linear-gradient(120deg, #ed1c24 0%, #bb1017 100%) !important;
    background: -webkit-gradient(120deg, #ed1c24 0%, #bb1017 100%) !important;
}

.background-gradient-red-orange-x,
.background-gradient-red-orange-y,
.background-gradient-red-orange {
    background: linear-gradient(120deg, #d9000d 0%, #f2711c 100%) !important;
    background: -webkit-gradient(120deg, #d9000d 0%, #f2711c 100%) !important;
}

.background-gradient-orange-red-x,
.background-gradient-orange-red-y,
.background-gradient-orange-red {
    background: linear-gradient(120deg, #f2711c 0%, #d9000d 100%) !important;
    background: -webkit-gradient(120deg, #f2711c 0%, #d9000d 100%) !important;
}
/*
---------------------------------
  Text, icons & text link colours
---------------------------------
*/

/* Text colour Neutral */
.text-black,
.icon-black,
.text-link-black,
.text-black,
.icon-black,
.text-link-black {
    color: var(--black) !important;
}

.text-white,
.icon-white,
.text-link-white,
.background-refurb .text-refurb,
.text-white,
.icon-white,
.text-link-white, .b-sash-solid-red {
    color: var(--white) !important;
}

.text-off-white,
.icon-off-white,
.text-link-off-white,
.text-neutral-50,
.icon-neutral-50,
.text-link-neutral-50 {
    color: var(--neutral-50) !important;
}

.text-neutral-100,
.icon-neutral-100,
.text-link-neutral-100 {
    color: var(--neutral-100) !important;
}

.text-light-grey,
.icon-light-grey,
.text-link-light-grey,
.text-neutral-200,
.icon-neutral-200,
.text-link-neutral-200 {
    color: var(--neutral-200) !important;
}

.text-neutral-300,
.icon-neutral-300,
.text-link-neutral-300 {
    color: var(--neutral-300) !important;
}

.text-neutral-400,
.icon-neutral-400,
.text-link-neutral-400 {
    color: var(--neutral-400) !important;
}

.text-mid-grey,
.icon-mid-grey,
.text-link-mid-grey,
.text-neutral-500,
.icon-neutral-500,
.text-link-neutral-500 {
    color: var(--neutral-500) !important;
}

.text-neutral-600,
.icon-neutral-600,
.text-link-neutral-600 {
    color: var(--neutral-600) !important;
}

.text-grey,
.icon-grey,
.text-link-grey,
.text-neutral-700,
.icon-neutral-700,
.text-link-neutral-700 {
    color: var(--neutral-700) !important;
}

.text-neutral-800,
.icon-neutral-800,
.text-link-neutral-800,
.alert-neutral {
    color: var(--neutral-800) !important;
}

.text-charcoal,
.icon-charcoal,
.text-link-charcoal,
.text-neutral-900,
.icon-neutral-900,
.text-link-neutral-900 {
    color: var(--neutral-900) !important;
}

/* Text colour Dark Blue */
.text-dark-blue-50 .icon-dark-blue-50,
.text-link-dark-blue-50 {
    color: var(--dark-blue-50) !important;
}

.text-dark-blue-100,
.icon-dark-blue-100,
.text-link-dark-blue-100 {
    color: var(--dark-blue-100) !important;
}

.text-dark-blue-200,
.icon-dark-blue-200,
.text-link-dark-blue-200 {
    color: var(--dark-blue-200) !important;
}

.text-dark-blue-300,
.icon-dark-blue-300,
.text-link-dark-blue-300 {
    color: var(--dark-blue-300) !important;
}

.text-dark-blue-400,
.icon-dark-blue-400,
.text-link-dark-blue-400 {
    color: var(--dark-blue-400) !important;
}

.text-dark-blue,
.icon-dark-blue,
.text-link-dark-blue,
.text-dark-blue-500,
.icon-dark-blue-500,
.text-link-dark-blue-500,
.text-primary {
    color: var(--dark-blue-500) !important;
}

.text-dark-blue-600,
.icon-dark-blue-600,
.text-link-dark-blue-600 {
    color: var(--dark-blue-600) !important;
}

.text-dark-blue-700,
.icon-dark-blue-700,
.text-link-dark-blue-700 {
    color: var(--dark-blue-700) !important;
}

.text-dark-blue-800,
.icon-dark-blue-800,
.text-link-dark-blue-800,
.alert-info {
    color: var(--dark-blue-800) !important;
}

.text-dark-blue-900,
.icon-dark-blue-900,
.text-link-dark-blue-900 {
    color: var(--dark-blue-900) !important;
}

/* Light Blue */
.text-light-blue-50,
.icon-light-blue-50,
.text-link-light-blue-50 {
    color: var(--light-blue-50) !important;
}

.text-light-blue-100,
.icon-light-blue-100,
.text-link-light-blue-100 {
    color: var(--light-blue-100) !important;
}

.text-light-blue-200,
.icon-light-blue-200,
.text-link-light-blue-200 {
    color: var(--light-blue-200) !important;
}

.text-light-blue-300,
.icon-light-blue-300,
.text-link-light-blue-300 {
    color: var(--light-blue-300) !important;
}

.text-light-blue-400,
.icon-light-blue-400,
.text-link-light-blue-400 {
    color: var(--light-blue-400) !important;
}

.text-light-blue,
.icon-light-blue,
.text-link-light-blue,
.text-light-blue-500,
.icon-light-blue-500,
.text-link-light-blue-500 {
    color: var(--light-blue-500) !important;
}

.text-light-blue-600,
.icon-light-blue-600,
.text-link-light-blue-600, .b-sash-solid-blue, .b-sash-outline-blue {
    color: var(--light-blue-600) !important;
}

.text-light-blue-700,
.icon-light-blue-700,
.text-link-light-blue-700 {
    color: var(--light-blue-700) !important;
}

.text-light-blue-800,
.icon-light-blue-800,
.text-link-light-blue-800 {
    color: var(--light-blue-800) !important;
}

.text-light-blue-900,
.icon-light-blue-900,
.text-link-light-blue-900 {
    color: var(--light-blue-900) !important;
}

/* Turquoise */
.text-turquoise-50,
.icon-turquoise-50,
.text-link-turquoise-50 {
    color: var(--turquoise-50) !important;
}

.text-turquoise-100,
.icon-turquoise-100,
.text-link-turquoise-100 {
    color: var(--turquoise-100) !important;
}

.text-turquoise-200,
.icon-turquoise-200,
.text-link-turquoise-200 {
    color: var(--turquoise-200) !important;
}

.text-turquoise-300,
.icon-turquoise-300,
.text-link-turquoise-300 {
    color: var(--turquoise-300) !important;
}

.text-turquoise-400,
.icon-turquoise-400,
.text-link-turquoise-400 {
    color: var(--turquoise-400) !important;
}

.text-turquoise,
.icon-turquoise,
.text-link-turquoise,
.text-turquoise-500,
.icon-turquoise-500,
.text-link-turquoise-500 {
    color: var(--turquoise-500) !important;
}

.text-turquoise-600,
.icon-turquoise-600,
.text-link-turquoise-600 {
    color: var(--turquoise-600) !important;
}

.text-turquoise-700,
.icon-turquoise-700,
.text-link-turquoise-700, .b-sash-outline-turquoise, .b-sash-solid-turquoise {
    color: var(--turquoise-700) !important;
}

.text-turquoise-800,
.icon-turquoise-800,
.text-link-turquoise-800 {
    color: var(--turquoise-800) !important;
}

.text-turquoise-900,
.icon-turquoise-900,
.text-link-turquoise-900 {
    color: var(--turquoise-900) !important;
}

/* Teal */
.text-teal-50,
.icon-teal-50,
.text-link-teal-50 {
    color: var(--teal-50) !important;
}

.text-teal-100,
.icon-teal-100,
.text-link-teal-100 {
    color: var(--teal-100) !important;
}

.text-teal-200,
.icon-teal-200,
.text-link-teal-200 {
    color: var(--teal-200) !important;
}

.text-teal-300,
.icon-teal-300,
.text-link-teal-300 {
    color: var(--teal-300) !important;
}

.text-teal-400,
.icon-teal-400,
.text-link-teal-400 {
    color: var(--teal-400) !important;
}

.text-teal,
.icon-teal,
.text-link-teal,
.text-teal-500,
.icon-teal-500,
.text-link-teal-500 {
    color: var(--teal-500) !important;
}

.text-teal-600,
.icon-teal-600,
.text-link-teal-600 {
    color: var(--teal-600) !important;
}

.text-teal-700,
.icon-teal-700,
.text-link-teal-700 {
    color: var(--teal-700) !important;
}

.text-teal-800,
.icon-teal-800,
.text-link-teal-800 {
    color: var(--teal-800) !important;
}

.text-teal-900,
.icon-teal-900,
.text-link-teal-900 {
    color: var(--teal-900) !important;
}

/* Green */
.text-green-50,
.icon-green-50,
.text-link-green-50 {
    color: var(--green-50) !important;
}

.text-green-100,
.icon-green-100,
.text-link-green-100 {
    color: var(--green-100) !important;
}

.text-green-200,
.icon-green-200,
.text-link-green-200 {
    color: var(--green-200) !important;
}

.text-green-300,
.icon-green-300,
.text-link-green-300 {
    color: var(--green-300) !important;
}

.text-green-400,
.icon-green-400,
.text-link-green-400 {
    color: var(--green-400) !important;
}

.text-green,
.icon-green,
.text-link-green,
.text-green-500,
.icon-green-500,
.text-link-green-500 {
    color: var(--green-500) !important;
}

.text-green-600,
.icon-green-600,
.text-link-green-600 {
    color: var(--green-600) !important;
}

.text-green-700,
.icon-green-700,
.text-link-green-700, .b-sash-solid-green, .b-sash-outline-green {
    color: var(--green-700) !important;
}

.text-green-800,
.icon-green-800,
.text-link-green-800,
.alert-success {
    color: var(--green-800) !important;
}

.text-green-900,
.icon-green-900,
.text-link-green-900 {
    color: var(--green-900) !important;
}

/* Red */
.text-red-50,
.icon-red-50,
.text-link-red-50 {
    color: var(--red-50) !important;
}

.text-red-100,
.icon-red-100,
.text-link-red-100 {
    color: var(--red-100) !important;
}

.text-red-200,
.icon-red-200,
.text-link-red-200 {
    color: var(--red-200) !important;
}

.text-red-300,
.icon-red-300,
.text-link-red-300 {
    color: var(--red-300) !important;
}

.text-red-400,
.icon-red-400,
.text-link-red-400 {
    color: var(--red-400) !important;
}

.text-red,
.icon-red,
.text-link-red,
.text-red-500,
.icon-red-500,
.text-link-red-500, .b-sash-outline-red {
    color: var(--red-500) !important;
}

.text-red-600,
.icon-red-600,
.text-link-red-600 {
    color: var(--red-600) !important;
}

.text-red-700,
.icon-red-700,
.text-link-red-700 {
    color: var(--red-700) !important;
}

.text-red-800,
.icon-red-800,
.text-link-red-800,
.alert-error {
    color: var(--red-800) !important;
}

.text-red-900,
.icon-red-900,
.text-link-red-900 {
    color: var(--red-900) !important;
}

/* Magenta */
.text-magenta-50,
.icon-magenta-50,
.text-link-magenta-50 {
    color: var(--magenta-50) !important;
}

.text-magenta-100,
.icon-magenta-100,
.text-link-magenta-100 {
    color: var(--magenta-100) !important;
}

.text-magenta-200,
.icon-magenta-200,
.text-link-magenta-200 {
    color: var(--magenta-200) !important;
}

.text-magenta-300,
.icon-magenta-300,
.text-link-magenta-300 {
    color: var(--magenta-300) !important;
}

.text-magenta-400,
.icon-magenta-400,
.text-link-magenta-400 {
    color: var(--magenta-400) !important;
}

.text-magenta,
.icon-magenta,
.text-link-magenta,
.text-magenta-500,
.icon-magenta-500,
.text-link-magenta-500 {
    color: var(--magenta-500) !important;
}

.text-magenta-600,
.icon-magenta-600,
.text-link-magenta-600 {
    color: var(--magenta-600) !important;
}

.text-magenta-700,
.icon-magenta-700,
.text-link-magenta-700 {
    color: var(--magenta-700) !important;
}

.text-magenta-800,
.icon-magenta-800,
.text-link-magenta-800 {
    color: var(--magenta-800) !important;
}

.text-magenta-900,
.icon-magenta-900,
.text-link-magenta-900 {
    color: var(--magenta-900) !important;
}

/* Orange */
.text-orange-50,
.icon-orange-50,
.text-link-orange-50 {
    color: var(--orange-50) !important;
}

.text-orange-100,
.icon-orange-100,
.text-link-orange-100 {
    color: var(--orange-100) !important;
}

.text-orange-200,
.icon-orange-200,
.text-link-orange-200 {
    color: var(--orange-200) !important;
}

.text-orange-300,
.icon-orange-300,
.text-link-orange-300 {
    color: var(--orange-300) !important;
}

.text-orange-400,
.icon-orange-400,
.text-link-orange-400 {
    color: var(--orange-400) !important;
}

.text-orange,
.icon-orange,
.text-link-orange,
.text-orange-500,
.icon-orange-500,
.text-link-orange-500 {
    color: var(--orange-500) !important;
}

.text-orange-600,
.icon-orange-600,
.text-link-orange-600, .b-sash-solid-orange, .b-sash-outline-orange {
    color: var(--orange-600) !important;
}

.text-orange-700,
.icon-orange-700,
.text-link-orange-700 {
    color: var(--orange-700) !important;
}

.text-orange-800,
.icon-orange-800,
.text-link-orange-800,
.alert-warning {
    color: var(--orange-800) !important;
}

.text-orange-900,
.icon-orange-900,
.text-link-orange-900 {
    color: var(--orange-900) !important;
}

/* Yellow */
.text-yellow-50,
.icon-yellow-50,
.text-link-yellow-50 {
    color: var(--yellow-50) !important;
}

.text-yellow-100,
.icon-yellow-100,
.text-link-yellow-100 {
    color: var(--yellow-100) !important;
}

.text-yellow-200,
.icon-yellow-200,
.text-link-yellow-200 {
    color: var(--yellow-200) !important;
}

.text-yellow-300,
.icon-yellow-300,
.text-link-yellow-300 {
    color: var(--yellow-300) !important;
}

.text-yellow-400,
.icon-yellow-400,
.text-link-yellow-400 {
    color: var(--yellow-400) !important;
}

.text-yellow,
.icon-yellow,
.text-link-yellow,
.text-yellow-500,
.icon-yellow-500,
.text-link-yellow-500 {
    color: var(--yellow-500) !important;
}

.text-yellow-600,
.icon-yellow-600,
.text-link-yellow-600 {
    color: var(--yellow-600) !important;
}

.text-yellow-700,
.icon-yellow-700,
.text-link-yellow-700 {
    color: var(--yellow-700) !important;
}

.text-yellow-800,
.icon-yellow-800,
.text-link-yellow-800 {
    color: var(--yellow-800) !important;
}

.text-yellow-900,
.icon-yellow-900,
.text-link-yellow-900 {
    color: var(--yellow-900) !important;
}


/** TEXT LINK **/
.text-link-dark-blue, .text-link-light-blue, .text-link-black, .text-link-green, .text-link-orange, .text-link-magenta, .text-link-red, .text-link-teal, .text-link-turquoise, .text-link-white, .text-link-yellow, .text-link {
    border-bottom: 1px dotted;
    text-decoration: none;
    transition: 0.3s ease all;
}

.text-link-dark-blue:hover, .text-link-light-blue:hover, .text-link-black:hover, .text-link-green:hover, .text-link-orange:hover, .text-link-magenta:hover, .text-link-red:hover, .text-link-teal:hover, .text-link-turquoise:hover, .text-link-white:hover, .text-link-yellow:hover, .text-link:hover {
    border-bottom-style: solid;
    text-decoration: none;
}

/*
--------------------
  Border Colours
--------------------  
*/

/* Border Neutral */
.border-white, .list-border-white li,
.border-white,
.list-border-white li {
    border-color: var(--white) !important;
}

.border-black, .list-border-black li,
.border-white,
.list-border-white li {
    border-color: var(--black) !important;
}

.border-off-white, .list-border-off-white li,
.border-neutral-50,
.list-border-neutral-50 li {
    border-color: var(--neutral-50) !important;
}

.border-neutral-100,
.list-border-neutral-100 li {
    border-color: var(--neutral-100) !important;
}

.border-light-grey, .list-border-light-grey li,
.border-neutral-200,
.list-border-neutral-200 li,
.alert-neutral {
    border-color: var(--neutral-200) !important;
}

.border-neutral-300,
.list-border-neutral-300 li {
    border-color: var(--neutral-300) !important;
}

.border-neutral-400,
.list-border-neutral-400 li {
    border-color: var(--neutral-400) !important;
}

.border-mid-grey, .list-border-mid-grey li,
.border-neutral-500,
.list-border-neutral-500 li {
    border-color: var(--neutral-500) !important;
}

.border-neutral-600,
.list-border-neutral-600 li {
    border-color: var(--neutral-600) !important;
}

.border-grey, .list-border-grey li,
.border-neutral-700,
.list-border-neutral-700 li {
    border-color: var(--neutral-700) !important;
}

.border-neutral-800,
.list-border-neutral-800 li {
    border-color: var(--neutral-800) !important;
}

.border-charcoal, .list-border-charcoal li,
.border-neutral-900,
.list-border-neutral-900 li {
    border-color: var(--neutral-900) !important;
}

/* Border Dark Blue */
.border-dark-blue-50,
.list-border-dark-blue-50 li {
    border-color: var(--dark-blue-50) !important;
}

.border-dark-blue-100,
.list-border-dark-blue-100 li, .b-sash-solid-blue {
    border-color: var(--dark-blue-100) !important;
}

.border-dark-blue-200,
.list-border-dark-blue-200 li,
.alert-info {
    border-color: var(--dark-blue-200) !important;
}

.border-dark-blue-300,
.list-border-dark-blue-300 li {
    border-color: var(--dark-blue-300) !important;
}

.border-dark-blue-400,
.list-border-dark-blue-400 li {
    border-color: var(--dark-blue-400) !important;
}

.border-dark-blue,
.list-border-dark-blue li,
.border-dark-blue-500,
.list-border-dark-blue-500 li,
.border-primary {
    border-color: var(--dark-blue-500) !important;
}

.border-dark-blue-600,
.list-border-dark-blue-600 li {
    border-color: var(--dark-blue-600) !important;
}

.border-dark-blue-700,
.list-border-dark-blue-700 li {
    border-color: var(--dark-blue-700) !important;
}

.border-dark-blue-800,
.list-border-dark-blue-800 li {
    border-color: var(--dark-blue-800) !important;
}

.border-dark-blue-900,
.list-border-dark-blue-900 li {
    border-color: var(--dark-blue-900) !important;
}

/* Border Light Blue */
.border-light-blue-50,
.list-border-light-blue-50 li {
    border-color: var(--light-blue-50) !important;
}

.border-light-blue-100,
.list-border-light-blue-100 li {
    border-color: var(--light-blue-100) !important;
}

.border-light-blue-200,
.list-border-light-blue-200 li {
    border-color: var(--light-blue-200) !important;
}

.border-light-blue-300,
.list-border-light-blue-300 li {
    border-color: var(--light-blue-300) !important;
}

.border-light-blue-400,
.list-border-light-blue-400 li {
    border-color: var(--light-blue-400) !important;
}

.border-light-blue,
.list-border-light-blue li,
.border-light-blue-500,
.list-border-light-blue-500 li {
    border-color: var(--light-blue-500) !important;
}

.border-light-blue-600,
.list-border-light-blue-600 li, .b-sash-outline-blue {
    border-color: var(--light-blue-600) !important;
}

.border-light-blue-700,
.list-border-light-blue-700 li {
    border-color: var(--light-blue-700) !important;
}

.border-light-blue-800,
.list-border-light-blue-800 li {
    border-color: var(--light-blue-800) !important;
}

.border-light-blue-900,
.list-border-light-blue-900 li {
    border-color: var(--light-blue-900) !important;
}

/* Border Turquoise */
.border-turquoise-50,
.list-border-turquoise-50 li {
    border-color: var(--turquoise-50) !important;
}

.border-turquoise-100,
.list-border-turquoise-100 li, .b-sash-solid-turquoise {
    border-color: var(--turquoise-100) !important;
}

.border-turquoise-200,
.list-border-turquoise-200 li {
    border-color: var(--turquoise-200) !important;
}

.border-turquoise-300,
.list-border-turquoise-300 li {
    border-color: var(--turquoise-300) !important;
}

.border-turquoise-400,
.list-border-turquoise-400 li {
    border-color: var(--turquoise-400) !important;
}

.border-turquoise,
.list-border-turquoise li,
.border-turquoise-500,
.list-border-turquoise-500 li {
    border-color: var(--turquoise-500) !important;
}

.border-turquoise-600,
.list-border-turquoise-600 li, .b-sash-outline-turquoise {
    border-color: var(--turquoise-600) !important;
}

.border-turquoise-700,
.list-border-turquoise-700 li {
    border-color: var(--turquoise-700) !important;
}

.border-turquoise-800,
.list-border-turquoise-800 li {
    border-color: var(--turquoise-800) !important;
}

.border-turquoise-900,
.list-border-turquoise-900 li {
    border-color: var(--turquoise-900) !important;
}

/* Border Teal */
.border-teal-50,
.list-border-teal-50 li {
    border-color: var(--teal-50) !important;
}

.border-teal-100,
.list-border-teal-100 li {
    border-color: var(--teal-100) !important;
}

.border-teal-200,
.list-border-teal-200 li {
    border-color: var(--teal-200) !important;
}

.border-teal-300,
.list-border-teal-300 li {
    border-color: var(--teal-300) !important;
}

.border-teal-400,
.list-border-teal-400 li {
    border-color: var(--teal-400) !important;
}

.border-teal,
.list-border-teal li,
.border-teal-500,
.list-border-teal-500 li {
    border-color: var(--teal-500) !important;
}

.border-teal-600,
.list-border-teal-600 li {
    border-color: var(--teal-600) !important;
}

.border-teal-700,
.list-border-teal-700 li {
    border-color: var(--teal-700) !important;
}

.border-teal-800,
.list-border-teal-800 li {
    border-color: var(--teal-800) !important;
}

.border-teal-900,
.list-border-teal-900 li {
    border-color: var(--teal-900) !important;
}

/* Border Green */
.border-green-50,
.list-border-green-50 li {
    border-color: var(--green-50) !important;
}

.border-green-100,
.list-border-green-100 li, .b-sash-solid-green {
    border-color: var(--green-100) !important;
}

.border-green-200,
.list-border-green-200 li,
.alert-success {
    border-color: var(--green-200) !important;
}

.border-green-300,
.list-border-green-300 li {
    border-color: var(--green-300) !important;
}

.border-green-400,
.list-border-green-400 li {
    border-color: var(--green-400) !important;
}

.border-green,
.list-border-green li,
.border-green-500,
.list-border-green-500 li {
    border-color: var(--green-500) !important;
}

.border-green-600,
.list-border-green-600 li {
    border-color: var(--green-600) !important;
}

.border-green-700,
.list-border-green-700 li, .b-sash-outline-green {
    border-color: var(--green-700) !important;
}

.border-green-800,
.list-border-green-800 li {
    border-color: var(--green-800) !important;
}

.border-green-900,
.list-border-green-900 li {
    border-color: var(--green-900) !important;
}

/* Border Red */
.border-red-50,
.list-border-red-50 li {
    border-color: var(--red-50) !important;
}

.border-red-100,
.list-border-red-100 li {
    border-color: var(--red-100) !important;
}

.border-red-200,
.list-border-red-200 li,
.alert-error {
    border-color: var(--red-200) !important;
}

.border-red-300,
.list-border-red-300 li {
    border-color: var(--red-300) !important;
}

.border-red-400,
.list-border-red-400 li, .b-sash-solid-red {
    border-color: var(--red-400) !important;
}

.border-red,
.list-border-red li,
.border-red-500,
.list-border-red-500 li, .b-sash-outline-red {
    border-color: var(--red-500) !important;
}

.border-red-600,
.list-border-red-600 li {
    border-color: var(--red-600) !important;
}

.border-red-700,
.list-border-red-700 li {
    border-color: var(--red-700) !important;
}

.border-red-800,
.list-border-red-800 li {
    border-color: var(--red-800) !important;
}

.border-red-900,
.list-border-red-900 li {
    border-color: var(--red-900) !important;
}

/* Border Magenta */
.border-magenta-50,
.list-border-magenta-50 li {
    border-color: var(--magenta-50) !important;
}

.border-magenta-100,
.list-border-magenta-100 li {
    border-color: var(--magenta-100) !important;
}

.border-magenta-200,
.list-border-magenta-200 li {
    border-color: var(--magenta-200) !important;
}

.border-magenta-300,
.list-border-magenta-300 li {
    border-color: var(--magenta-300) !important;
}

.border-magenta-400,
.list-border-magenta-400 li {
    border-color: var(--magenta-400) !important;
}

.border-magenta,
.list-border-magenta li,
.border-magenta-500,
.list-border-magenta-500 li {
    border-color: var(--magenta-500) !important;
}

.border-magenta-600,
.list-border-magenta-600 li {
    border-color: var(--magenta-600) !important;
}

.border-magenta-700,
.list-border-magenta-700 li {
    border-color: var(--magenta-700) !important;
}

.border-magenta-800,
.list-border-magenta-800 li {
    border-color: var(--magenta-800) !important;
}

.border-magenta-900,
.list-border-magenta-900 li {
    border-color: var(--magenta-900) !important;
}

/* Border Orange */
.border-orange-50,
.list-border-orange-50 li {
    border-color: var(--orange-50) !important;
}

.border-orange-100,
.list-border-orange-100 li, .b-sash-solid-orange {
    border-color: var(--orange-100) !important;
}

.border-orange-200,
.list-border-orange-200 li,
.alert-warning {
    border-color: var(--orange-200) !important;
}

.border-orange-300,
.list-border-orange-300 li {
    border-color: var(--orange-300) !important;
}

.border-orange-400,
.list-border-orange-400 li {
    border-color: var(--orange-400) !important;
}

.border-orange,
.list-border-orange li,
.border-orange-500,
.list-border-orange-500 li {
    border-color: var(--orange-500) !important;
}

.border-orange-600,
.list-border-orange-600 li, .b-sash-outline-orange {
    border-color: var(--orange-600) !important;
}

.border-orange-700,
.list-border-orange-700 li {
    border-color: var(--orange-700) !important;
}

.border-orange-800,
.list-border-orange-800 li {
    border-color: var(--orange-800) !important;
}

.border-orange-900,
.list-border-orange-900 li {
    border-color: var(--orange-900) !important;
}

/* Border Yellow */
.border-yellow-50,
.list-border-yellow-50 li {
    border-color: var(--yellow-50) !important;
}

.border-yellow-100,
.list-border-yellow-100 li {
    border-color: var(--yellow-100) !important;
}

.border-yellow-200,
.list-border-yellow-200 li {
    border-color: var(--yellow-200) !important;
}

.border-yellow-300,
.list-border-yellow-300 li {
    border-color: var(--yellow-300) !important;
}

.border-yellow-400,
.list-border-yellow-400 li {
    border-color: var(--yellow-400) !important;
}

.border-yellow,
.list-border-yellow li,
.border-yellow-500,
.list-border-yellow-500 li {
    border-color: var(--yellow-500) !important;
}

.border-yellow-600,
.list-border-yellow-600 li {
    border-color: var(--yellow-600) !important;
}

.border-yellow-700,
.list-border-yellow-700 li {
    border-color: var(--yellow-700) !important;
}

.border-yellow-800,
.list-border-yellow-800 li {
    border-color: var(--yellow-800) !important;
}

.border-yellow-900,
.list-border-yellow-900 li {
    border-color: var(--yellow-900) !important;
}
/** ICONS **/
@font-face {
    font-family: 'apdicons';
    src: url('/SiteContent/toolkit/icons/apdicons.ttf?pnvauq') format('truetype'), url('/SiteContent/toolkit/icons/apdicons.woff?pnvauq') format('woff'), url('/SiteContent/toolkit/icons/apdicons.svg?pnvauq#apdicons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'apdicons' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 1rem;
    text-rendering: auto;
}

.icon-account:before {
    content: "\e900";
}

.icon-amazon-pay:before {
    content: "\e901";
}

.icon-back-to-top:before {
    content: "\e902";
}

.icon-basket:before {
    content: "\e903";
}

.icon-bbq:before {
    content: "\e904";
}

.icon-box:before {
    content: "\e905";
}

.icon-calendar:before {
    content: "\e906";
}

.icon-cc-amazon:before {
    content: "\e907";
}

.icon-cc-apple-pay:before {
    content: "\e908";
}

.icon-cc-mastercard:before {
    content: "\e909";
}

.icon-cc-paypal:before {
    content: "\e90a";
}

.icon-cc-visa:before {
    content: "\e90b";
}

.icon-chat:before {
    content: "\e90c";
}

.icon-check:before {
    content: "\e90d";
}

.icon-chevron-down:before {
    content: "\e90e";
}

.icon-chevron-left:before {
    content: "\e90f";
}

.icon-chevron-right:before {
    content: "\e910";
}

.icon-chevron-up:before {
    content: "\e911";
}

.icon-circle-check:before {
    content: "\e912";
}

.icon-clock:before {
    content: "\e913";
}

.icon-customer-reviews:before {
    content: "\e914";
}

.icon-customer-service:before {
    content: "\e915";
}

.icon-delivery:before {
    content: "\e916";
}

.icon-delivery-next-day:before {
    content: "\e917";
}

.icon-delivery-timed:before {
    content: "\e918";
}

.icon-discount:before {
    content: "\e919";
}

.icon-double-chevron-left:before {
    content: "\e91a";
}

.icon-double-chevron-right:before {
    content: "\e91b";
}

.icon-euro-sign:before {
    content: "\e91c";
}

.icon-finance:before {
    content: "\e91d";
}

.icon-guarantee:before {
    content: "\e91e";
}

.icon-info-circle:before {
    content: "\e91f";
}

.icon-inspect:before {
    content: "\e920";
}

.icon-installation:before {
    content: "\e921";
}

.icon-mail:before {
    content: "\e922";
}

.icon-mail-solid:before {
    content: "\e923";
}

.icon-map-marker:before {
    content: "\e924";
}

.icon-map-marker-solid:before {
    content: "\e925";
}

.icon-minus:before {
    content: "\e926";
}

.icon-package-deals:before {
    content: "\e927";
}

.icon-paypal:before {
    content: "\e928";
}

.icon-phone:before {
    content: "\e929";
}

.icon-play-circle:before {
    content: "\e92a";
}

.icon-play-circle-solid:before {
    content: "\e92b";
}

.icon-plus:before {
    content: "\e92c";
}

.icon-plus-circle:before {
    content: "\e92d";
}

.icon-pound-sign:before {
    content: "\e92e";
}

.icon-promotion:before {
    content: "\e92f";
}

.icon-q-and-a:before {
    content: "\e930";
}

.icon-question:before {
    content: "\e931";
}

.icon-refurb-recycle:before {
    content: "\e932";
}

.icon-save-money:before {
    content: "\e933";
}

.icon-search:before {
    content: "\e934";
}

.icon-spec:before {
    content: "\e935";
}

.icon-star:before {
    content: "\e936";
}

.icon-star-solid:before {
    content: "\e937";
}

.icon-times:before {
    content: "\e938";
}

.icon-times-circle:before {
    content: "\e939";
}

.icon-trade:before {
    content: "\e93a";
}

.icon-video:before {
    content: "\e93b";
}

.icon-wishlist:before {
    content: "\e93c";
}

.icon-wishlist-solid:before {
    content: "\e93d";
}
  

.icon-dark-blue-gradient {
    background: linear-gradient(to top, #081a44, #0f4a5a, #153c70, #194e86, #1a619d, #1a75b5, #178acc, #0e9fe3);
    background: -webkit-gradient(to top, #081a44, #0f4a5a, #153c70, #194e86, #1a619d, #1a75b5, #178acc, #0e9fe3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.icon-light-blue-gradient {
    background: linear-gradient(to bottom, #1edcca, #00d5d3, #00cdda, #00c5e0, #00bce4, #00b3e6, #00a9e6, #0e9fe3);
    background: -webkit-gradient(to bottom, #1edcca, #00d5d3, #00cdda, #00c5e0, #00bce4, #00b3e6, #00a9e6, #0e9fe3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.icon-teal-gradient {
    background: linear-gradient(to top, #3fb498, #3dba9f, #3abfa5, #37c5ac, #34cbb4, #4dd0bb, #47d6c4, #1edcca);
    background: -webkit-gradient(to top, #3fb498, #3dba9f, #3abfa5, #37c5ac, #34cbb4, #4dd0bb, #47d6c4, #1edcca);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
/** IE ICON GRADIENT FALLBACK **/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .icon-dark-blue-gradient {
        color: #081a44;
        background: transparent;
    }

    .icon-light-blue-gradient {
        color: #0e9fe3;
        background: transparent;
    }

    .icon-teal-gradient {
        color: #3fb498;
        background: transparent;
    }
}
/** BUTTON **/

/* Button Green */
.button-green,
.button-green-outline:hover {
    color: var(--white) !important;
    background-color: var(--green-500);
    border-color: var(--green-500);
}

.button-green:hover {
    background-color: var(--green-600);
    border-color: var(--green-600);
    color: var(--white) !important;
}

.button-green:focus,
.button-green:active,
.button-green.active,
.button-green-outline:focus,
.button-green-outline:active,
.button-green-outline.active {
    background-color: var(--green-500);
    border-color: var(--green-300);
    color: var(--white) !important;
}

.button-green-outline {
    color: var(--green-500) !important;
    background-color: var(--white);
    border-color: var(--green-500);
}

/* Buttons Dark Blue */
.button-primary,
.button-dark-blue,
.button-dark-blue-outline:hover,
.card-product-tile .button:hover {
    color: var(--white) !important;
    background-color: var(--dark-blue-500);
    border-color: var(--dark-blue-500);
}

.button-primary:hover,
.button-dark-blue:hover {
    background-color: var(--dark-blue-600);
    border-color: var(--dark-blue-600);
    color: var(--white) !important;
}

.button-primary:focus,
.button-primary:active,
.button-primary.active,
.button-secondary:focus,
.button-secondary:active,
.button-secondary.active,
.card-product-tile .button-primary:active,
.card-product-tile .button-primary:focus,
.button-dark-blue:focus,
.button-dark-blue:active,
.button-dark-blue.active,
.button-dark-blue-outline:focus,
.button-dark-blue-outline:active,
.button-dark-blue-outline.active,
.card-product-tile .button:active,
.card-product-tile .button:focus {
    background-color: var(--dark-blue-500);
    border-color: var(--dark-blue-300);
    color: var(--white) !important;
}

.button-secondary,
.card-product-tile .button-secondary,
.button-dark-blue-outline,
.card-product-tile .button {
    color: var(--dark-blue-500) !important;
    background-color: var(--white);
    border-color: var(--dark-blue-500);
}

/* Buttons Light Blue */
.button-light-blue,
.button-light-blue-outline:hover,
.card-product-tile .button:hover {
    color: var(--white) !important;
    background-color: var(--light-blue-500);
    border-color: var(--light-blue-500);
}

.button-light-blue:hover {
    background-color: var(--light-blue-600);
    border-color: var(--light-blue-600);
    color: var(--white) !important;
}

.button-light-blue:focus,
.button-light-blue:active,
.button-light-blue.active,
.button-light-blue-outline:focus,
.button-light-blue-outline:active,
.button-light-blue-outline.active,
.card-product-tile .button:active,
.card-product-tile .button:focus {
    background-color: var(--light-blue-500);
    border-color: var(--light-blue-300);
    color: var(--white) !important;
}

.button-light-blue-outline,
.card-product-tile .button {
    color: var(--light-blue-500) !important;
    background-color: var(--white);
    border-color: var(--light-blue-500);
}

/* Button Turquoise */
.button-turquoise,
.button-turquoise-outline:hover {
    color: var(--turquoise-900) !important;
    background-color: var(--turquoise-500);
    border-color: var(--turquoise-500);
}

.button-turquoise:hover {
    background-color: var(--turquoise-600);
    border-color: var(--turquoise-600);
    color: var(--turquoise-900) !important;
}

.button-turquoise:focus,
.button-turquoise:active,
.button-turquoise.active,
.button-turquoise-outline:focus,
.button-turquoise-outline:active,
.button-turquoise-outline.active {
    background-color: var(--turquoise-500);
    border-color: var(--turquoise-300);
    color: var(--turquoise-900) !important;
}

.button-turquoise-outline {
    color: var(--turquoise-500) !important;
    background-color: var(--white);
    border-color: var(--turquoise-500);
}

/* Button Red */
.button-red,
.button-red-outline:hover,
.card-product-tile.card-product-sale .button {
    color: var(--white) !important;
    background-color: var(--red-500);
    border-color: var(--red-500);
}

.button-red:hover,
.card-product-tile.card-product-sale .button:hover {
    background-color: var(--red-600);
    border-color: var(--red-600);
    color: var(--white) !important;
}

.button-red:focus,
.button-red:active,
.button-red.active,
.button-red-outline:focus,
.button-red-outline:active,
.button-red-outline.active,
.card-product-tile.card-product-sale .button:focus,
.card-product-tile.card-product-sale .button:active {
    background-color: var(--red-500);
    border-color: var(--red-300);
    color: var(--white) !important;
    }

.button-red-outline {
    color: var(--red-500) !important;
    background-color: var(--white);
    border-color: var(--red-500);
}

/* Button Magenta */
.button-magenta,
.button-magenta-outline:hover,
.card-product-tile.card-product-sale .button {
    color: var(--white) !important;
    background-color: var(--magenta-500);
    border-color: var(--magenta-500);
}

.button-magenta:hover,
.card-product-tile.card-product-sale .button:hover {
    background-color: var(--magenta-600);
    border-color: var(--magenta-600);
    color: var(--white) !important;
}

.button-magenta:focus,
.button-magenta:active,
.button-magenta.active,
.button-magenta-outline:focus,
.button-magenta-outline:active,
.button-magenta-outline.active,
.card-product-tile.card-product-sale .button:focus,
.card-product-tile.card-product-sale .button:active {
    background-color: var(--magenta-500);
    border-color: var(--magenta-300);
    color: var(--white) !important;
}

.button-magenta-outline {
    color: var(--magenta-500) !important;
    background-color: var(--white);
    border-color: var(--magenta-500);
}

/* Button Orange */
.button-orange,
.button-orange-outline:hover,
.card-product-tile.card-product-sale .button {
    color: var(--white) !important;
    background-color: var(--orange-500);
    border-color: var(--orange-500);
}

.button-orange:hover,
.card-product-tile.card-product-sale .button:hover {
    background-color: var(--orange-600);
    border-color: var(--orange-600);
    color: var(--white) !important;
}

.button-orange:focus,
.button-orange:active,
.button-orange.active,
.button-orange-outline:focus,
.button-orange-outline:active,
.button-orange-outline.active,
.card-product-tile.card-product-sale .button:focus,
.card-product-tile.card-product-sale .button:active {
    background-color: var(--orange-500);
    border-color: var(--orange-300);
    color: var(--white) !important;
}

.button-orange-outline {
    color: var(--orange-500) !important;
    background-color: var(--white);
    border-color: var(--orange-500);
}


/* Button White */
.button-white,
.button-white-outline:hover,
.button-white-outline:focus,
.button-white-outline:active,
.button-white-outline.active {
    color: var(--dark-blue-500) !important;
    background-color: var(--white);
    border-color: var(--white);
}

.button-white:hover {
    background-color: transparent;
    border-color: var(--white);
    color: var(--white) !important;
}

.button-white:focus,
.button-white:active,
.button-white.active {
    background-color: transparent;
    border-color: var(--white);
    color: var(--white) !important;
}

.button-white-outline {
    color: var(--white) !important;
    border-color: var(--white);
}

/* Button Yellow */
.button-yellow,
.button-yellow-outline:hover,
.card-product-tile.card-product-clearance .button {
    color: var(--yellow-900) !important;
    background-color: var(--yellow-500);
    border-color: var(--yellow-500);
}

.button-yellow:hover,
.card-product-tile.card-product-clearance .button:hover {
    background-color: var(--yellow-700);
    border-color: var(--yellow-700);
    color: var(--yellow-900) !important;
}

.button-yellow:focus,
.button-yellow:active,
.button-yellow.active,
.button-yellow-outline:focus,
.button-yellow-outline:active,
.button-yellow-outline.active,
.card-product-tile.card-product-clearance .button:active,
.card-product-tile.card-product-clearance .button:focus {
    background-color: var(--yellow-500);
    border-color: var(--yellow-300);
    color: var(--yellow-900) !important;
}

.button-yellow-outline {
    color: var(--yellow-700) !important;
    border-color: var(--yellow-700);
}

/* Button Teal */
.button-teal,
.button-teal-outline:hover,
.card-product-tile.card-product-refurb .button {
    color: var(--white) !important;
    background-color: var(--teal-500);
    border-color: var(--teal-500);
}

.button-teal:hover,
.card-product-tile.card-product-refurb .button:hover {
    background-color: var(--teal-600);
    border-color: var(--teal-600);
    color: var(--white) !important;
}

.button-teal:focus,
.button-teal:active,
.button-teal.active,
.button-teal-outline:focus,
.button-teal-outline:active,
.button-teal-outline.active,
.card-product-tile.card-product-refurb .button:active,
.card-product-tile.card-product-refurb .button:focus {
    background-color: var(--teal-500);
    border-color: var(--teal-300);
    color: var(--white) !important;
}

.button-teal-outline {
    color: var(--teal-500) !important;
    border-color: var(--teal-500);
}

/* Button Black */
.button-black,
.button-black-outline:hover {
    color: var(--white) !important;
    background-color: var(--neutral-900);
    border-color: var(--neutral-900);
}

.button-black:hover {
    background-color: var(--neutral-800);
    border-color: var(--neutral-800);
    color: var(--white) !important;
}

.button-black:focus,
.button-black:active,
.button-black.active,
.button-black-outline:focus,
.button-black-outline:active,
.button-black-outline.active {
    background-color: var(--neutral-900);
    border-color: var(--neutral-700);
    color: var(--white) !important;
}

.button-black-outline {
    color: var(--neutral-900) !important;
    border-color: var(--neutral-900);
}

.button-light {
    color: var(--neutral-900) !important;
    background-color: var(--neutral-100) !important;
    border-color: var(--neutral-300) !important;
}

.button-light:hover, .button-light-outline:hover {
    background-color: transparent !important;
    border-color: var(--neutral-300);
    color: var(--neutral-900) !important;
}

.button-light:focus, .button-light:active, .button-light.active, .button-light-outline:focus, .button-light-outline:active, .button-light-outline.active {
    background-color: var(--neutral-300) !important;
    border-color: var(--neutral-500) !important;
    color: var(--neutral-900) !important;
}

.button-light-outline {
    color: var(--neutral-900) !important;
    border-color: var(--neutral-300) !important;
}

/* Black Text link */
.button-text-link-black {
    color: var(--neutral-900) !important;
}

.button-text-link-black:hover {
    background-color: transparent;
    border-color: transparent;
    color: var(--neutral-800) !important;
    text-decoration: underline dotted;
}

.button-text-link-black:focus,
.button-text-link-black:active,
.button-text-link-black.active {
    background-color: transparent;
    border-color: var(--neutral-900);
    color: var(--neutral-900) !important;
}

/* White Text Link */
.button-text-link-white {
    color: var(--white) !important;
}

.button-text-link-white:hover {
    background-color: rgba(455, 455, 455, 0.18);
    border-color: rgba(455, 455, 455, 0.18);
    color: #fff !important;
}

.button-text-link-white:focus,
.button-text-link-white:active,
.button-text-link-white.active {
    background-color: rgba(455, 455, 455, 0.18);
    border-color: #fff;
    color: #fff !important;
}

.button-group {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
}

    .button-group > .button {
        position: relative;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }

        .button-group > .button:hover {
            z-index: 1;
        }

        .button-group > .button:focus, .button-group > .button:active, .button-group > .button.active {
            z-index: 1;
        }

        .button-group > .button:not(:first-child), .button-group > .button-group:not(:first-child) {
            margin-left: -1px;
        }

        .button-group > .button:not(:last-child), .button-group > .button-group:not(:last-child) > .button {
            border-top-right-radius: 0!important;
            border-bottom-right-radius: 0!important;
        }

        .button-group > .button:not(:first-child), .button-group > .button-group:not(:first-child) > .button {
            border-top-left-radius: 0!important;
            border-bottom-left-radius: 0!important;
        }
/** Forms **/
.form-input:focus,
.form-input:focus + .input-button-right {
    border-color: var(--dark-blue-300) !important;
    color: var(--neutral-900);
}

.form-input-destructive,
.form-input-destructive:focus,
.form-input-destructive:focus + .input-button-right {
    border-color: var(--red-300) !important;
}

.form-input-success,
.form-input-success:focus,
.form-input-success:focus + .input-button-right {
    border-color: var(--green-300) !important;
}

.form-checkbox-input:checked ~ .form-checkbox-label::before {
    color: var(--white) !important;
    border-color: var(--dark-blue-500) !important;
    background-color: var(--dark-blue-500) !important;
}

.form-radio-input:checked ~ .form-radio-label::before {
    border-color: var(--dark-blue-500) !important;
    background-color: var(--white) !important;
    border-width: 2px;
}

.form-checkbox .form-checkbox-input:checked ~ .form-checkbox-label::after {
    font-family: "apdicons";
    content: "\e90d";
    color: var(--white) !important;
}

.form-radio .form-radio-input:checked ~ .form-radio-label::after {
    background: var(--dark-blue-500) content-box !important;
}

.form-select-wrapper:after {
    font-family: "apdicons";
    content: "\e90e";
    font-size: 1rem;
    color: var(--black);
}

/** Lists **/
.ordered-list-solid.list-dark-blue li::before {
    background-color: #081a44;
    color: #fff;
}

.ordered-list-solid.list-light-blue li::before {
    background-color: #0e9fe3;
    color: #fff;
}

.ordered-list-solid.list-teal li::before {
    background-color: #3fb498;
    color: #fff;
}

.ordered-list-solid.list-green li::before {
    background-color: #04865C;
    color: #fff;
}

.ordered-list-solid.list-magenta li::before {
    background-color: #e40565;
    color: #fff;
}

.ordered-list-solid.list-orange li::before {
    background-color: #f4711c;
    color: #fff;
}

.ordered-list-solid.list-yellow li::before {
    background-color: #fbeb08;
    color: #000;
}

.ordered-list-solid.list-turquoise li::before {
    background-color: #1edcca;
    color: #fff;
}

.ordered-list-solid.list-red li::before {
    background-color: #c5000c;
    color: #fff;
}

.ordered-list-solid.list-black li::before {
    background-color: #000000;
    color: #fff;
}

.ordered-list-solid.list-charcoal li::before {
    background-color: #1b1b1b;
    color: #fff;
}

.ordered-list-solid.list-grey li::before {
    background-color: #5b5b5b;
    color: #fff;
}

.ordered-list-solid.list-mid-grey li::before {
    background-color: #b9b9b9;
    color: #1b1b1b;
}

.ordered-list-solid.list-light-grey li::before {
    background-color: #eeeeee;
    color: #5b5b5b;
}

.ordered-list-outline.list-dark-blue li::before {
    border-color: #081a44;
    color: #081a44;
}

.ordered-list-outline.list-light-blue li::before {
    border-color: #0e9fe3;
    color: #0e9fe3;
}

.ordered-list-outline.list-teal li::before {
    border-color: #3fb498;
    color: #3fb498;
}

.ordered-list-outline.list-green li::before {
    border-color: #04865C;
    color: #04865C;
}

.ordered-list-outline.list-magenta li::before {
    border-color: #e40565;
    color: #e40565;
}

.ordered-list-outline.list-orange li::before {
    border-color: #f4711c;
    color: #f4711c;
}

.ordered-list-outline.list-yellow li::before {
    border-color: #fbeb08;
    color: #000;
}

.ordered-list-outline.list-turquoise li::before {
    border-color: #1edcca;
    color: #1edcca;
}

.ordered-list-outline.list-red li::before {
    border-color: #c5000c;
    color: #c5000c;
}

.ordered-list-outline.list-black li::before {
    border-color: #000000;
    color: #000;
}

.ordered-list-outline.list-charcoal li::before {
    border-color: #1b1b1b;
    color: #1b1b1b;
}

.ordered-list-outline.list-grey li::before {
    border-color: #5b5b5b;
    color: #5b5b5b;
}

.ordered-list-outline.list-mid-grey li::before {
    border-color: #b9b9b9;
    color: #b9b9b9;
}

.ordered-list-outline.list-light-grey li::before {
    border-color: #eeeeee;
    color: #5b5b5b;
}
.unordered-list-tick li::before {
    font-family: 'apdicons' !important;
    content: '\e90d';
}
.unordered-list-tick.list-dark-blue li::before {
    color: #081a44;
}

.unordered-list-tick.list-light-blue li::before {
    color: #0e9fe3;
}

.unordered-list-tick.list-teal li::before {
    color: #3fb498;
}

.unordered-list-tick.list-green li::before {
    color: #04865C;
}

.unordered-list-tick.list-magenta li::before {
    color: #e40565;
}

.unordered-list-tick.list-orange li::before {
    color: #f4711c;
}

.unordered-list-tick.list-yellow li::before {
    color: #000;
}

.unordered-list-tick.list-turquoise li::before {
    color: #1edcca;
}

.unordered-list-tick.list-red li::before {
    color: #c5000c;
}

.unordered-list-tick.list-black li::before {
    color: #000;
}

.unordered-list-tick.list-charcoal li::before {
    color: #1b1b1b;
}

.unordered-list-tick.list-grey li::before {
    color: #5b5b5b;
}

.unordered-list-tick.list-mid-grey li::before {
    color: #b9b9b9;
}

.unordered-list-tick.list-light-grey li::before {
    color: #eeeeee;
}

.list-active-dark-blue .toggle-accordion.open, .list-active-dark-blue .toggle-accordion:hover {
    background-color: #081a44;
    color: #fff !important;
}

.list-active-light-blue .toggle-accordion.open, .list-active-light-blue .toggle-accordion:hover {
    background-color: #0e9fe3;
    color: #fff !important;
}

.list-active-turquoise .toggle-accordion.open, .list-active-turquoise .toggle-accordion:hover {
    background-color: #1edcca;
    color: #fff !important;
}

.list-active-teal .toggle-accordion.open, .list-active-teal .toggle-accordion:hover {
    background-color: #3fb498;
    color: #fff !important;
}

.list-active-green .toggle-accordion.open, .list-active-green .toggle-accordion:hover {
    background-color: #04865C;
    color: #fff !important;
}

.list-active-red .toggle-accordion.open, .list-active-red .toggle-accordion:hover {
    background-color: #c5000c;
    color: #fff !important;
}

.list-active-magenta .toggle-accordion.open, .list-active-magenta .toggle-accordion:hover {
    background-color: #e40565;
    color: #fff !important;
}

.list-active-orange .toggle-accordion.open, .list-active-orange .toggle-accordion:hover {
    background-color: #f4711c;
    color: #fff !important;
}

.list-active-yellow .toggle-accordion.open, .list-active-yellow .toggle-accordion:hover {
    background-color: #fbeb08;
    color: #000 !important;
}

.list-active-black .toggle-accordion.open, .list-active-black .toggle-accordion:hover {
    background-color: #000000;
    color: #fff !important;
}

.list-active-charcoal .toggle-accordion.open, .list-active-charcoal .toggle-accordion:hover {
    background-color: #1b1b1b;
    color: #fff !important;
}

.list-active-grey .toggle-accordion.open, .list-active-grey .toggle-accordion:hover {
    background-color: #5b5b5b;
    color: #fff !important;
}

.list-active-mid-grey .toggle-accordion.open, .list-active-mid-grey .toggle-accordion:hover {
    background-color: #b9b9b9;
    color: #1b1b1b !important;
}

.list-active-light-grey .toggle-accordion.open, .list-active-light-grey .toggle-accordion:hover {
    background-color: #eeeeee;
}
.list-accordion.chevron-right .toggle-accordion:after, .list-accordion.chevron-left .toggle-accordion:before {
    font-family: 'apdicons' !important;
    content: '\e90e';
}

.list-accordion.plus-right .toggle-accordion:after, .list-accordion.plus-left .toggle-accordion:before {
    font-family: 'apdicons' !important;
    content: '\e92c';
}

.list-accordion.plus-right .toggle-accordion.open:after, .list-accordion.plus-left .toggle-accordion.open:before {
    content: '\e926';
}
/** Pagination **/

.page-link:hover {
    border-color: #081a44;
    color: #081a44;
}

.page-item.active .page-link {
    color: #fff;
    background-color: #081a44;
    border-color: #081a44;
}
/** Roundels **/
.roundel-red,
.card-product-tile.card-product-sale .FPpercent {
    background-color: var(--red-500) !important;
    color: var(--white) !important;
}

.roundel-yellow,
.card-product-tile.card-product-clearance .FPpercent {
    background-color: var(--yellow-500) !important;
    color: var(--yellow-900) !important;
}

.roundel-teal,
.card-product-tile.card-product-refurb .FPpercent {
    background-color: var(--teal-500) !important;
    color: var(--white) !important;
}

.roundel-dark-blue,
.roundel-primary {
    background-color: var(--dark-blue-500);
    color: var(--white);
}
/** Tables **/
.table .thead-dark-blue th,
.table .thead-primary th {
    color: var(--white);
    background-color: var(--dark-blue-500);
    border-color: var(--dark-blue-500);
}

.table .thead-light-blue th {
    color: var(--white);
    background-color: var(--light-blue-500);
    border-color: var(--light-blue-500);
}

.table .thead-turquoise th {
    color: var(--white);
    background-color: var(--turquoise-500);
    border-color: var(--turquoise-500);
}

.table .thead-teal th {
    color: var(--white);
    background-color: var(--teal-500);
    border-color: var(--teal-500);
}

.table .thead-green th {
    color: var(--white);
    background-color: var(--green-500);
    border-color: var(--green-500);
}

.table .thead-red th {
    color: var(--white);
    background-color: var(--red-500);
    border-color: var(--red-500);
}

.table .thead-magenta th {
    color: var(--white);
    background-color: var(--magenta-500);
    border-color: var(--magenta-500);
}

.table .thead-orange th {
    color: var(--white);
    background-color: var(--orange-500);
    border-color: var(--orange-500);
}

.table .thead-yellow th {
    color: var(--yellow-900);
    background-color: var(--yellow-500);
    border-color: var(--yellow-500);
}

.table .thead-black th {
    color: var(--white);
    background-color: var(--black);
    border-color: var(--black);
}

.table .thead-charcoal th {
    color: var(--white);
    background-color: var(--neutral-900);
    border-color: var(--neutral-900);
}

.table .thead-grey th {
    color: var(--white);
    background-color: var(--neutral-500);
    border-color: var(--neutral-500);
}

.table .thead-mid-grey th {
    color: var(--white);
    background-color: var(--neutral-300);
    border-color: var(--neutral-300);
}

.table .thead-light-grey th {
    color: var(--neutral-900);
    background-color: var(--neutral-100);
    border-color: var(--neutral-100);
}

.table .thead-off-white th {
    color: var(--neutral-900);
    background-color: var(--neutral-50);
    border-color: var(--neutral-50);
}
/** Tabs **/
.tabs-navigation .tab-item.active,
.tabs-navigation .tab-item.show .nav-link {
    color: var(--dark-blue-500);
    background-color: var(--white);
    border-color: var(--dark-blue-500);
}
/** Cards **/
.card {
    box-shadow: 1px 1px 3px rgb(0 0 0 / 20%);
    border-radius: 0.3rem;
}

.category-card-title h2, .category-hub-title h2, .category-hub-title h3, .category-card-title h3 {
    color: #081a44;
    font-family: gibson_semibold, sans-serif;
    font-weight: normal;
}

.category-card-title, .category-card-buttons {
    padding-left: 0.5rem;
}

.category-card-image {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
}

    .category-card-image .roundel-small {
        width: 55px;
        height: 55px;
    }
    .category-card-image .roundel-top-right {
        top: 0 !important;
        right: 0 !important;
    }

    .category-card-image > img, .category-card-image > a > img {
        margin: 0 auto;
        max-height: 120px;
    }

.category-card-buttons .button {
    display: block;
}

.category-card-title {
    order: 2;
}

.category-card-image {
    order: 1;
    position: relative;
}

.category-card-buttons {
    order: 3;
}

.multiple-buttons .category-card-buttons, .category-hub-content {
    display: flex;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    align-content: flex-end;
    -webkit-align-content: flex-end;
}

    .multiple-buttons .category-card-buttons .button, .category-hub-content .button {
        flex-grow: 1;
        flex-basis: 0;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        box-sizing: border-box;
    }
    .category-card-buttons .button, .category-hub-content .button {
        margin: 0.4rem;
    }

    .category-hub-content > a > img {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-bottom: 1rem;
        box-sizing: border-box;
    }

.horizontal-hub {
    display: flex !important;
    display: -ms-flexbox;
    align-items: center;
    -ms-align-items: center;
    align-content: center;
    -ms-align-content: center;
    justify-content: center;
    padding: 1.15rem !important;
    flex-wrap: wrap;
    text-align: center;
    box-sizing: border-box;
}

@media only screen and (min-width: 768px) {
    .card {
        padding: 1.4rem 1rem;
        text-align: center;
        position: relative;
        height: 100%;
    }

    .category-card {
        display: flex;
        display: -ms-flexbox;
        flex-direction: column;
        -ms-flex-direction: column;
    }

    .category-card-image, .category-card-title, .category-card-buttons {
        width: 100%;
        padding: 0;
    }

    .category-card-title, .category-card-buttons {
        padding-left: 0;
    }

    .category-card-image .roundel-small {
        width: 65px;
        height: 65px;
    }

    .category-card-image > img, .category-card-image > a > img {
        max-height: 200px;
    }
    .category-card-image .roundel-top-right {
        top: 1rem !important;
        right: 1rem !important;
    }
    .category-card-title {
        order: 1;
    }

    .category-card-image {
        order: 2;
    }

    .category-card-buttons {
        order: 3;
        margin-top: auto;
    }

    .multiple-buttons .category-card-buttons {
        display: flex;
        display: -ms-flexbox;
    }

    .category-card-buttons .button {
        display: inline-block;
    }

    .category-card-buttons .button, .category-hub-content .button {
        margin: 0.8rem 0.4rem 0 0.4rem;
    }

    .horizontal-hub {
        justify-content: space-between;
    }
}
/** PRODUCT CARDS **/
.card-product-tile {
    text-align: center;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-direction: column;
}

.swiper-slide.card-product-tile {
    height: auto !important;
}

.card-product-tile .contenttilecta {
    margin-top: auto;
}

.card-product-tile .OfferBoxImage img {
    max-width: 80%;
    display: block;
    margin: 0 auto;
}

.card-product-tile .OfferBoxPrice {
    font-size: 1.3rem;
    font-family: gibson_semibold,sans-serif;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 1.1rem;
    color: black !important;
}

.card-product-tile .productname {
    margin-top: 1rem;
    color: black;
    flex-grow: 1;
    -ms-flex-positive: 1;
}
    .card-product-tile .productname a {
        font-size:0.9rem;
        color:black!important;
        line-height:normal!important;
    }

    .card-product-tile .FPpercent {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: inline-flex;
        display: -ms-inline-flexbox;
        border-radius: 50%;
        box-sizing: border-box;
        text-align: center;
        z-index: 1;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        background-color: #c5000c;
        color: #fff;
        width: 55px;
        height: 55px;
        font-size: 0.8em !important;
    }

.card-product-tile .FPpercent b {
    display: block;
    font-family: gibson_semibold, helvetica,arial,sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    line-height: normal;
    padding-left: 0.3em;
    padding-right: 0.3em;
}

.card-product-tile .FPpercent b span {
    display: block;
}
/** ANIMATIONS **/
.pulse-black {
    box-shadow: 0 0 0 rgba(0,0,0,1);
    animation: pulse-black 2s infinite
}

@-webkit-keyframes pulse-black {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0.6);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(0,0,0, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
    }
}

@keyframes pulse-black {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0.6);
        box-shadow: 0 0 0 0 rgba(0,0,0, 0.6);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(0,0,0, 0);
        box-shadow: 0 0 0 10px rgba(0,0,0, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
        box-shadow: 0 0 0 0 rgba(0,0,0, 0);
    }
}

.pulse-teal {
    box-shadow: 0 0 0 rgba(63,180,152,1);
    animation: pulse-teal 2s infinite
}

@-webkit-keyframes pulse-teal {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(63,180,152, 0.6);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(63,180,152, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(63,180,152, 0);
    }
}

@keyframes pulse-teal {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(63,180,152, 0.6);
        box-shadow: 0 0 0 0 rgba(63,180,152, 0.6);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(63,180,152, 0);
        box-shadow: 0 0 0 10px rgba(63,180,152, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(63,180,152, 0);
        box-shadow: 0 0 0 0 rgba(63,180,152, 0);
    }
}

.pulse-red {
    box-shadow: 0 0 0 rgba(197,0,12,1);
    animation: pulse-red 2s infinite
}

@-webkit-keyframes pulse-red {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(197,0,12, 0.6);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(197,0,12, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(197,0,12, 0);
    }
}

@keyframes pulse-red {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(197,0,12, 0.6);
        box-shadow: 0 0 0 0 rgba(197,0,12, 0.6);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(197,0,12, 0);
        box-shadow: 0 0 0 10px rgba(197,0,12, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(197,0,12, 0);
        box-shadow: 0 0 0 0 rgba(197,0,12, 0);
    }
}
